import React, {Component} from 'react';
import {getUserFilesAction, logoutFileUserAction} from "../actions/fileAction";
import {FILE_LOGIN} from "../constants/routePaths";
import {connect} from "react-redux";
import SharedFileComponent from "../components/fileShare/sharedFileComponent";
import {openMenu} from "../actions/panelAction";
import {Link} from "react-router-dom";

class FileSharePage extends Component {
    sharedFiles;

    constructor(props) {
        super(props);
        props.dispatch(getUserFilesAction({}, (res) => {
            if (res) {
                this.sharedFiles = res;
                this.forceUpdate();
            } else {
                this.props.history.push(FILE_LOGIN)
            }
        }))
    }
    logOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.props.dispatch(logoutFileUserAction(()=>{
            this.props.history.push(FILE_LOGIN)
        }))
    }

    render() {
        return (
            this.sharedFiles ?
                <div>
                    <div className="header-root file-share">
                        <div className="header-container safe-area">

                            <div className="header-block">
                                <div className="logo-item">
                                    <img src="/assets/img/unluTekstilLogo.png" alt=""/>
                                </div>
                                <div className={`user-root ${this.props.isMenuOpen === true ? "active":""}`}>
                                    <div className="user-item" onClick={()=>{
                                        this.props.dispatch(openMenu(!this.props.isMenuOpen));
                                    }}>
                                        <i className="icon-user"></i>
                                        <div className="open-user-root">
                                            <div className="arr-up"></div>
                                            <ul>
                                                <li onClick={this.logOut}>Sign Out</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="file-share-root">

                        <div className="file-share-container safe-area">
                            <div className="head-item">
                                <h1>Files</h1>
                            </div>
                            <div className="file-share-block">
                                {
                                    this.sharedFiles.map((fileItem,key)=>
                                        <SharedFileComponent key={key} {...fileItem}/>
                                    )
                                }
                            </div>

                        </div>
                    </div>
                </div>
                :
                null
        );
    }
}

const mapStateToProps = (state)=>({
    isMenuOpen:state.PanelReducer.isMenuOpen,
});

export default connect(mapStateToProps)(FileSharePage);
