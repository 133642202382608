import React, {Component} from "react";
import {sendVoteAction} from "../../actions/electionAction";
import {connect} from "react-redux";
import {ASSETS} from "../../constants/Path";

class ElectionVoteContainer extends Component {
    selectedCandidate;
    isSucceed = false
    sendVote = () => {
        if (this.selectedCandidate) {
            this.props.dispatch(sendVoteAction({
                token: this.props.token,
                election_id: this.props.link,
                candidate_id: this.selectedCandidate
            }, (res) => {
                if (res) {
                    this.isSucceed = true;
                    this.forceUpdate();
                }
            }))
        }
    }

    closeModal = () => {
        this.props.changeActiveIndex(0,null,null,null)
    }

    render() {
        let {candidates, checkCondition} = this.props;
        return (
            <div>
                {
                    this.isSucceed === false ?
                        <div className="appointment-root">
                            <div className="appointment-wrapper">
                                <div className="appointment-container">
                                    <div className="banner-img-block ">
                                        <div className="img-item"><img
                                            src="/assets/img/education/calisan-temsilcisi-secimi-2021.png"
                                            alt=""/></div>
                                        <div className="text-item">
                                            <p>Çalışan Temsilcisi Seçimi 2020</p>
                                        </div>

                                    </div>
                                    <form className="right-block">
                                        <div className="right-sub-block">
                                            <div className="first-step-block">
                                                {
                                                    candidates && candidates.map((candidate, key) =>
                                                        <div className="radio-block " key={key}>
                                                            <div className="radio-button">
                                                                <div className="vote-radio-item">
                                                                    <input onClick={() => {
                                                                        this.selectedCandidate = candidate.id;
                                                                    }} type="radio" id={key} name="shop" value="false"/>
                                                                    <label className="radio-label" htmlFor={key}>
                                                                        <img src={`${ASSETS}${candidate.img}`}
                                                                             alt={candidate.name}/>
                                                                        <span dangerouslySetInnerHTML={{__html:candidate.name.replace(" ","</br>")}}></span>
                                                                        <span
                                                                            className="info">{candidate.department_name}</span>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>

                                        </div>

                                    </form>
                                    <div className="sticky-item safe-area">
                                        <div className="btn-sticky">
                                            <div className="btn-item">
                                            <span className="btn-back" onClick={()=>{
                                                checkCondition()
                                            }}>ŞARTLARI İNCELE</span>
                                                <span className={"btn-green " + (!this.selectedCandidate ? "disabled" : "")}
                                                      onClick={this.sendVote}>OY VER</span>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>
                        :

                        <div className="lb-root">
                            <div className="lb-block">
                                <div className="content-block add-part-block">
                                    <div className="content-item ">
                                        <div className="head-block lb-head "><h2>Teşekkürler</h2>
                                            <p>Verdiğiniz oyu kaydettik. Sonuçlar ile ilgili bilgilendirmeyi daha sonra
                                                yapacağız.
                                                Lütfen takip edin.</p>
                                        </div>
                                        <i className="icon-close" onClick={this.closeModal}></i>
                                        <div className="bottom-btn-item lb-btn">
                                            <span className="btn-back login" onClick={this.closeModal}>GERİ DÖN</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                }

            </div>

        )
    }
};

export default connect()(ElectionVoteContainer);