import React, {Component, Fragment} from "react";
import {
    FILE_LOGIN,
    FILE_SHARE,
    FILE_SHARE_DETAIL_WITHOUT_PARAM,
    PANEL_FILE_DETAIL_WITHOUT_DETAIL
} from "../../constants/routePaths";
import {Link} from "react-router-dom";
import PanelFileFeedbackContainer from "../../containers/Panel/PanelFileFeedbackContainer";
import {connect} from "react-redux";
import {openMenu} from "../../actions/panelAction";
import LoginPage from "../../pages/loginPage";
import {logoutFileUserAction} from "../../actions/fileAction";
import {ASSETS} from "../../constants/Path";

class SharedFileHeaderComponent extends Component {
    feedBackDetail;

    componentDidMount() {
        if(this.props.match.params && this.props.match.params.modalType==="geri-bildirim"){
            this.feedBackDetail = true;
            this.forceUpdate();
        }
    }

    toggleFeedBackModal = (value) => {
        this.feedBackDetail = value;
        if(!value){
            this.props.history.replace(`${FILE_SHARE_DETAIL_WITHOUT_PARAM}${this.props.match.params.id}`)
        }
        this.forceUpdate();
    }

    logOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.props.dispatch(logoutFileUserAction(()=>{
            this.props.history.push(FILE_LOGIN)
        }))
    }

    render() {
        let {uploadedFiles, pdf_path, file_user_id, feedBacks} = this.props;
        return (
            <Fragment>
                <div className="header-root file-share">
                    <div className="header-container safe-area">

                        <div className="header-block">
                            <div className="left-block">
                                <div className="logo-item">
                                    <img src="/assets/img/unluTekstilLogo.png" alt=""/>
                                </div>
                                <ul>
                                    <Link to={FILE_SHARE}>
                                        <li>All Files</li>
                                    </Link>
                                    <li>{uploadedFiles.name}</li>
                                </ul>
                            </div>
                            <div className="right-block">
                                <div className="btn-item">
                                <span className="btn btn-back" onClick={() => {
                                    this.toggleFeedBackModal(true)
                                }}>Give Feedback</span>
                                    <a href={ASSETS+pdf_path} download className="btn  ">
                                        <span className="btn btn-back">Download</span>
                                    </a>
                                </div>

                                <div className={`user-root ${this.props.isMenuOpen === true ? "active":""}`}>
                                    <div className="user-item" onClick={(e)=>{
                                        if(e.target.tagName !== "li"){
                                            this.props.dispatch(openMenu(!this.props.isMenuOpen));
                                        }
                                    }}>
                                        <i className="icon-user"></i>
                                        <div className="open-user-root">
                                            <div className="arr-up"></div>
                                            <ul>
                                                <li onClick={this.logOut}>Sign Out</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
                {
                    this.feedBackDetail &&
                    <PanelFileFeedbackContainer isUser={true} toggleFeedBackModal={this.toggleFeedBackModal}
                                                feedBacks={feedBacks} fileName={uploadedFiles.name}
                                                file_user_id={file_user_id} fileUsers={this.props}/>
                }
            </Fragment>
        )
    }
}

const mapStateToProps = (state)=>({
    isMenuOpen:state.PanelReducer.isMenuOpen,
});

export default connect(mapStateToProps)(SharedFileHeaderComponent);
