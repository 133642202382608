import { combineReducers } from 'redux';
import homeReducer from './homeReducer';
import educationDetailReducer from './educationDetailReducer';
import {reducer as formReducer} from 'redux-form';
import educationCategoriesReducer from "./educationCategoriesReducer";
import PanelReducer from "./panelReducer";
import sessionReducer from "./sessionReducer";
import smartDocumentDetailReducer from "./smartDocumentDetailReducer";
import electionReducer from "./electionReducer";

const combineRed = combineReducers({
    homeReducer,
    educationDetailReducer,
    educationCategoriesReducer,
    PanelReducer,
    sessionReducer,
    smartDocumentDetailReducer,
    electionReducer,
    form: formReducer
});

export default combineRed
