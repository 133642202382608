import React, {Component} from "react";
//import moment from "moment";
import {connect} from "react-redux";
import {setCurrentVersionAction} from "../../actions/fileAction";
import {ASSETS} from "../../constants/Path";
import moment from 'moment-timezone'
moment.locale("tr");

class PanelFileVersionItemComponent extends Component {
    isClosed = false;
    toggleClosed = () => {
        this.isClosed = !this.isClosed;
        this.forceUpdate();
    }
    setCurrentVersion = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.props.dispatch(setCurrentVersionAction({file_id:this.props.file_id,version_id:this.props.id},(res)=>{
            if(res){
                this.props.getData();
            }
        }))
    }
    render() {
        let {pdf_path, pptx_path, is_current, created_date, id} = this.props;
        return (
            <div className={"version-block " +(is_current==1 ? "selected" : "")}>
                <div className={"box-item "+ ( "date-info " + (this.isClosed?"closed":"") ) } onClick={this.toggleClosed} >
                    <div className="date-item">
                        <i className="icon-arr-bottom"></i>
                        <div className="date"><span>{moment(created_date).tz("Europe/Istanbul").format("DD MMM YYYY")}</span>, <span>{created_date.split(" ")[1]}</span></div>
                    </div>
                    {
                        // is_current == 1 &&
                        <span onClick={this.setCurrentVersion} className="select-version" >{is_current==1 ? "Aktif Versiyon" : "SEÇİLİ VERSİYON YAP" }</span>
                    }
                </div>
                {
                    pdf_path &&
                    <a href={ASSETS+pdf_path} download className="box-item added">
                        <div className="added-item">
                            <i className="icon-doc"></i>
                            <span>{pdf_path.replace("/files/","")}</span>
                        </div>
                    </a>
                }
                {
                    pptx_path &&
                    <a href={ASSETS+pptx_path} download className="box-item added">
                        <div className="added-item">
                            <i className="icon-doc"></i>
                            <span>{pptx_path.replace("/files/","")}</span>
                        </div>
                    </a>
                }

            </div>
        )
    }
};

export default connect()(PanelFileVersionItemComponent);