import React, {Component} from 'react';
import {ASSETS} from "../../constants/Path";
import {getElectionResultAction, saveCandidateAction} from "../../actions/electionAction";
import {connect} from "react-redux";

class ElectionResultContainer extends Component{
    candidates = [];
    printContent = React.createRef();
    associateCandidates = [];
    originalCandidates = [];
    activeTab;
    isSaved = false;
    printClicked = false;
    constructor(props) {
        super(props);
        if(!this.props.candidates){
            this.getCandidates();
        }else{
            this.props.candidates.filter(item=>item.is_associate==1).map((item)=>{
                this.associateCandidates.push(item.candidate_id)
            })
            this.props.candidates.filter(item=>item.is_original==1).map((item)=>{
                this.originalCandidates.push(item.candidate_id)
            })
        }
    }

    getCandidates = () => {
        this.props.dispatch(getElectionResultAction({election_id:this.props.link},(result)=>{
            if(result){
                this.candidates = result;
                this.candidates.filter(item=>item.is_associate==1).map((item)=>{
                    this.associateCandidates.push(item.candidate_id)
                })
                this.candidates.filter(item=>item.is_original==1).map((item)=>{
                    this.originalCandidates.push(item.candidate_id)
                })
                this.forceUpdate()
            }
        }))
    }

    closeModal = () => {
        this.props.changeActiveIndex(0,null,null,null)
    }
    saveCandidates = () => {
        this.props.dispatch(saveCandidateAction({candidates:(this.props.candidates || this.candidates),associateCandidates:this.associateCandidates,originalCandidates:this.originalCandidates},(res)=>{
            if(res){
                this.isSaved = true;
                this.forceUpdate();
                this.getCandidates();
            }
        }))
    }
    addArr = (key, otherKey,value) => {
        this.isSaved = false;
        let keyIndex = this[key].indexOf(value);
        let otherKeyIndex = this[otherKey].indexOf(value);
        if(keyIndex>-1){
            this[key].splice(keyIndex,1)
        }else{
            this[key].push(value)
        }
        if(otherKeyIndex>-1){
            this[otherKey].splice(otherKeyIndex,1)
        }
        this.forceUpdate();
    };
    printEducation = (elem) => {
        this.printClicked = true;
        this.forceUpdate();

        setTimeout(()=>{
            let myWindow = window.open('', 'PRINT', 'height=400,width=600');

            myWindow.document.write('<html><head><title>' + document.title + '</title>');
            myWindow.document.write('<style>h2,  div.vote-result-block  {display: block;} div.vote-result  {display: table-row;} div.vote-result-item  {display: table-cell; width: 350px;}  div.profile-info-item  {display: table-row} div.info-item{display: table-cell} div.number{display: table-row} span.votes{ display: table-cell; width:100px;margin-right: 10px;margin-left: 10px}  div.vote-result-tab-block{display: none} div.vote-right-admin{ width:100px!important;display: table-cell; margin-left: 20px;} span.asil-cand{background: green;} span.yedek-cand{background: orange} div.img-item{display: none}  span.label-tag{display: none} </style></head><body >');
            myWindow.document.write('<h1>' + document.title + '</h1>');
            myWindow.document.write(this.printContent.current.innerHTML);
            myWindow.document.write('</body></html>');

            myWindow.document.close(); // necessary for IE >= 10
            myWindow.focus(); // necessary for IE >= 10*/

            myWindow.print();
            myWindow.close();
            this.printClicked = false;
            this.forceUpdate();
        },150)
        return true;
    };
    render() {
        let {candidates, detail, isLogin, roleId} = this.props;
        return(
            <div>
                <div className={"appointment-root "+(isLogin?"":"result-vote")}>
                    <div className="appointment-wrapper">
                        <div className="appointment-container">
                            <div className="banner-img-block ">
                                <div className="info-item">
                                    {
                                        isLogin &&
                                        <div className="btn-item" onClick={this.printEducation}><span className="btn-green">Yazdır</span></div>
                                    }
                                    {
                                        (detail && detail.detail.is_ended == 1) ?
                                            <div className="btn-item"><span className="btn-green">Oylama Sona Erdi</span></div>
                                            :
                                            <div className="btn-item"><span className="btn-green">Oylama Devam Ediyor</span></div>
                                    }
                                </div>
                                <div className="img-item"><img
                                    src="/assets/img/education/calisan-temsilcisi-secimi-2021.png"
                                    alt=""/>  </div>
                                <div className="text-item">
                                    <p>Çalışan Temsilcisi Seçimi 2020</p>
                                </div>

                            </div>

                            {
                                (candidates || this.candidates) &&
                                <div className="vote-result-block" ref={this.printContent}>
                                    {
                                        (detail.detail.is_ended == 1 && !(this.printClicked ===false && isLogin && roleId == 1)) &&
                                        <div className="vote-result-tab-block">
                                            <ul>
                                                <li onClick={()=>{
                                                    this.activeTab = null;
                                                    this.forceUpdate();
                                                }} className={!this.activeTab ? "active" : ""}>Tümü</li>
                                                <li onClick={()=>{
                                                    this.activeTab = "is_original";
                                                    this.forceUpdate();
                                                }} className={this.activeTab === "is_original" ? "active" : ""}>Asiller</li>
                                                <li onClick={()=>{
                                                    this.activeTab = "is_associate";
                                                    this.forceUpdate();
                                                }} className={this.activeTab === "is_associate" ? "active" : ""}>Yedekler</li>
                                            </ul>
                                        </div>
                                    }
                                    {
                                        (candidates || this.candidates).filter(item=> !this.activeTab || item[this.activeTab] == "1" ).map((candidateItem,key)=>
                                            <div className="vote-result" key={key}>
                                                <div className="vote-result-item">
                                                    <div className="profile-info-item">
                                                        <span className="number">{key+1}.</span>
                                                        <div className="img-item">
                                                            <img src={`${ASSETS}${candidateItem.candidate_img}`} alt=""/>
                                                        </div>
                                                        <div className="info-item">
                                                            <span>{candidateItem.candidate_name}</span>
                                                            <span>{candidateItem.candidate_department_name || candidateItem.department_name}</span>
                                                        </div>
                                                    </div>
                                                    {/*<span className="votes">{candidateItem.total_count}</span>*/}

                                                </div>
                                                <span className="votes">{candidateItem.total_count}</span>
                                                <div className="vote-right-admin">
                                                    {
                                                        (this.printClicked ===false && detail.detail.is_ended == 1 && isLogin && roleId == 1) &&
                                                        <div className="radio-block">
                                                            <div className="radio-item">
                                                                <input onChange={()=>{}} checked={this.originalCandidates.indexOf(candidateItem.candidate_id)>-1} onClick={()=>{
                                                                    this.addArr("originalCandidates","associateCandidates",candidateItem.candidate_id)
                                                                }} id={candidateItem.candidate_id+"-asil"} name={candidateItem.candidate_id+"-asil"} type="radio" />
                                                                <label htmlFor={candidateItem.candidate_id+"-asil"} className="radio-label">Asil</label>
                                                            </div>
                                                            <div className="radio-item">
                                                                <input onChange={()=>{}} checked={this.associateCandidates.indexOf(candidateItem.candidate_id)>-1} onClick={()=>{
                                                                    this.addArr("associateCandidates","originalCandidates",candidateItem.candidate_id)
                                                                }} id={candidateItem.candidate_id+"-yedek"} name={candidateItem.candidate_id+"-yedek"} type="radio" />
                                                                <label htmlFor={candidateItem.candidate_id+"-yedek"} className="radio-label">Yedek</label>
                                                            </div>
                                                        </div>
                                                    }

                                                    {
                                                        ((candidateItem.is_original == "1" || candidateItem.is_associate == "1") && !(this.printClicked ===false && isLogin && roleId == 1)) &&
                                                        <div className="candidate-type">
                                                            {
                                                                candidateItem.is_original == "1" && <span className="asil-cand">Asil</span>
                                                            }
                                                            {
                                                                candidateItem.is_associate == "1" && <span className="yedek-cand">Yedek</span>
                                                            }
                                                        </div>
                                                    }


                                                </div>

                                            </div>
                                        )
                                    }
                                </div>
                            }

                           <div className="sticky-item safe-area">
                               <div className="btn-sticky">
                                   <div className="btn-item" >
                                       <span onClick={this.closeModal} className="btn-back">GERİ DÖN</span>
                                       {
                                           (this.printClicked ===false && detail.detail.is_ended == 1 && isLogin && roleId == 1) &&
                                           <span className="btn-green" onClick={()=>{
                                               this.saveCandidates()
                                           }}>{this.isSaved ? "KAYDEDİLDİ":"KAYDET"}</span>
                                       }
                                   </div>
                               </div>
                           </div>

                        </div>


                    </div>
                </div>
            </div>
        )
    }
};

const mapStateToProps = (state) => ({
    isLogin: state.sessionReducer.isLogin,
    roleId: state.sessionReducer.role_id
});

export default connect(mapStateToProps)(ElectionResultContainer);