import React, {Component} from 'react';
import PageService from '../pageServices/pageService';
import * as ROUTES from "../constants/routePaths";
import {connect} from "react-redux";
import NotificationBannerComponent from "../components/common/notificationBannerComponent";
import HomeNewAndFeatureCourses from "../components/home/HomeNewAndFeatureCoursesComponent";
import FundamentalCoursesComponent from "../components/home/FundamentalCoursesComponent";
import FooterComponent from "./../components/common/footerComponent";
import BannerContainer from "../containers/home/bannerContainer";

class HomePage extends Component {
    pageService = null;
    constructor(props) {
        super(props);
        this.pageService = new PageService(ROUTES.HOME);
        this.pageService.getData(props.dispatch).then((res) => {

        });

    }

    render() {

        const {mainTiles, openEducation, survey, fundamentalCourses, news, featureCourses} = this.props;

        return (
            <div className="mainRoot">

                <div className="safe-area">

                    {
                        (openEducation && Object.keys(openEducation).length > 0) &&
                        <NotificationBannerComponent openEducation={openEducation}/>
                    }

                    {/*Tiles*/}
                    <BannerContainer history={this.props.history}/>


                </div>

                {
                    fundamentalCourses &&
                    <FundamentalCoursesComponent fundamentalCourses={fundamentalCourses}/>
                }

                {
                    news &&
                    <HomeNewAndFeatureCourses news={news} featureCourses={featureCourses}/>
                }


                <FooterComponent />

            </div>
        )
    }
}

// export default HomePage;

const mapStateToProps = (state) => ({
    educationCategories: state.homeReducer ? state.homeReducer.educationCategories : [],
    featureCourses: state.homeReducer ? state.homeReducer.featureCourses : [],
    fundamentalCourses: state.homeReducer ? state.homeReducer.fundamentalCourses : [],
    news: state.homeReducer ? state.homeReducer.news : [],
    openEducation: state.homeReducer ? state.homeReducer.openEducation : {},
    notifications: state.homeReducer ? state.homeReducer.notifications : [],
});

export default connect(mapStateToProps)(HomePage);
