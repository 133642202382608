import {RECEIVE_ELECTIONS, RECEIVE_ELECTION_DETAIL} from '../constants/actionTypes';
import {
    getElectionDetail,
    sendSmsToPhone,
    checkCode,
    sendVote,
    getElectionResult,
    isSessionExist,
    getResultSession,
    saveCandidate,
    getElectionVoters,
    getElections,
    getElectionPanelDetail,
    getElectionAutoComplete, endElection, startElection
} from "../services/webService";
import {saveElectionDetail} from "../services/adminService";

const receiveElection = (payload) => ({
    type: RECEIVE_ELECTION_DETAIL,
    payload
})

export const getElectionDetailAction = (params) => dispatch => {
    getElectionDetail(params).then((res)=>{
        //if(res.data.success){
            dispatch(receiveElection({[params.link]:res.data.success}))
        //}
    })
}

export const sendSmsToPhoneAction = (params,cb) => dispatch => {
    sendSmsToPhone(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false,res.data.state)
        }
    }).catch(()=>{
        cb(false,"permission_error")
    })
}

export const checkCodeAction = (params,cb) => dispatch => {
    checkCode(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success,res.data.state)
        }else{
            cb(false)
        }
    }).catch(()=>{
        cb(false)
    })
}

export const sendVoteAction = (params,cb) => dispatch => {
    sendVote(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }
    })
}

export const getElectionResultAction = (params,cb) => dispatch => {
    getElectionResult(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }
    })
}

export const isSessionExistAction = (params,cb) => dispatch => {
    isSessionExist(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false)
        }
    }).catch(()=>{
        cb(false)
    })
}

export const getResultSessionAction = (params,cb) => dispatch => {
    getResultSession(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false)
        }
    }).catch(()=>{
        cb(false)
    })
}

export const saveCandidateAction = (params,cb) => dispatch => {
    saveCandidate(params).then((res)=>{
        if(res.data.success){
            cb(true,res.data.success);
        }else{
            cb(false);
        }
    }).catch(()=>{
        cb(false);
    })
}

export const getElectionVotersAction = (params,cb) => dispatch => {
    getElectionVoters(params).then(res => {
        if(res.data.success){
            cb(res.data.success)
        }
    }).catch(()=>{

    })
}

export const receiveElections = (payload, isFirst) => ({
    type: RECEIVE_ELECTIONS,
    payload,
    isFirst
})

export const getElectionsAction = (params,cb) => dispatch => {
    getElections(params).then((res)=> {
        if (res.data.success) {
            dispatch(receiveElections(res.data.success, params.index === 0));
            cb(true, res.data.state)
        }
    }).catch((err)=>{

    })
}

export const getElectionPanelDetailAction = (params, cb) => dispatch => {
    getElectionPanelDetail(params).then(res => {
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false)
        }
    }).catch((err)=>{
        cb(false)
    })
}

export const getElectionAutoCompleteAction = (params,cb) => dispatch => {
    getElectionAutoComplete(params).then(res => {
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb([])
        }
    }).catch((err)=>{
        cb([])
    })
}

export const saveElectionDetailAction = (params,cb) => dispatch => {
    saveElectionDetail(params).then(res => {
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false)
        }
    }).catch((err)=>{
        cb(false)
    })
}

export const endElectionAction = (params,cb) => dispatch => {
    endElection(params).then(res => {
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false)
        }
    }).catch((err)=>{
        cb(false)
    })
}

export const startElectionAction = (params,cb) => dispatch => {
    startElection(params).then(res => {
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false)
        }
    }).catch((err)=>{
        cb(false)
    })
}