import React, {Component} from "react";
import {connect} from "react-redux";
import {getElectionAutoCompleteAction} from "../../actions/electionAction";

class ElectionParticipantsContainer extends Component {
    departmentInput = React.createRef();
    autoCompleteArr;
    autoIsShowing = false;
    selectedElement;
    addParticipant = () => {
        if(this.selectedElement){
            let participants = [];
            if(this.selectedElement.participants){
                this.selectedElement.participants.filter(item=> !this.props.electionParticipant.find(participantItem=>participantItem.participant_id===item.id) ).map((participantItem)=>{
                    participants.push({
                        participant_id: participantItem.id,
                        department_name: this.selectedElement.department_name,
                        participant_name_surname: participantItem.name_surname,
                        participant_phone: participantItem.phone,
                    })
                })
            }else{
                participants.push({
                    participant_id: this.selectedElement.id,
                    department_name: this.selectedElement.department_name,
                    participant_name_surname: this.selectedElement.name_surname,
                    participant_phone: this.selectedElement.phone,
                })
            }
            this.props.addParticipant(participants)
            this.departmentInput.current.value = "";
            this.selectedElement = null;

        }
    }
    searchParticipants = (e) => {
        this.selectedElement = null;
        if(e.target.value !== ""){
            this.props.dispatch(getElectionAutoCompleteAction({searchTerm:e.target.value},(result)=>{
                this.autoCompleteArr = result;
                this.forceUpdate();
            }))
        }else{
            this.autoCompleteArr=null;
            this.forceUpdate();
        }
    }
    focusElement = () => {
        this.autoIsShowing = true;
        this.forceUpdate()
    }

    blurElement = () => {
        setTimeout(()=>{
            this.autoIsShowing = false;
            this.forceUpdate()
        },250)

    }

    selectedDepartmentFunc = (selectedDepartment) => {
        this.selectedElement = selectedDepartment;
        this.departmentInput.current.value = selectedDepartment.department_name
        this.autoCompleteArr=null;
        this.forceUpdate();
    }

    selectedParticipantFunc = (selectedParticipant) => {
        this.selectedElement = selectedParticipant;
        this.departmentInput.current.value = selectedParticipant.name_surname
        this.autoCompleteArr=null;
        this.forceUpdate();
    }

    render() {
        let {electionParticipant, deleteParticipant} = this.props;
        return (
            <div className="election-person-container">
                <div className="subtitle">
                    <span>Oy verebilecekler</span>
                </div>
                <div className="add-person-block">

                    <div className="add-person-item">
                        <input ref={this.departmentInput} type="text" onChange={this.searchParticipants} onFocus={this.focusElement} onBlur={this.blurElement}
                               placeholder="Ad Soyad, telefon numarası veya departman adı ile katılımcı ekleyebilirsiniz."/>
                    </div>
                    {
                        (this.autoIsShowing && this.autoCompleteArr) &&
                        <div className="autocomplete-root">
                            <ul>
                                {
                                    this.autoCompleteArr.departments && this.autoCompleteArr.departments.map((item, key) =>
                                        <li onClick={() => {
                                            this.selectedDepartmentFunc(item)
                                        }} key={key}>{item.department_name}</li>
                                    )
                                }
                                {
                                    this.autoCompleteArr.participants && this.autoCompleteArr.participants.filter(item=> !electionParticipant.find(participantItem=>participantItem.participant_id===item.id) ).map((item, key) =>
                                        <li onClick={() => {
                                            this.selectedParticipantFunc(item)
                                        }} key={key}>{item.name_surname} - {item.phone}</li>
                                    )
                                }
                            </ul>
                        </div>

                    }
                    <div className="add-btn" onClick={this.addParticipant}>
                        <i className="icon-plus-circle"></i>
                        <span>EKLE</span>
                    </div>
                </div>
                <div className="added-person-container">
                    <div className="added-person-block">
                        {
                            electionParticipant.map((electionItem,key)=>
                                <div className="added-item" key={key}>
                                    <div className="left-item">
                                        <p>{electionItem.participant_name_surname}</p>
                                        <p>{electionItem.participant_phone}</p>
                                        <p>{electionItem.department_name}</p>
                                    </div>
                                    <div className="close-item" onClick={()=>{
                                        deleteParticipant(key)
                                    }}>
                                        <i className="icon-close"></i>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default connect()(ElectionParticipantsContainer);