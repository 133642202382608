import {RECEIVE_FILE_USER_GROUPS} from "../constants/actionTypes";
import {getFileGroups} from "../services/adminService";


const receiveFileGroups = (payload, isFirst) => ({
    type: RECEIVE_FILE_USER_GROUPS,
    payload,
    isFirst
});

export const getFileGroupsAction = (params,cb) =>dispatch =>{
    getFileGroups(params).then((res) => {
        if (res.data.success) {
            dispatch(receiveFileGroups(res.data.success,params.index===0));
            cb(res.data.state)
        }
        else{
            cb(res.data.state)
        }

    })
        .catch((err) => {
            console.log("err", err)
        })

}