import React, {Component} from 'react';
import {checkCodeAction, sendSmsToPhoneAction} from "../../actions/electionAction";
import {connect} from "react-redux";
import Helper from "../../helper";
import {Field, reduxForm} from "redux-form";
import {withRouter} from "react-router-dom";
import inputFieldComponent from "../../components/common/formFields/inputFieldComponent";

class ElectionPhoneNumberModalContainer extends Component{
    phoneNumberInput = React.createRef();
    phoneCodeInput = React.createRef();
    state = {
        phone_number: "",
        modalType:"phone_number",
        numberExistError: false,
        codeFail: false
    }
    checkCode = () => {
        this.setState({
            codeFail:false
        })
        this.props.dispatch(checkCodeAction({phone_code:this.phoneCodeInput.current.value,type:this.props.type,election_id:this.props.link,phone_number:this.state.phone_number},(res,token)=>{
            if(res){
                this.props.changeActiveIndex(2,null,null,token,res)
            }else{
                this.setState({
                    codeFail:true
                })
            }
        }))
    }
    sendCodeToPhone = () => {
        if(this.props.formValues.phone_number && this.props.formValues.phone_number.length===14){
            this.setState({
                numberExistError:false
            })
            let phoneNumber = this.props.formValues.phone_number.split(" ").join("").split(")").join("").split("(").join("")
            this.props.dispatch(sendSmsToPhoneAction({phone:phoneNumber,type:this.props.type,link:this.props.link},(res, errorState)=>{
                if(res){
                    this.setState({
                        phone_number:phoneNumber,
                        modalType:"code"
                    })
                    //this.props.changeActiveIndex(2,null,this.phoneNumberInput.current.value)
                }else{
                    this.setState({
                        numberExistError:true,
                        modalType:errorState
                    })
                }
            }))
        }else{
            this.setState({
                numberExistError:true
            })
        }

    }
    closeModal = () => {
        this.props.changeActiveIndex(0,null,null,null)
    }
    goToFirstStep = () => {
        this.setState({
            phone_number:null,
            modalType:"phone_number",
            numberExistError: false,
            codeFail: false
        })
    }
    render() {
        let {type} = this.props
        return(
            <div>
                <div className="lb-root">
                    <div className="lb-block">
                        <div className="content-block add-part-block">
                            {
                                this.state.modalType === "phone_number" &&
                                <div className="content-item">
                                    <div className="head-block"><h2>Telefonunuzu Doğrulayın</h2>
                                        <p>{type === "result" ? 'Sonuçları görebilmek' :'Oy kullanabilmek'} için aşağıdaki alana telefon numaranızı yazın ve devam edin</p>
                                    </div>
                                    <i className="icon-close" onClick={this.closeModal}></i>
                                    <div className="form-block">
                                        <div className="input-container">
                                            <div className="input-block">
                                                <div className="input-container">
                                                    <div className="input-block">
                                                        <Field
                                                            name="phone_number"
                                                            label="TELEFON NUMARANIZ"
                                                            type="text"
                                                            ref={this.phoneNumberInput}
                                                            placeholder={"(5_ _ ) _ _ _  _ _  _ _"}
                                                            normalize={Helper.normalizePhone}
                                                            component={inputFieldComponent}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="bottom-btn-item ">
                                        {
                                            this.state.numberExistError && <p>Telefon numaranızı doğru girdiğinizden emin olun.</p>
                                        }
                                        <span className="btn-green" onClick={this.sendCodeToPhone}>DOĞRULAMA KODU GÖNDER</span>
                                    </div>
                                </div>
                            }


                            {
                                this.state.modalType === "code" &&
                                <div className="content-item">
                                    <div className="head-block"><h2>Telefonunuzu Doğrulayın</h2>
                                        <p>Verdiğiniz telefon numarasına gelen SMS’teki kodu aşağıdaki alana yazın</p>
                                    </div>
                                    <i className="icon-close" onClick={this.closeModal}></i>
                                    <div className="form-block">
                                        <div className="input-container">
                                            <div className="input-block">
                                                <div className="input-container">
                                                    <div className="input-block">
                                                        <div className="input-item">
                                                            <label> DOĞRULAMA KODU
                                                                <input ref={this.phoneCodeInput} autoComplete="off" type="text" placeholder=""/>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bottom-btn-item ">
                                        {
                                            this.state.codeFail &&
                                            <p>Girdiğiniz kod hatalı.</p>
                                        }
                                        <span onClick={this.checkCode} className="btn-green">DOĞRULAMAYI BİTİR</span>
                                    </div>
                                </div>
                            }

                            {
                                this.state.modalType === "permission_error" &&
                                <div className="content-item ">
                                    <div className="head-block lb-head "><h2>Yetkisiz Giriş Denemesi</h2>
                                        <p>Bu ekranı görmek için yetkiniz bulunmuyor. Bir hata olduğunu düşünüyorsanız sistem yöneticisi ile görüşün.</p>
                                    </div>
                                    <i className="icon-close" onClick={this.closeModal}></i>
                                    <div className="bottom-btn-item lb-btn"><span
                                        className="btn-back login" onClick={this.goToFirstStep}>GERİ DÖN</span></div>
                                </div>
                            }

                            {
                                this.state.modalType === "allready_voted" &&
                                <div className="content-item ">
                                    <div className="head-block lb-head "><h2>Daha önce oy kullandınız</h2>
                                        <p>Her seçimde sadece 1 oy kullanma hakkınız bulunuyor, daha fazla oy kullanamazsınız.</p>
                                    </div>
                                    <i className="icon-close" onClick={this.closeModal}></i>
                                    <div className="bottom-btn-item lb-btn">
                                        <span className="btn-back login" onClick={this.goToFirstStep}>GERİ DÖN</span>
                                    </div>
                                </div>
                            }




                        </div>
                    </div>
                </div>
            </div>
        )
    }
};


const mapStateToProps = reduxForm({
    form: "phoneModal"
})(ElectionPhoneNumberModalContainer);

export default withRouter(connect(state => (
    {
        formValues: state.form.phoneModal ? state.form.phoneModal.values : {}
    }
))(mapStateToProps));