import React, {Component} from 'react'

class ElectionInputFieldComponent extends Component{
    imgRef = React.createRef();
    render() {
        const {input, meta: {error, touched}, fileOnChangeFunc} = this.props;
        return(
            <div className="upload-item">
                <div className="text-item">
                    <p>GÖRSEL</p> <span>Portalda kullanılmak üzere görsel ekleyin.</span>
                </div>
                <div className="box-item">
                    <span>{input.value}</span>
                    <div className="add-img-item">
                        <span className="btn-edit">GÖRSEL EKLE</span>
                        <input type="file" onChange={(e)=>{
                            if(e.target.files[0] && (e.target.files[0].name.split(".").pop() === "jpg" || e.target.files[0].name.split(".").pop() === "jpeg" || e.target.files[0].name.split(".").pop() === "png")){
                                let img = new Image();
                                img.src = window.URL.createObjectURL(e.target.files[0]);
                                let files = e.target.files;

                                {/*this.imgRef.src = window.URL.createObjectURL(e.target.files[0]);*/}

                                img.onload = (imgE)=> {
                                    let width = img.naturalWidth,
                                        height = img.naturalHeight;
                                    window.URL.revokeObjectURL( img.src );
                                    // if( width === 1000 && height === 1000 )
                                    {/*if( width === 1580 && height === 260 )*/}
                                    //if( width >= 1000 && height >=1000 )
                                    if( !this.props.width || (this.props.width >= 1000 && this.props.height >=1000) )
                                    {
                                        fileOnChangeFunc(files);
                                        input.onChange(files[0].name);
                                    }
                                    else {
                                    }
                                };
                            }
                        }} />
                    </div>

                </div>
            </div>
        )
    }
}

export default ElectionInputFieldComponent;