import React, {Component} from "react";
import {saveEducationPictureAction} from "../../actions/panelAction";
import {connect} from "react-redux";

class ElectionCandidateContainers extends Component{
    nameInput = React.createRef();
    departmentInput = React.createRef();
    image;
    fileOnChange = (e) => {
        if(e.target.files[0] && (e.target.files[0].name.split(".").pop() === "jpg" || e.target.files[0].name.split(".").pop() === "jpeg" || e.target.files[0].name.split(".").pop() === "png")){
            let files = e.target.files;
            this.props.dispatch(saveEducationPictureAction(files[0], "survey", (res) => {
                if (res !== false) {
                    this.image = res;
                    this.forceUpdate();
                }
            }));
        }
    }
    addCandidate = () => {
        if(this.image && this.nameInput.current.value.trim().length!==0 && this.departmentInput.current.value.trim().length!==0){
            this.props.addCandidate({
                name: this.nameInput.current.value.trim(),
                img: this.image,
                election_id:this.props.election_id,
                department_name: this.departmentInput.current.value.trim()
            })
            this.nameInput.current.value = "";
            this.image = null;
            this.departmentInput.current.value = "";

        }
    }
    render() {
        let {candidates} = this.props;
        return(
            <div className="election-person-container">
                <div className="head-item">
                    <h3>Seçenekler</h3>
                    <div className="add-btn">
                        <i className="icon-plus-circle"></i>
                        <span>TEMPLATE SEÇ</span>
                    </div>
                </div>
                <div className="add-person-block">
                    <div className="add-person-item add-person-img">
                        <span>{this.image ? "Fotoğrafı değiştirin" : "Lütfen fotoğraf ekleyiniz"}</span>
                        <div className="add">
                            Ekle
                            <input onChange={this.fileOnChange} type="file"/>
                        </div>
                    </div>
                    <div className="add-person-item">
                        <input ref={this.nameInput} type="text" placeholder="İsim Soyisim"/>
                    </div>
                    <div className="add-person-item">
                        <input ref={this.departmentInput} type="text" placeholder="Departman"/>
                    </div>

                    <div className="add-btn" onClick={this.addCandidate}>
                        <i className="icon-plus-circle"></i>
                        <span>EKLE</span>
                    </div>
                </div>
                <div className="added-person-container">
                    <div className="added-person-block">
                        {
                            candidates && candidates.map((candidateItem,key)=>
                                <div className="added-item" key={key}>
                                    <div className="left-item">
                                        <div className="img-item">
                                            <img src={`/assets${candidateItem.img}`} alt=""/>
                                        </div>
                                        <p>{candidateItem.name}</p>
                                        <p>{candidateItem.department_name}</p>
                                    </div>
                                    <div className="close-item" onClick={()=>{
                                        this.props.deleteCandidate(key)
                                    }}>
                                        <i className="icon-close"></i>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default connect()(ElectionCandidateContainers);