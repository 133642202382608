import React, {Component} from 'react';
import PanelFileVersionItemComponent from "./panelFileVersionItemComponent";
import {createNewVersionOfFileAction, uploadFileAction} from "../../actions/fileAction";
import {connect} from "react-redux";
import SuccessModalComponent from "../common/successModalComponent";
import {FILE_SHARE, PANEL_FILES} from "../../constants/routePaths";
import {SITE_URL} from "../../constants/Path";
import {saveEducationPictureAction} from "../../actions/panelAction";
import {change} from "redux-form";
import LoaderModalComponent from "../common/loaderModalComponent";
import ChangeWarningModalComponent from "../modals/changeWarningModalComponent";
const pdfjs = require("pdfjs-dist");
pdfjs.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");

class PanelFileDetailVersion extends Component {
    newPdf;
    newPptx;
    pdfPreview;
    pdfFileError = false
    pptxFileError = false
    successMessage = false;
    pdfFileValidError = false;
    timeOut;
    uploadFile = (file, key) => {
        this.loading = true;
        this.pdfFileValidError = false;
        this.forceUpdate();
        this.props.dispatch(uploadFileAction(file, (res) => {
            if (res) {
                if (key === "newPdf" && res.indexOf(".pdf")!==-1) {
                    this.getPDFPreview(res)
                }else{
                    this.loading = false;
                }
                this[key] = res;
                this.forceUpdate();
            }
        }))
    }
    getPDFPreview = (res) => {
        var pdfPath = process.argv[2] || SITE_URL + "/assets/" + res;
        var loadingTask = pdfjs.getDocument(pdfPath);
        loadingTask.promise
            .then((doc) => {
                doc.getPage(1).then((page) => {
                    var viewport = page.getViewport();
                    var canvas = document.getElementById('canvas');
                    var context = canvas.getContext('2d');
                    canvas.height = viewport.viewBox[3] || 1000;
                    canvas.width = viewport.viewBox[2] || 1000;
                    var task = page.render({canvasContext: context, viewport: viewport})
                    task.promise.then(() => {
                        var image = new Image();
                        image.src = canvas.toDataURL('image/jpeg', 1.0);
                        image.onload = () => {
                            context.setTransform(1,0,0,-1,0,canvas.height);
                            context.drawImage(image, 0, 0);
                            canvas.toBlob((blob) => {
                                this.props.dispatch(saveEducationPictureAction(blob, "survey", (previewRes) => {
                                    if (res !== false) {
                                        this.pdfPreview = previewRes;
                                        this.loading = false;
                                        this.forceUpdate();
                                    }
                                }));
                            }, 'image/jpeg', 0.95)
                        };


                    });
                });
            })
            .then(
                function () {
                    console.log("# End of Document");
                },
                function (err) {
                    console.error("Error: " + err);
                }
            );
    }
    clearValue = (key) => {
        this[key] = null;
        this.forceUpdate();
    }
    saveVersion = () => {
        if (this.newPdf) {
            this.props.dispatch(createNewVersionOfFileAction({
                file_id: this.props.file_id,
                pptx_path: this.newPptx,
                pdf_path: this.newPdf,
                preview_src: this.pdfPreview
            }, (res) => {
                if (res) {
                    this.newPdf = null;
                    this.newPptx = null;
                    this.pdfPreview = null;
                    this.successMessage = true;
                    this.forceUpdate();
                    this.timeOut = setTimeout(() => {
                        this.successMessage = false;
                        this.forceUpdate();
                    }, 2000)
                    this.props.getData();
                }
            }))
        }else{
            this.pdfFileValidError = true;
            this.forceUpdate();
        }
    }

    componentWillUnmount() {
        clearTimeout(this.timeOut);
    }

    render() {
        let {versions} = this.props;
        return (
            <div>
                {
                    this.loading && <LoaderModalComponent/>
                }
                <canvas style={{display:"none"}} id="canvas"></canvas>
                <div className="upload-item">
                    <div className="head-item">
                        <h3>Versiyon Ekle</h3>
                    </div>
                    <div className="text-item">
                        <p>KAYNAK DOSYASI</p>
                        <span>KAYNAK DOSYASI dökümanını ekleyin</span>
                    </div>
                    <div className={"box-item " + (this.newPptx ? "added" : "")}>
                        <div className="add-file-item">
                            <span className="btn-edit">
                                <i className="icon-documents"> </i>
                                DOSYA EKLE
                            </span>
                            <input type="file" onChange={(e) => {
                                this.pptxFileError = false;
                                this.forceUpdate()
                                if (e.target.files[0]) {
                                    let files = e.target.files;
                                    this.uploadFile(files[0], "newPptx")
                                } else {
                                    this.pptxFileError = true;
                                    this.forceUpdate()
                                }
                            }}/>
                        </div>
                        <div className="added-item">
                            <i className="icon-doc"></i>
                            <span>{this.newPptx}</span>
                        </div>
                        <div className="close-item" onClick={() => {
                            this.clearValue("newPptx")
                        }}>
                            <i className="icon-close"></i>
                        </div>
                    </div>
                    {
                        this.pptxFileError &&
                        <div className="error-message">
                            <p>Lütfen ppt ve pptx formatında dosya yükleyiniz.</p>
                        </div>
                    }
                </div>

                <div className="upload-item">
                    <div className="text-item">
                        <p>ÇIKTI DOSYASI*</p>
                        <span>ÇIKTI dökümanını ekleyin</span>
                    </div>
                    <div className={"box-item " + (this.newPdf ? "added" : "")}>
                        <div className="add-file-item">
                            <span className="btn-edit">
                                <i className="icon-documents"> </i> DOSYA EKLE
                            </span>
                            <input type="file" onChange={(e) => {
                                this.pdfFileError = false;
                                this.forceUpdate()
                                if (e.target.files[0]) {
                                    let files = e.target.files;
                                    this.uploadFile(files[0], "newPdf")
                                } else {
                                    this.pdfFileError = true;
                                    this.forceUpdate()
                                }
                            }}/>
                        </div>
                        <div className="added-item">
                            <i className="icon-doc"></i>
                            <span>{this.newPdf}</span>
                        </div>
                        <div className="close-item" onClick={() => {
                            this.clearValue("newPdf")
                        }}>
                            <i className="icon-close"></i>
                        </div>
                    </div>
                    {
                        this.pdfFileError &&
                        <div className="error-message">
                            <p>Lütfen PDF, ppt ve pptx formatında dosya yükleyiniz.</p>
                        </div>
                    }
                    {
                        this.pdfFileValidError &&
                        <div className="error-message">
                            <p>Lütfen Çıktı Dosyası yükleyiniz.</p>
                        </div>
                    }
                </div>

                <div className="upload-item uploaded">
                    <div className="head-item">
                        <h3>Versiyon Geçmişi</h3>
                    </div>
                    {
                        versions && versions.map((item, key) =>
                            <PanelFileVersionItemComponent getData={this.props.getData} key={key} {...item} />
                        )
                    }
                </div>
                <div className="sticky-item ">
                    <div className="btn-sticky safe-area">
                        <div className="left-item">
                            <div className="btn-item"><span className="btn-back" onClick={() => {
                                if (this.newPdf || this.newPptx){
                                    this.isCloseEdit = true;
                                    this.forceUpdate();
                                    console.log(this.isCloseEdit)
                                    return;

                                }
                                this.props.history.push(PANEL_FILES);
                            }}>TÜM DOSYALARA DÖN</span>

                            </div>
                        </div>
                        <div className="btn-item">
                            <button onClick={this.saveVersion} type="button" className={`${(this.newPdf || this.newPptx)?"btn-green":"disable-btn"}`}>KAYDET</button>
                        </div>
                    </div>
                </div>
                {
                    this.successMessage === true && <SuccessModalComponent
                        message={"Kayıt Edildi."}/>
                }
                {
                    this.isCloseEdit &&
                    <ChangeWarningModalComponent
                        saveAndExit={this.saveVersion}
                        forceChangeActiveTab={()=>{
                            this.props.history.push(PANEL_FILES);
                        }}
                        toggleCloseEdit={this.toggleCloseEdit}/>
                }
            </div>
        );
    }
}

export default connect(null,null,null,{withRef:true})(PanelFileDetailVersion);