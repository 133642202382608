import React, {Component} from 'react';
import {connect} from "react-redux";
import {getFilesAction} from "../../actions/fileAction";
import InfiniteScroll from "react-infinite-scroller";
import {Link} from "react-router-dom";
import {PANEL_FILE_DETAIL_WITHOUT_DETAIL} from "../../constants/routePaths";

class PanelFilesContainer extends Component {
    activePageIndex=0;
    orderName = "created_date"
    orderType = "asc"
    hasMore = true;
    constructor(props) {
        super(props);
        this.getData();
    }
    getData = () => {
        if(this.hasMore){
            this.hasMore = false;
            this.forceUpdate()
            this.props.dispatch(getFilesAction({index:this.activePageIndex,orderName:this.orderName,orderType:this.orderType},(res)=>{
                this.hasMore = res;
                this.forceUpdate()
            }))
        }
    }
    sortChange = (sortName) => {
        this.activePageIndex = 0;
        this.hasMore = true;
        this.orderType = this.orderName === sortName ? (this.orderType === "asc"?"desc":"asc") : "asc"
        this.orderName = sortName;
        this.getData()
    }
    loadFunc = () => {
        if(this.hasMore){
            this.activePageIndex+=1;
            this.getData();
        }
    }
    render() {
        return (
            <div className="panel-content-block files">
                <div className="breadcrumb-menu">
                    <ul>
                        <li>Dosyalar</li>
                    </ul>
                </div>
                <div className="table-root">
                    <div className="table-container">
                        <div className="table-head">
                            <div className="t-1 td-item" onClick={()=>{
                                this.sortChange("name")
                            }}>
                                <p>Dosya Adı</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-2 td-item" onClick={()=>{
                                this.sortChange("userCount")
                            }}>
                                <p>Paylaşılan kişi sayısı</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-3 td-item resp-date" onClick={()=>{
                                this.sortChange("updated_date")
                            }}>
                                <p>Son değiştirme tarihi</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                        </div>
                        <div className="table-block">
                            <div>
                                {
                                    (this.props.files && this.props.files.length>0) &&
                                    <InfiniteScroll
                                        pageStart={0}
                                        loadMore={this.loadFunc}
                                        hasMore={this.hasMore && this.props.files.length > 19}
                                        threshold={250}
                                    >
                                        {
                                            this.props.files.map((fileItem, key) =>
                                            <Link to={PANEL_FILE_DETAIL_WITHOUT_DETAIL+fileItem.id} className="table-item" key={key}>
                                                <div className="td-item t-1">
                                                    <i className="icon-doc"></i>
                                                    <p>{fileItem.name}</p>
                                                </div>
                                                <div className="td-item t-2">
                                                    <p>{fileItem.userCount}</p>
                                                </div>
                                                <div className="td-item t-3 resp-date">
                                                    <p>{fileItem.updated_date}</p>
                                                </div>
                                            </Link>
                                        )}
                                    </InfiniteScroll>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    files:state.PanelReducer.files
})

export default connect(mapStateToProps)(PanelFilesContainer);