import React, {Component} from 'react';
import {getFileGroupsAction, } from "../actions/fileUserGroupAction";
import {connect} from "react-redux";
import NewFileUserComponent from "../components/modals/newFileUserComponent";
import NewFileGroupComponent from "../components/modals/newFileGroupComponent";
import InfiniteScroll from "react-infinite-scroller";

class PanelFileUsersGroupContainer extends Component {
    activePageIndex=0;
    orderName ="id";
    orderType ="asc";
    hasMore=true;
    editedFileGroup = null;
    constructor(props){
        super(props);
        this.getData();
    }
    getData =()=>{
        if(this.hasMore){
            this.hasMore=false;
            this.forceUpdate();
            this.props.dispatch(getFileGroupsAction({index:this.activePageIndex,orderName:this.orderName,orderType:this.orderType},(res)=>{
                this.hasMore=res;
                this.forceUpdate()
            }))
        }

    }
    sortChange = (sortName) => {
        this.activePageIndex = 0;
        this.hasMore = true;
        this.orderType = this.orderName === sortName ? (this.orderType === "asc"?"desc":"asc") : "asc"
        this.orderName = sortName;
        this.getData()
    }
    toggleEditedGroup = (item) => {
        this.editedFileGroup = item;
        this.forceUpdate();
    }
    render() {
        return (
            <div className="panel-content-block file-user-group">
                <div className="breadcrumb-menu">
                    <ul>
                        <li>Gruplar</li>
                    </ul>
                </div>
                <div className="table-root">
                    <div className="table-container">
                        <div className="table-head">
                            <div className="t-1 td-item" onClick={()=>{
                                this.sortChange("group_name")

                            }}><p>Grup Adı</p><i className="icon-arr-bottom"></i></div>
                            <div className="t-2 td-item"><p>Kişi Sayısı</p><i className="icon-arr-bottom"></i></div>
                            <div className="t-3 td-item resp-date" onClick={()=>{
                                this.sortChange("created_date")

                            }}><p>Ekleme tarihi</p><i className="icon-arr-bottom"></i>
                            </div>
                        </div>
                        <div className="table-block">
                            {
                                (this.props.fileGroups && this.props.fileGroups.length>0) &&
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={this.loadFunc}
                                    hasMore={this.hasMore && this.props.files.length > 19}
                                    threshold={250}
                                >

                                    {
                                        this.props.fileGroups.map((fileItem,key)=>
                                            <div className="table-item" onClick={()=>{
                                                this.toggleEditedGroup(fileItem)
                                            }}>
                                                <div className="td-item t-1"><i className="icon-user"></i><p>{fileItem.group_name}</p>
                                                </div>
                                                <div className="td-item t-2"><p>{fileItem.userCount}</p></div>
                                                <div className="td-item t-3 resp-date"><p>{fileItem.created_date}</p></div>
                                            </div>
                                        )
                                    }

                                </InfiniteScroll>
                            }
                        </div>
                    </div>
                </div>
                {
                    this.editedFileGroup && <NewFileGroupComponent editedFileGroup={this.editedFileGroup} toggleModal={this.toggleEditedGroup} {...this.props}/>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    fileGroups:state.PanelReducer.fileGroups
});

export default connect(mapStateToProps)(PanelFileUsersGroupContainer);