import React, {Component, Fragment} from 'react';
import dompurify from "dompurify";
import {endElectionAction, getElectionDetailAction, startElectionAction} from "../../actions/electionAction";
import {connect} from "react-redux";

const sanitizer = dompurify.sanitize;
const getContent = (subItems, index, focusText) => {
    return subItems.map((subItem, key) =>
        <Fragment key={key}>
            <div className="content-text-block" ref={"text-item"} onClick={(e) => {
                focusText(e.target, subItem)
            }}>
                <div className="list-count ">
                    <span>{`${index}.${key + 1}`}</span>
                </div>
                <div className="text-item" dangerouslySetInnerHTML={{__html: subItem.content}}></div>
            </div>
            {
                (subItem.subItems && subItem.subItems.length > 0) && getContent(subItem.subItems, `${index}.${key + 1}`, focusText)
            }
        </Fragment>
    )
};

class ElectionInfoPageComponent extends Component {

    printContent = React.createRef();
    getContent = (subItems, index, focusText, focusElementId, approvedSubItems, isVersion) => {
        return subItems && subItems.map((subItem, key) =>
            <Fragment key={key}>
                <div
                    className={`content-text-block`}>
                    <div className="list-count ">
                        <span>{`${index}.${key + 1}`}</span>
                    </div>
                    <div className="text-item" dangerouslySetInnerHTML={{__html: subItem.content}}></div>
                </div>
                {
                    (subItem.subItems && subItem.subItems.length > 0) && this.getContent(subItem.subItems, `${index}.${key + 1}`, focusText, null, (approvedSubItems && approvedSubItems[key] ? approvedSubItems[key].subItems : null), isVersion)
                }
            </Fragment>
        )
    };
    endElection = () => {
        this.props.dispatch(endElectionAction({election_id: this.props.link},()=>{
            this.props.dispatch(getElectionDetailAction({link:this.props.link}))
        }))
    }
    startElection = () => {
        this.props.dispatch(startElectionAction({election_id: this.props.link},()=>{
            this.props.dispatch(getElectionDetailAction({link:this.props.link}))
        }))
    };
    printEducation = (elem) => {
        let myWindow = window.open('', 'PRINT', 'height=400,width=600');

        myWindow.document.write('<html><head><title>' + document.title + '</title>');
        myWindow.document.write('<style>  div.list-count{display: none}   </style></head><body >');
        myWindow.document.write('<h1>' + document.title + '</h1>');
        myWindow.document.write(this.printContent.current.innerHTML);
        myWindow.document.write('</body></html>');

        myWindow.document.close(); // necessary for IE >= 10
        myWindow.focus(); // necessary for IE >= 10*/

        myWindow.print();
        myWindow.close();
        return true;
    };
    render() {
        let {isConditionCheck, detail, goToResult, isAdmin} = this.props;
        return (
            <div>
                <div className="detail-content-root">
                    <div className="safe-area">
                        <div className="detail-content-wrapper">
                            <div className="detail-content-container">
                                <div className="banner-img-block ">
                                    <div className="img-item"><img
                                        src="/assets/img/education/calisan-temsilcisi-secimi-2021.png"
                                        alt=""/></div>
                                    <div className="text-item">
                                        <p>{detail.detail.name}</p>
                                    </div>
                                    <div className="sticky-item safe-area">
                                        <div className="btn-sticky">
                                            <div className="btn-item ">
                                                {
                                                    (detail.detail.is_started == 1 && detail.detail.is_ended != 1) &&
                                                    <div className="left-btn" onClick={this.endElection}>
                                                        <span className="btn-back finish">SEÇİMİ BİTİR</span>
                                                    </div>
                                                }
                                                {
                                                    detail.detail.is_started != 1 &&
                                                    <div className="left-btn" onClick={this.startElection}>
                                                        <span className="btn-back finish">SEÇİMİ BAŞLAT</span>
                                                    </div>
                                                }
                                                {
                                                    detail.detail.is_started == 1 &&
                                                    <div className="left-btn">{
                                                        isAdmin &&
                                                        <span onClick={() => {
                                                            this.props.changeActiveIndex(2, "voters")
                                                        }} className="btn-back finish">OY VERENLERİ GÖR</span>
                                                    }
                                                    </div>
                                                }
                                                {
                                                    isAdmin &&
                                                    <div className="left-btn" onClick={this.printEducation}>
                                                        <span className="btn-back finish">YAZDIR</span>
                                                    </div>
                                                }
                                                <div className="right-btn">
                                                  <span className="btn-back" onClick={() => {
                                                      if (detail && detail.detail && detail.detail.is_ended == 1) {
                                                          goToResult()
                                                      } else {
                                                          this.props.changeActiveIndex(1, "result")
                                                      }
                                                  }}>SONUÇLARI GÖR</span>
                                                    {
                                                        (detail && detail.detail && detail.detail.is_ended != 1 && detail.detail.is_started==1) &&
                                                        <span className="btn-green" onClick={() => {
                                                            if (isConditionCheck) {
                                                                this.props.goToVote();
                                                            } else {
                                                                this.props.changeActiveIndex(1, "vote")
                                                            }
                                                        }}>OY KULLAN</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                                <div className="detail-content-block" ref={this.printContent}>
                                    {
                                        detail.document && detail.document.map((documentItem,index)=>
                                            <Fragment key={index}>
                                                <div
                                                    className={`content-text-block`} >
                                                    <div className="list-count head-count">
                                                        <span>{index + 1}</span>
                                                    </div>
                                                    <div className="text-item">
                                                        <h2 dangerouslySetInnerHTML={{__html: documentItem.text}}></h2>
                                                        <div className="text-item" dangerouslySetInnerHTML={{__html: documentItem.description}}></div>
                                                    </div>
                                                </div>
                                                {this.getContent(documentItem.subItems, index + 1, "", null, documentItem.approved && documentItem.approved.subItems ? documentItem.approved.subItems : null, false)}
                                            </Fragment>
                                        )
                                    }
                                    {/*<div className="content-text-block  ">
                                        <div className="list-count head-count"><span>1</span></div>
                                        <div className="text-item"><h2>SEÇİM TAKVİMİ VE KRİTERLERİ İLE İLGİLİ
                                            BİLGİLER</h2>
                                            <div className="text-item"></div>
                                        </div>
                                    </div>*/}
                                    {/*<div className="content-text-block  ">
                                        <div className="list-count "><span>1.1</span></div>
                                        <div className="text-item"><p><strong> Duyuru Tarihi: </strong> 24.11.2020</p>
                                        </div>
                                    </div>
                                    <div className="content-text-block  ">
                                        <div className="list-count "><span>1.2</span></div>
                                        <div className="text-item"><p><strong>Adayların
                                            Belirlenmesi: </strong> 24.11.2020 -
                                            08.12.2020</p></div>
                                    </div>
                                    <div className="content-text-block  ">
                                        <div className="list-count "><span>1.3</span></div>
                                        <div className="text-item"><p><strong>Temsilcilerin Oy Toplaması: </strong>08.12.2020
                                            -
                                            21.12.2020</p></div>
                                    </div>
                                    <div className="content-text-block  ">
                                        <div className="list-count "><span>1.4</span></div>
                                        <div className="text-item"><p><strong> Seçim Başlangıç: </strong> 22.12.2020 -
                                            08:00</p><p><strong>Seçim
                                            Bitiş </strong>: 23.12.2020 - 18:15</p></div>
                                    </div>
                                    <div className="content-text-block  ">
                                        <div className="list-count "><span>1.5</span></div>
                                        <div className="text-item"><p><strong>İşçi Temsilcilerinin İlanı: </strong>24.12.2020
                                        </p><p><strong>Seçim
                                            Yöntemi: </strong> Online Anket Yöntemi</p></div>
                                    </div>
                                    <div className="content-text-block  ">
                                        <div className="list-count "><span>1.6</span></div>
                                        <div className="text-item"><p>5 Kadın ve 7 Erkek olmak üzere toplam 12 asil
                                            çalışan temsilcisi seçilecektir. Aynı şekilde daha az oy alan 5 Kadın 7
                                            erkek yedek temsilci seçilecektir. <strong>Her çalışanın sadece 1 oy
                                                kullanma hakkı
                                                vardır.</strong> En çok oy alan Suriye uyruklu personelimiz 12
                                            temsilciden 1'i
                                            olacak.</p><p className="info">Seçimler tüm çalışanlara kısa mesaj ile
                                            gönderilecek olan anket aracılığı ile yapılacak olup kimlerin seçim işlemini
                                            tamamladığı görülüp kimlerin hangi adayı seçtiği görülemeyecek şekilde
                                            tasarlanmıştır. (!)</p><p>Covid-19 Pandemisi sebebi ile sandık
                                            ile oy kullanımı yerine online kapalı oylama sistemi kullanılacaktır. Oylama
                                            esnasında canlı sonuçlar günde 4 kez yenilenerek ekranlara
                                            yansıtılacaktır.</p></div>
                                    </div>
                                    <div className="content-text-block  ">
                                        <div className="list-count "><span>1.7</span></div>
                                        <div className="text-item"><p>Aday kriterleri arasında öğrenim durumu, kıdem,
                                            cinsiyet, memleket, görev, ırk, yaş vb. herhangi bir hususta limit yoktur.
                                            Kendinin ve çalışma arkadaşlarının haklarını savunabilen, iş yeri çalışma
                                            koşullarının geliştirilmesi hususunda çalışmaya gönüllü herkes aday
                                            olabilir.</p></div>
                                    </div>
                                    <div className="content-text-block  ">
                                        <div className="list-count "><span>1.8</span></div>
                                        <div className="text-item"><p>İşletmemizdeki yabancı çalışanların
                                            temsiliyetlerini yapabilmeleri için yabancı çalışanlarımızın da
                                            adaylıklarını beklemekteyiz.</p></div>
                                    </div>
                                    <div className="content-text-block  ">
                                        <div className="list-count "><span>1.9</span></div>
                                        <div className="text-item"><p>Mümkün olduğunca her departmandan adayların
                                            çıkması ve seçimlerde çeşitlilik olmasını tercih ederiz.</p></div>
                                    </div>
                                    <div className="content-text-block  ">
                                        <div className="list-count "><span>1.10</span></div>
                                        <div className="text-item"><p>Adaylar <strong>08.12.2020</strong>'ye kadar aday
                                            olmak
                                            istediklerini <strong>İnsan Kaynakları departmanına </strong> bildirmelidir.
                                        </p><p>
                                            <img src="/assets//img/education/unluTekstil_calTemsilcisi-1.png"/>
                                        </p></div>
                                    </div>
                                    <div className="content-text-block  ">
                                        <div className="list-count head-count"><span>2</span></div>
                                        <div className="text-item"><h2>ÇALIŞAN TEMSİLCİSİ İLE İLGİLİ BİLGİLER</h2>
                                            <div className="text-item"></div>
                                        </div>
                                    </div>
                                    <div className="content-text-block  ">
                                        <div className="list-count "><span>2.1</span></div>
                                        <div className="text-item"><p>Seçilmiş temsilciler, eğitimlere katılmak, ayda
                                            bir yönetimle yapılan ortak toplantılara katılmak veya iş saatlerinde şefler
                                            ve yönetimle görevleri dahilinde konuşmalar yapmak için harcadıkları zaman
                                            konusunda bir gelir aybına/ maaş kesintisine uğramayacaklardır.</p></div>
                                    </div>
                                    <div className="content-text-block  ">
                                        <div className="list-count "><span>2.2</span></div>
                                        <div className="text-item"><p>Seçilen temsilciler İSG kurulları dahil olmak
                                            üzere tüm sosyal alanlarla ilgilenmeli. Bunlar; çevre, yemek, şikayetler,
                                            ücretler, tatiller, sosyal etkinlikler, üretim prosesleri vb. olabilir.</p>
                                        </div>
                                    </div>
                                    <div className="content-text-block  ">
                                        <div className="list-count "><span>2.3</span></div>
                                        <div className="text-item"><p>Seçilmiş çalışan temsilcileri şikayetleri ve
                                            sorunları dile getirdikleri veya çalışma saatlerinde bu konular ile meşgul
                                            olduklarından dolayı herhangi bir maduriyet ve ayrımcılık yapılmaması için
                                            yönetim tarafından korunacaktır.</p></div>
                                    </div>
                                    <div className="content-text-block  ">
                                        <div className="list-count "><span>2.4</span></div>
                                        <div className="text-item"><p>Çalışan temsilcilerine kapsamlı eğitim verilecek
                                            ve eğitim mataryeli yazılı olarak verilecektir.</p></div>
                                    </div>
                                    <div className="content-text-block  ">
                                        <div className="list-count head-count"><span>3</span></div>
                                        <div className="text-item"><h2>ÇALIŞAN TEMSİLCİLERİNİN GÖREV, HAK VE
                                            SORUMLULUKLARI</h2>
                                            <div className="text-item"></div>
                                        </div>
                                    </div>
                                    <div className="content-text-block  ">
                                        <div className="list-count "><span>3.1</span></div>
                                        <div className="text-item"><p>Katılacağı toplantıların öncesinde gündem
                                            konularını toparlamak, özetlemek, toplantı öncesi gerekli tüm diğer
                                            hazırlıkları yapmak.</p></div>
                                    </div>
                                    <div className="content-text-block  ">
                                        <div className="list-count "><span>3.2</span></div>
                                        <div className="text-item"><p>Kendisine gelen tüm istek, şikayet ve fikirlerin
                                            aslını koruyarak yazılı saklamak, analiz etmek ve yönetime iletmerek süreci
                                            takip etmek.</p></div>
                                    </div>
                                    <div className="content-text-block  ">
                                        <div className="list-count "><span>3.3</span></div>
                                        <div className="text-item"><p>İşletmede proseslerde, yönetim sisteminde herhangi
                                            bir değişiklik ya da yenilik olması durumunda çalışma arkadaşlarına yol
                                            gösterici olmak ve onları yönlendirmek.</p></div>
                                    </div>
                                    <div className="content-text-block  ">
                                        <div className="list-count "><span>3.4</span></div>
                                        <div className="text-item"><p>İSG Kurulu, Temsilci Toplantıları, Şilkayet
                                            Mekanizması Kanalları ve süreçleri, Yıllık İzin Kurulu, Disiplin Kurulu vb.
                                            toplantılara katılarak çalışma arkadaşlarının lehine fikirler sunmak,
                                            toplantı sonuçlarını birebir çalışma arkadaşlarına iletmek.</p></div>
                                    </div>
                                    <div className="content-text-block  ">
                                        <div className="list-count "><span>3.5</span></div>
                                        <div className="text-item"><p>İşyerinin fiziki ve sosyal koşullarının çalışmaya
                                            daha elverişli olması için işverene yapılabilecek adımların planlanması
                                            konusunda fikir vermek, destek olmak.</p></div>
                                    </div>
                                    <div className="content-text-block  ">
                                        <div className="list-count "><span>3.6</span></div>
                                        <div className="text-item"><p>Yönetim ile yapılan toplantılarda yapıcı, çözümcül
                                            olarak fikirler sunmak</p></div>
                                    </div>
                                    <div className="content-text-block  ">
                                        <div className="list-count "><span>3.7</span></div>
                                        <div className="text-item"><p>Çalışanların haklarını bilmeleri konusunda
                                            yardımcı olmak.</p></div>
                                    </div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect()(ElectionInfoPageComponent);