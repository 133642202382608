import React,{Component} from 'react';
import {connect} from "react-redux";
import {ASSETS} from '../../constants/Path';
import {EDITEDUCATIONDETAIL_WITHOUTPARAM} from '../../constants/routePaths';
import CloseEditModalComponent from "../modals/closeEditModalComponent";
import Helper from '../../helper';

class EducationContentHeaderComponent extends Component{
    iscloseEdit = false;
    toggleCloseEdit = (value) => {
        this.iscloseEdit = value;
        this.forceUpdate();
    };
    render(){
        let {isEducationStarted, startEducation, urlLink, detailInfo, toggleIsEditing, isAdmin,history,isVersion, isEditExist, isToken} = this.props;
        return(
            <div className={`banner-img-block ${isEducationStarted === true ? "" : "t-2"}`}>
                {
                    detailInfo &&
                    <div className="img-item">
                        <img src={ASSETS + detailInfo.image} alt=""/>
                    </div>
                }
                {
                    detailInfo &&
                    <div className="info-item">
                        {
                            (isEducationStarted !== true && detailInfo.tag)  && <span className="label-tag green">#{detailInfo.tag.toLocaleUpperCase('tr')}</span>
                        }
                        <h1>{detailInfo.name}</h1>
                        {
                            (isEducationStarted !== true && detailInfo.duration) &&
                            <div className="time-block">
                                <span><i className="icon-clock"></i>{detailInfo.duration}</span>
                            </div>
                        }
                        {
                            (isEducationStarted !== true && isVersion!==true && isToken!==true) &&
                            <div className="btn-item">
                                {
                                    !detailInfo.election_id &&
                                    <span className="btn-green"
                                          onClick={() => {
                                              startEducation()
                                          }}>EĞİTİME BAŞLA</span>
                                }

                                {
                                    isAdmin === true && <span className="btn-back"
                                                              onClick={() => {
                                                                  if(isEditExist){
                                                                      {/*this.toggleCloseEdit(true);*/}
                                                                      history.push(`${EDITEDUCATIONDETAIL_WITHOUTPARAM}${Helper.turkishToLower(detailInfo.name).split(" ").join("-").split("/").join("").split("?").join("")}-${detailInfo.id}`);
                                                                  }else{
                                                                      history.push(`${EDITEDUCATIONDETAIL_WITHOUTPARAM}${Helper.turkishToLower(detailInfo.name).split(" ").join("-").split("/").join("").split("?").join("")}-${detailInfo.id}`);
                                                                  }
                                                              }}>DÜZENLE</span>
                                }
                            </div>
                        }
                    </div>
                }
                {
                    this.iscloseEdit === true && <CloseEditModalComponent toggleCloseEdit={this.toggleCloseEdit}/>
                }
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    let urlArr = ownProps.urlLink.split("-");
    return ({
        detailInfo: state.educationDetailReducer[urlArr[urlArr.length - 1]] ? state.educationDetailReducer[urlArr[urlArr.length - 1]].info : null,
        isAdmin: state.sessionReducer.role_id==="1" || state.sessionReducer.role_id==="3"
    });
};

export default connect(mapStateToProps)(EducationContentHeaderComponent);