import React, {Component} from 'react';
import moment from "moment-timezone";
import {ASSETS} from "../../constants/Path";

class PanelFileActiveVersionComponent extends Component {


    render() {
        let {versions} = this.props
        let selectedVersion = versions.find(item => item.is_current == "1");
        return (
            <div className="active-version-block">
                <div className="head-item">
                    <span>Aktif Versiyon</span>
                    {
                        selectedVersion &&
                        <div className="date">
                            <span>{moment(selectedVersion.created_date).tz("Europe/Istanbul").format("DD MMM YYYY")}</span>, <span>{selectedVersion.created_date.split(" ")[1]}</span>
                        </div>
                    }
                </div>
                {
                    selectedVersion &&
                    <div className="file-block">
                        {
                            selectedVersion.pdf_path &&
                            <div className="box-item added">
                                <div className="added-item">
                                    <i className="icon-doc"/>
                                    <p>{selectedVersion.pdf_path.replace("/files/", "")}</p>
                                </div>
                                <a href={ASSETS + selectedVersion.pdf_path} download className="btn-download">
                                    <i>
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18 9L11.9999 15.0001L6 9" stroke="black" strokeWidth={2}/>
                                            <path d="M12.0005 4V14.6567" stroke="black" strokeWidth={2}/>
                                            <path d="M17 19L7 19" stroke="black" strokeWidth={2}/>
                                        </svg>
                                    </i>
                                    <span>DOSYAYI İNDİR</span>
                                </a>
                            </div>
                        }
                        {
                            selectedVersion.pptx_path &&
                            <div className="box-item added">
                                <div className="added-item">
                                    <i className="icon-doc"/>
                                    <p>{selectedVersion.pptx_path.replace("/files/", "")}</p>
                                </div>
                                <a href={ASSETS + selectedVersion.pptx_path} download
                                   className="btn-download">
                                    <i>
                                        <svg width={24} height={24} viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M18 9L11.9999 15.0001L6 9" stroke="black" strokeWidth={2}/>
                                            <path d="M12.0005 4V14.6567" stroke="black" strokeWidth={2}/>
                                            <path d="M17 19L7 19" stroke="black" strokeWidth={2}/>
                                        </svg>
                                    </i>
                                    <span>DOSYAYI İNDİR</span>
                                </a>
                            </div>
                        }
                    </div>
                }
            </div>
        );
    }
}

export default PanelFileActiveVersionComponent;