import React, {Component} from 'react';

import ElectionInfoPageComponent from "../../components/Panel/electionInfoPageComponent";

class ElectionInfoPageContainer extends Component {
    render() {
        return (
            <div>
               <ElectionInfoPageComponent link={this.props.link} isAdmin={this.props.isAdmin} goToResult={this.props.goToResult} detail={this.props.detail} goToVote={this.props.goToVote} isConditionCheck={this.props.isConditionCheck} changeActiveIndex={this.props.changeActiveIndex}/>
            </div>
        );
    }
}

export default ElectionInfoPageContainer;