import React, {Component} from 'react';
import {getFileUsersAction} from "../actions/fileUserAction";
import {connect} from "react-redux";
import NewParticipantModalComponent from "../components/modals/newParticipantModalComponent";
import NewFileUserComponent from "../components/modals/newFileUserComponent";
import InfiniteScroll from "react-infinite-scroller";

class PanelFileUsersContainer extends Component {
    activePageIndex=0;
    orderName ="created_date";
    orderType ="asc";
    hasMore=true;
    editedFileUser;
    constructor(props){
        super(props);
        this.getData();
    }
    getData = () =>{
        if(this.hasMore){
            this.hasMore=false;
            this.forceUpdate();
            this.props.dispatch(getFileUsersAction({index:this.activePageIndex,orderName:this.orderName,orderType:this.orderType},(res)=>{
                this.hasMore=res;
                this.forceUpdate()

            }))
        }
    }
    sortChange = (sortName) => {
        this.activePageIndex = 0;
        this.hasMore = true;
        this.orderType = this.orderName === sortName ? (this.orderType === "asc"?"desc":"asc") : "asc"
        this.orderName = sortName;
        this.getData()
    }

    toggleEditedUser = (item) => {
        this.editedFileUser = item;
        this.forceUpdate();
    }

    loadFunc= () => {
        this.activePageIndex = (this.activePageIndex+1)
        this.getData()
    }

    render() {
        return (
            <div className="panel-content-block file-user-group">
                <div className="breadcrumb-menu">
                    <ul>
                        <li>Kişiler</li>
                    </ul>
                </div>
                <div className="table-root">
                    <div className="table-container">
                        <div className="table-head">
                            <div className="t-1 td-item" onClick={()=>{
                                this.sortChange("name_surname")

                            }}><p>Ad Soyad</p><i className="icon-arr-bottom"></i></div>
                            <div className="t-2 td-item"><p>Bulunduğu Grup</p><i className="icon-arr-bottom"></i></div>
                            <div className="t-3 td-item resp-date" onClick={()=>{
                                this.sortChange("created_date")

                            }}><p>Ekleme tarihi</p><i className="icon-arr-bottom"></i>
                            </div>
                        </div>
                        <div className="table-block">
                            {
                                (this.props.fileUsers && this.props.fileUsers.length>0) &&
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={this.loadFunc}
                                    hasMore={this.hasMore && this.props.fileUsers.length > 19}
                                    threshold={250}
                                >
                                    {

                                        this.props.fileUsers.map((fileItem,key)=>
                                            <div className="table-item" onClick={()=>{
                                                this.toggleEditedUser(fileItem)
                                            }}>
                                                <div className="td-item t-1" ><i className="icon-user2"></i><p>{fileItem.name_surname}</p>
                                                </div>
                                                <div className="td-item t-2"><p>{fileItem.group_name}</p></div>
                                                <div className="td-item t-3 resp-date"><p>{fileItem.created_date}</p></div>
                                            </div>
                                        )
                                    }

                                </InfiniteScroll>
                            }
                        </div>
                    </div>

                </div>
                {
                    this.editedFileUser && <NewFileUserComponent editedFileUser={this.editedFileUser} toggleModal={this.toggleEditedUser} {...this.props}/>
                }
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    fileUsers:state.PanelReducer.fileUsers
})

export default connect(mapStateToProps)(PanelFileUsersContainer);