import React, {Component, Fragment} from 'react';
import {connect,change} from "react-redux";

class AutoCompleteFieldComponent extends Component {
    autoCompleteArr;
    autoIsShowing = false;
    isUnmounted = false;
    onChangeFunc = (value) => {
        if (value.length > 0) {
            this.props.dispatch(this.props.autoCompleteAction({searchTerm: value}, (res) => {
                if (res !== false) {
                    this.autoCompleteArr = res.fileGroups?res.fileGroups:res;
                    if(!this.isUnmounted){
                        this.forceUpdate();
                    }
                }
            }));
        } else {
            this.autoCompleteArr = [];
            this.forceUpdate();
        }
    };
    selectedFunc = (item) => {
        this.props.selectedFunc(item);
        this.autoCompleteArr = [];
        this.props.input.onChange(item.smart_document_name ? item.smart_document_name : (item.group_name?item.group_name : item.name));
    };
    changeFocus = (value) => {
        this.autoIsShowing = value;
        if(!this.isUnmounted){
            this.forceUpdate();
        }
    };
    componentWillUnmount(){
        this.isUnmounted = true;
    }
    render() {
        const {input, meta: {error, touched}, placeholder, type, label, onChangeFunc} = this.props;
        return (
            <Fragment>
                <div className="input-item">
                    <label>{label}
                        <input {...input} autoComplete="off"
                               onFocus={(e)=>{
                                   input.onFocus(e);
                                   this.changeFocus(true)
                               }}
                               onBlur={(e)=>{

                                   setTimeout(()=>{

                                       if(this.autoCompleteArr && this.autoCompleteArr.length===1){

                                           this.selectedFunc(this.autoCompleteArr[0])

                                       }else{
                                           this.changeFocus(false)
                                       }

                                       {/*input.onBlur(e);*/}
                                   },200)

                               }}
                               onChange={(e) => {
                                   input.onChange(e);
                                   this.onChangeFunc(e.target.value)
                               }}
                               className={`${error && touched === true ? "error" : ""}`} type={type}
                               placeholder={placeholder}/>
                    </label>
                    {
                        (this.autoIsShowing === true && this.autoCompleteArr && this.autoCompleteArr.length > 0) &&
                        <div className="autocomplete-root">
                            <ul>
                                {
                                    this.autoCompleteArr.map((item, key) =>
                                        <li onClick={() => {
                                            this.selectedFunc(item)
                                        }}
                                            key={key}>{item.smart_document_name ? item.smart_document_name : (item.group_name?item.group_name : item.name)}</li>
                                    )
                                }
                            </ul>
                        </div>

                    }
                </div>
            </Fragment>
        );
    }
}

export default connect()(AutoCompleteFieldComponent);
