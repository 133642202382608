import React, {Component} from 'react';
import moment from "moment";
import PanelFileFeedbackContainer from "../../containers/Panel/PanelFileFeedbackContainer";
import {FILE_SHARE, PANEL_FILES} from "../../constants/routePaths";

class PanelFileDetailFeedback extends Component {
    feedBackDetail;
    sortKey="id";
    sortType="asc";
    toggleFeedBackModal = (value) => {
        this.feedBackDetail = value;
        this.forceUpdate();
    }
    sortFunc = (obj1,obj2) => {
        let value1 = this.sortKey==="name_surname"?obj1.fileUsers[this.sortKey]:obj1[this.sortKey];
        let value2 = this.sortKey==="name_surname"?obj2.fileUsers[this.sortKey]:obj2[this.sortKey]
        if (value1<value2) {
            return this.sortType === "asc" ? -1:1;
        }
        if (value1>value2) {
            return this.sortType === "asc" ? 1:-1;
        }
        return 0;
    }
    changeSort = (type) => {
        this.sortType = type===this.sortKey?(this.sortType==="asc"?"desc":"asc"):"asc";
        this.sortKey = type;
        this.forceUpdate();
    }
    render() {
        let {fileFeedBacks,uploadedFileInfo} = this.props;
        return (
            <div>
                <div className="table-head">
                    <div className="t-1 td-item" onClick={()=>{
                        this.changeSort("name_surname")
                    }}>
                        <p>Ad Soyad</p>
                        <i className="icon-arr-bottom"></i>
                    </div>
                    <div className="t-2 td-item" onClick={()=>{
                        this.changeSort("group_name")
                    }}>
                        <p>Dahil olduğu grup</p>
                        <i className="icon-arr-bottom"></i>
                    </div>
                    <div className="t-2 td-item" onClick={()=>{
                        this.changeSort("created_date")
                    }}>
                        <p>Geri bildirim zamanı</p>
                        <i className="icon-arr-bottom"></i>
                    </div>
                </div>
                {
                    fileFeedBacks && fileFeedBacks.sort(this.sortFunc).map((item,key)=>
                        <div className="table-item" key={key} onClick={()=>{
                            this.toggleFeedBackModal(item)
                        }}>
                            <div className="td-item t-1"><i className="icon-user2"></i>
                                <p>{item.fileUsers.name_surname}</p>
                            </div>
                            <div className="td-item t-2"><p>{item.group_name}</p></div>
                            <div className="td-item t-2"><p>{moment(item.created_date).format("DD.MM.YYYY")}</p></div>
                        </div>
                    )
                }
                {
                    this.feedBackDetail &&
                    <PanelFileFeedbackContainer getData={this.props.getData} toggleFeedBackModal={this.toggleFeedBackModal} feedBacks={this.feedBackDetail.feedBacks} fileName={uploadedFileInfo.name} file_user_id={this.feedBackDetail.file_user_id} fileUsers={this.feedBackDetail.fileUsers}/>
                }
                <div className="sticky-item ">
                    <div className="btn-sticky safe-area">
                        <div className="left-item">
                            <div className="btn-item"><span className="btn-back" onClick={() => {
                                this.props.history.push(PANEL_FILES);
                            }}>TÜM DOSYALARA DÖN</span>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PanelFileDetailFeedback;