import React, {Component} from 'react';
import FileLoginComponent from "../components/login/fileLoginComponent";
import FileLoginActivationComponent from "../components/login/fileLoginActivationComponent";

class FileLoginPage extends Component {
    codeModal = false;
    userEmail;
    toggleCodeModal = (codeModal,userEmail) => {
        this.codeModal = codeModal;
        this.userEmail = userEmail;
        this.forceUpdate();
    }

    render() {
        return (
            <div>
                <div className="login-root">
                    <div className="login-wrapper safe-area">
                        <div className="login-con">
                            <div className="login-block">
                                <div className="left-block">
                                    <div className="logo-item">
                                        <img src="/assets/img/unluTekstilLogo2.png" alt="unluTekstilLogo"/></div>
                                </div>
                                {
                                    !this.codeModal &&
                                    <FileLoginComponent location={this.props.location} openCodeModal={this.toggleCodeModal}/>
                                }
                                {
                                    this.codeModal &&
                                    <FileLoginActivationComponent location={this.props.location} userEmail={this.userEmail} history={this.props.history} toggleCodeModal={this.toggleCodeModal}/>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FileLoginPage;