import React, {Component, Fragment} from 'react';
import {Field, reduxForm} from "redux-form";
import inputFieldComponent from "../components/common/formFields/inputFieldComponent";
import UploadFileFieldComponent from "../components/common/formFields/uploadfileFieldComponent";
import {createUploadedFileAction, getFileUsersAutoCompleteAction} from "../actions/fileAction";
import {PANEL_FILE_DETAIL_WITHOUT_DETAIL} from "../constants/routePaths";
import LoaderModalComponent from "../components/common/loaderModalComponent";
import NewFileUserComponent from "../components/modals/newFileUserComponent";
import NewFileGroupComponent from "../components/modals/newFileGroupComponent";
import PanelNewFileContainer from "../containers/Panel/PanelNewFileContainer";

class PanelNewFilePage extends Component {

    toggleModal = () => {
        this.isNewFileUser = false;
        this.isNewFileGroup = false;
        this.forceUpdate()
    }

    openModal = (key) => {
        this[key] = true;
        this.forceUpdate();
    }

    render() {
        return (
            <Fragment>
                <PanelNewFileContainer history={this.props.history} openModal={this.openModal}/>
                {
                    this.isNewFileUser === true &&
                    <NewFileUserComponent isNewFileUser={this.isNewFileUser}
                                          toggleModal={this.toggleModal} {...this.props}/>
                }
                {
                    this.isNewFileGroup === true &&
                    <NewFileGroupComponent isNewFileGroup={this.isNewFileGroup}
                                           toggleModal={this.toggleModal} {...this.props}/>
                }
            </Fragment>
        );
    }
}

export default PanelNewFilePage;