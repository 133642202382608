import React, {Component, Fragment} from "react";
import {Field, initialize, reduxForm} from "redux-form";
import {connect} from "react-redux";
import inputFieldComponent from "../common/formFields/inputFieldComponent";
import autoCompleteFieldComponent from "../common/formFields/autoCompleteFieldComponent";
import {createFileUserAction, getFileUsersAutoCompleteAction} from "../../actions/fileAction";
import LoaderModalComponent from "../common/loaderModalComponent";
import SuccessModalComponent from "../common/successModalComponent";
import {getParticipantDetailAction, receiveParticipants} from "../../actions/panelAction";
import {getFileUsersAction} from "../../actions/fileUserAction";

const validate = (values) => {
    let hasErrors = false;
    let errors = {};
    if (!values.name_surname || values.name_surname.trim() === "") {
        hasErrors = true;
        errors.name_surname = "Adı Doldurmanız Gerekmektedir.";
    }
    if (!values.file_group || values.file_group.trim() === "") {
        hasErrors = true;
        errors.file_group = "Grup Alanını Doldurmanız Gerekmektedir.";
    }
    if (!values.email || values.email.trim() === "" || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        hasErrors = true;
        errors.email = "Email Doldurmanız Gerekmektedir.";
    }
    return hasErrors && errors;
};

class NewFileUserComponent extends Component {
    selectedFileGroup;
    successMessage = false;

    constructor(props) {
        super(props);
        if (props.editedFileUser) {
            props.dispatch(initialize('createNewFileUser', {
                name_surname: props.editedFileUser.name_surname,
                email: props.editedFileUser.email,
                file_group_id: props.editedFileUser.file_group_id,
                id: props.editedFileUser.id,
                file_group:props.editedFileUser.group_name
            }));
            this.selectedFileGroup = {id: props.editedFileUser.file_group_id, group_name: props.editedFileUser.group_name}
        }
    }

    submitForm = (values) => {
        this.loaderIsShowing = true;
        this.errMsg = null;
        this.forceUpdate();
        this.props.dispatch(createFileUserAction({
            ...values,
            file_group_id: this.selectedFileGroup ? this.selectedFileGroup.id : null
        }, (res, message) => {
            this.loaderIsShowing = false;
            if (res === true) {
                this.successMessage = true;
                this.forceUpdate();
                this.props.dispatch(getFileUsersAction({
                    index: 0,
                    orderName: "name_surname",
                    orderType: "asc"
                }, (res) => {
                }))
                setTimeout(() => {
                    this.props.toggleModal(false, "isNewFileUser");
                }, 3000);
            } else {
                this.errMsg = message;
                this.forceUpdate();
            }
        }))

    }

    fileGroupSelected = (selectedFileGroup) => {
        this.selectedFileGroup = selectedFileGroup
    }

    render() {
        let {handleSubmit, toggleModal} = this.props;
        return (
            <Fragment>
                {
                    this.successMessage === false &&
                    <div className="lb-root">

                        <div className="lb-block">
                            <form onSubmit={handleSubmit(this.submitForm)} className="content-block add-part-block">
                                <div className="content-item">
                                    <div className="head-block">
                                        <h2>Yeni Kişi Yarat</h2>
                                        <div className="info-item">
                                            <p>Yeni bir kişi oluşturun.</p>
                                        </div>
                                    </div>
                                    <i className="icon-close" onClick={() => {
                                        toggleModal(false, "isNewFileUser");
                                    }}></i>
                                    <div className="form-block">
                                        <div className="input-container">
                                            <div className="input-block">
                                                <div className="input-container">
                                                    <div className="input-block">
                                                        <Field
                                                            name="name_surname"
                                                            label="ADI SOYADI"
                                                            type="text"
                                                            component={inputFieldComponent}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-container">
                                            <div className="input-block">
                                                <div className="input-container">
                                                    <div className="input-block">
                                                        <Field
                                                            name="email"
                                                            label="E-POSTA ADRESİ"
                                                            type="text"
                                                            component={inputFieldComponent}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="input-container">
                                            <div className="input-block">
                                                <Field
                                                    name="file_group"
                                                    type="text"
                                                    label="Bulunduğu Grup"
                                                    selectedFunc={this.fileGroupSelected}
                                                    autoCompleteAction={getFileUsersAutoCompleteAction}
                                                    example="Yazmaya başlayın..."
                                                    component={autoCompleteFieldComponent}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bottom-btn-item ">
                                        <p>
                                            {this.errMsg || "" }
                                        </p>
                                        <button type="submit" className="btn-green">{this.props.editedFileUser ? "KİŞİ GÜNCELLE":"KİŞİ YARAT"}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                }
                {
                    this.loaderIsShowing === true && <LoaderModalComponent/>
                }
                {
                    this.successMessage === true && <SuccessModalComponent
                        message={this.props.editedFileUser ? "Katılımcı Güncellendi." : "Katılımcı Eklendi."}/>
                }
            </Fragment>
        )
    }
};

NewFileUserComponent = reduxForm({
    form: "createNewFileUser",
    validate
})(NewFileUserComponent);

export default connect()(NewFileUserComponent);