import React, {Component} from 'react';
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import {checkCodeAction, sendEmailToUserAction} from "../../actions/fileAction";
import InputFieldComponent from "../common/formFields/inputFieldComponent";
import {FILE_SHARE} from "../../constants/routePaths";
import queryString from "query-string";

const validate = (values)=>{
    let hasError = false;
    let errors = {};

    if(!values.email_code){
        hasError = true;
        errors.email_code = true;
    }
    return hasError && errors;
};

class FileLoginActivationComponent extends Component {
    filePermitError = false;
    submitForm = (values) => {
        this.props.dispatch(checkCodeAction({...values,isFileDetail:false,email:this.props.userEmail},(res)=>{
            if(res){
                const urlParams = queryString.parse(this.props.location.search)
                if(urlParams && urlParams.redirectUrl){
                    this.props.history.push(urlParams.redirectUrl);
                }else{
                    this.props.history.push(FILE_SHARE);
                }
            }else{
                this.filePermitError = true;
                this.forceUpdate();
            }
        }))
    }
    render() {
        let {handleSubmit} = this.props;
        return (
            <div className="right-block">
                <div className="text-block">
                    <h2>Verification</h2>
                    <p>Paste the code from the email address you entered in the previous step. </p>
                </div>
                <form onSubmit={handleSubmit(this.submitForm)}>
                    <div>
                        <div className="input-block">
                            <Field
                                name="email_code"
                                type="text"
                                label="VERIFICATION CODE"
                                component={InputFieldComponent}
                            />
                        </div>
                        {
                            this.filePermitError &&
                            <div className="content-item ">
                                <div className="head-block lb-head ">
                                    <div className="error-message">
                                        <p> Incorrect code</p>
                                    </div>

                                </div>
                            </div>
                        }
                        <div className="input-bottom">
                            <input type="submit" className="btn-green" value="LOGIN"/>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}



FileLoginActivationComponent = connect()(FileLoginActivationComponent);

export default reduxForm({
    form: 'checkCodeEmail',
    validate
})(FileLoginActivationComponent);