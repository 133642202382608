import React,{Component} from 'react';
import * as ROUTES from "../../constants/routePaths";
import {Link} from "react-router-dom";
import Helper from '../../helper';

class AllEducationsContainer extends Component{
    classIcons = ["icon-message","icon-department","icon-topic","icon-coordination","icon-management","icon-chemicals"];
    hoverIndex = 0;
    render(){
        let {educationCategories} = this.props;
        return(
            <div className="open-block">
                <ul>
                    {
                        (educationCategories && educationCategories.length > 0) && educationCategories.map((eduCategory, i) =>
                            <li onMouseEnter={(e) => {
                                if (this.hoverIndex !== i) {
                                    this.hoverIndex = i;
                                    this.forceUpdate();
                                }
                            }} key={i}
                                className={"cat-li " + (typeof window !== "undefined" && window.innerWidth > 850 && i === this.hoverIndex ? "active" : "")}
                                onClick={(e) => {
                                    if (window.innerWidth < 851) {
                                        e.preventDefault();
                                        this.props.history.push(ROUTES.ALLEDUCATIONS);
                                    }
                                }}>
                                                <span>
                                                    <i className={this.classIcons[i]}></i>
                                                    {eduCategory.name.toUpperCase("tr")}
                                                    </span>
                                <div className="sub-menu-root">
                                    <ul>
                                        {
                                            eduCategory.educations.slice(0, 19).map((edu, eduInx) =>
                                                <li key={eduInx}>
                                                    <Link to={`${ROUTES.EDUCATIONDETAIL_WITHOUTPARAM}${Helper.turkishToLower(edu.name).split(" ").join("-").split("/").join("").split("?").join("")}-${edu.id}`}>{Helper.titleCase(edu.name)}</Link>
                                                </li>
                                            )
                                        }
                                        {
                                            eduCategory.educations.length >= 20 &&
                                            <li className="see-more">
                                                <Link to={`${ROUTES.ALLEDUCATIONS}?education=${Helper.turkishToLower(eduCategory.name)}`}>Devamını Gör</Link></li>
                                        }
                                    </ul>
                                </div>
                            </li>
                        )
                    }

                </ul>
            </div>
        )
    }
};

export default AllEducationsContainer;