import React, {Component} from 'react';
import {sendEmailToUserAction} from "../../actions/fileAction";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import InputFieldComponent from "../common/formFields/inputFieldComponent";
import queryString from "query-string";

const validate = (values)=>{
    let hasError = false;
    let errors = {};

    if(!values.email || values.email.trim() === "" || !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)){
        hasError = true;
        errors.email = true;
    }
    return hasError && errors;
};


class FileLoginComponent extends Component {
    filePermitError = false;
    submitForm = (values) => {
        const urlParams = queryString.parse(this.props.location.search)
        let isFileDetail = false;
        let file_id = null;
        if(urlParams && urlParams.redirectUrl){
            isFileDetail=true;
            file_id = urlParams.redirectUrl.split("/")[2]
        }
        this.props.dispatch(sendEmailToUserAction({...values,isFileDetail:isFileDetail,file_id},(res)=>{
            if(res){
                this.props.openCodeModal(true,values.email)
            }else{
                this.filePermitError = true;
                this.forceUpdate();
            }
        }))
    }
    render() {
        let {handleSubmit} = this.props;
        return (
            <div className="right-block">
                <div className="text-block">
                    <h2>Login</h2>
                    <p>Login to Unlü Tekstil Sustainability portal.</p>
                </div>
                <form onSubmit={handleSubmit(this.submitForm)}>
                    <div>
                        <div className="input-block">
                            <Field
                                name="email"
                                type="text"
                                label="USER"
                                placeholder="Enter your email address"
                                component={InputFieldComponent}
                            />
                        </div>
                        {
                           this.filePermitError &&
                           <div className="content-item ">
                               <div className="head-block lb-head ">
                                   <div className="error-message">
                                       <p>Intrusion Attempt</p>
                                       <p>You are not authorized to view this screen. If you think there is an error, contact the system administrator.</p>
                                   </div>

                               </div>
                           </div>
                        }

                        <div className="input-bottom">
                            <input type="submit" className="btn-green" value="GO"/>
                        </div>
                    </div>
                </form>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    errors:state.form.sendCodeEmail?state.form.sendCodeEmail.syncErrors:null
});

FileLoginComponent = connect(mapStateToProps)(FileLoginComponent);

export default reduxForm({
    form: 'sendCodeEmail',
    validate
})(FileLoginComponent);