import {RECEIVE_ELECTION_DETAIL} from '../constants/actionTypes';

const initialState = {

};

const ElectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_ELECTION_DETAIL:
            return {
                ...state,
                ...action.payload
            };
        default:
            return state;
    }
};

export default ElectionReducer;