import axios from 'axios';
import * as URLS from "./../constants/urls";
import {WEB_SERVICE_API_URL_EDUCATION} from "../constants/urls";

const configJson = {
    headers: {
        'Content-Type': 'application/json; charset=utf-8'
    }
};

export const getEducationCategories = () => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}getEducationCategories`);
    // return axios.get(`${URLS.API_URL}/getEducationCategories.json`);
};

export const getEducationInfo = (education_id) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL_EDUCATION}getEducationInfo`, "params=" + JSON.stringify(education_id));
    // return axios.get(`${URLS.API_URL}/getEducationCategories.json`);
};

export const getTags = () => {
    return axios.get(`${URLS.WEB_SERVICE_API_URL}getTags`);
    // return axios.get(`${URLS.API_URL}/getEducationCategories.json`);
};

export const getPanelTagList = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/getTagsList`, "params=" + JSON.stringify(params));
    // return axios.get(`${URLS.API_URL}/getEducationCategories.json`);
};

export const getTagsBySearch = (searchTerm) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}getTagsBySearch`, "searchTerm=" + searchTerm);
    // return axios.get(`${URLS.API_URL}/getEducationCategories.json`);
};

export const getTagDetail = (id) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/getTagDetail`, "params=" + JSON.stringify(id));
    // return axios.get(`${URLS.API_URL}/getEducationCategories.json`);
};

export const createNewTag = (id) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/createNewTag`, "params=" + JSON.stringify(id));
    // return axios.get(`${URLS.API_URL}/getEducationCategories.json`);
};

export const getOpenEducation = () => {
    return axios.get(`${WEB_SERVICE_API_URL_EDUCATION}getOpenEducation`);
    // return axios.get(`${URLS.API_URL}/getOpenEducation.json`);
};


export const getUserNotifications = () => {
    // return axios.get(`${URLS.API_URL}/getUserNotifications`);
    // return axios.get(`${URLS.API_URL}/getUserNotifications.json`);
    return axios.get(`${URLS.WEB_SERVICE_API_URL}user/getUserNotifications`)
};

export const getFeatureCourses = () => {
    return axios.get(`${URLS.WEB_SERVICE_API_URL}/home/getPlannedEducation`);
    // return axios.get(`${URLS.API_URL}/getFeatureCourses.json`);
};

export const getMainTiles = () => {
    return axios.get(`${URLS.WEB_SERVICE_API_URL}/home/getMainTiles`);
    // return axios.get(`${URLS.API_URL}/getMainTiles.json`);
};

export const getFundamentalCourses = () => {
    return axios.get(`${WEB_SERVICE_API_URL_EDUCATION}GetFundamentalEducations`);
    // return axios.get(`${URLS.API_URL}/getFundamentalCourses.json`);
};

export const getNews = () => {
    return axios.get(`${URLS.WEB_SERVICE_API_URL}home/getHomeNews`);
    // return axios.get(`${URLS.API_URL}/getNews.json`);
};

export const getSurvey = () => {
    return axios.get(`${URLS.WEB_SERVICE_API_URL}/home/getSurvey`);
    // return axios.get(`${URLS.API_URL}/getSurvey.json`);
};

export const answerToSurveyService = (answer) => {
    // return axios.get(`${URLS.API_URL}/answerToSurvey`,answer);
    return axios.get(`${URLS.API_URL}/answerToSurvey.json`);
};

export const login = (values) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}user/login`, "params=" + JSON.stringify(values))
    // return axios.get(`${URLS.WEB_SERVICE_API_URL}user/login`)
}

export const signUp = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}user/signUp`, "params=" + JSON.stringify(params))
};

export const forgotPassword = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}user/forgotPassword`, "params=" + JSON.stringify(params))
};

export const getEducationDetail = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL_EDUCATION}getEducationDetail`, "params=" + JSON.stringify(params));
    // return axios.get(`${URLS.API_URL}getEducationDetail.json`)
};


export const getEducationTokenDetail = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}user/getEducationDetailWithToken`, "params=" + JSON.stringify(params));
};

export const getDocuments = (params) => {
    if (params.subPage === "version") {
        return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/education/getVersionDocuments`, "params=" + JSON.stringify(params))
    } else {
        return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/education/getDocuments`, "params=" + JSON.stringify(params));
    }
    // return axios.get(`${URLS.API_URL}getDocuments.json`)
};

export const getUsers = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/getUsers`, "params=" + JSON.stringify(params));
};

export const getPanelEducations = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/getStartedEducation`, "params=" + JSON.stringify(params))
    // return axios.get(`${URLS.API_URL}getEducations.json`)
};

export const getPanelFeedBack = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/getEducationFeedBack`, "params=" + JSON.stringify(params));
    // return axios.get(`${URLS.API_URL}getPanelFeedBack.json`)
};

export const getPanelQuiz = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/getEducationQuiz`, "params=" + JSON.stringify(params));
    // return axios.get(`${URLS.API_URL}getPanelQuiz.json`)
};

export const getPanelDrafts = () => {
    return axios.get(`${URLS.API_URL}getPanelDrafts.json`)
};

export const getPanelLocations = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}getPanelLocations`, "params=" + JSON.stringify(params))
};

export const getSmartDocuments = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/getSmartDocuments`, "params=" + JSON.stringify(params))
    // return axios.get(`${URLS.API_URL}getSmartDocuments.json`)
};

export const getPanelNotifications = () => {
    return axios.get(`${URLS.API_URL}getPanelNotifications.json`)
};

export const saveFeedBack = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL_EDUCATION}addFeedBackToEducation`, "params=" + encodeURIComponent(JSON.stringify(params)));
    // return axios.post(`${URLS.API_URL}saveFeedBack`,"params="+JSON.stringify(params))
};

export const sendMailEducationToUser = (params) => {
    // return axios.post(`${URLS.API_URL}sendMailEducationToUser`,"params="+JSON.stringify(params))
    return axios.post(`${URLS.WEB_SERVICE_API_URL}/education/sendMailEducationToUser`, "params=" + JSON.stringify(params))
};

export const getEducationPdf = (params) => {
    return axios.post(`${URLS.API_URL}getEducationPdf`, "params=" + JSON.stringify(params))
};

export const getDepartmentAutoComplete = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}auto-complete/departmentAutoComplete`, "params=" + JSON.stringify(params))
};

export const getActiveUser = () => {
    return axios.get(`${URLS.WEB_SERVICE_API_URL}user/getActiveUser`)
};

export const educationAddParticipant = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL_EDUCATION}educationAddParticipant`, "params=" + JSON.stringify(params))
};

export const startEducationSession = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL_EDUCATION}startEducationSession`, "params=" + JSON.stringify(params))
};

export const endEducationSession = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL_EDUCATION}endEducationSession`, "params=" + JSON.stringify(params))
};

export const breakSession = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL_EDUCATION}breakSession`, "params=" + JSON.stringify(params))
};

export const endBreakSession = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL_EDUCATION}endBreakSession`, "params=" + JSON.stringify(params))
};

export const createNewEducation = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/createNewEducation`, "params=" + encodeURIComponent(JSON.stringify(params)))
};

export const saveEducationPicture = (image, type) => {
    let formData = new FormData();
    formData.append(type, image);
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/education/saveEducationPicture`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const getParticipantsQrCodes = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}quiz/getQuizQRCodes`, "session_id=" + params.session_id)
};

export const addDocumentPicture = (image, type) => {
    let formData = new FormData();
    formData.append('education', image);
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/education/addDocumentPicture`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const getQuizQuestion = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}getQuizQuestion`, "params=" + JSON.stringify(params))
};

export const getTeacherQuizQuestion = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}getTeacherQuizQuestion`, "params=" + JSON.stringify(params))
};

export const answerQuestion = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}answerQuestion`, "params=" + JSON.stringify(params))
};

export const teacherAnswerQuestion = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}teacherAnswerQuestion`, "params=" + JSON.stringify(params))
};

export const updateDocumentContent = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/education/updateDocumentContent`, "params=" + encodeURIComponent(JSON.stringify(params)))
};

export const updateUser = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/updateUser`, "params=" + JSON.stringify(params))
};

export const getUserDetail = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/getUserDetail`, "params=" + JSON.stringify(params))
};

export const getSmartDocumentsAutoComplete = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/getSmartDocumentsAutoComplete`, params, configJson)
};

export const createSmartDocument = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/createSmartDocument`, params, configJson)
};

export const createNewLocation = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/createNewLocation`, params, configJson);
};

export const getSmartDocumentDetail = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/getSmartDocumentDetail`, params, configJson);
};

export const updateSmartDocumentDetail = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/updateSmartDocumentDetail`, "params=" + encodeURIComponent(JSON.stringify(params)));
};

export const createPasswordWithToken = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}user/createPasswordWithToken`, "params=" + JSON.stringify(params));
};

export const getQuizProcess = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}getQuizProcess`, "params=" + JSON.stringify(params));
};

export const startQuiz = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}quiz/startQuiz`, "session_id=" + params.session_id)
};

export const createNewSessionQuiz = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}quiz/createNewSessionQuiz`, "params=" + JSON.stringify(params))
};

export const endQuiz = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}quiz/endQuiz`, "params=" + JSON.stringify(params))
};

export const getQuizResults = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}quiz/getQuizResults`, "params=" + JSON.stringify(params))
};

export const search = (searchTerm) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}/search`, "searchTerm=" + searchTerm)
};

export const answerSurvey = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}/home/answerSurvey`, "params=" + JSON.stringify(params))
};

export const getSessionDocuments = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}/user/getSessionDocuments`, "params=" + JSON.stringify(params))
};

export const saveSessionDocuments = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}/user/saveSessionDocuments`, "params=" + JSON.stringify(params))
};

export const uploadSessionDocument = (image, type) => {
    let formData = new FormData();
    formData.append(type, image);
    return axios.post(`${URLS.WEB_SERVICE_API_URL}/user/uploadSessionDocument`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const logOut = () => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}user/logOut`)
};

export const getVersionEducationDetail = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}/admin/education/getVersionEducationDetail`, "params=" + JSON.stringify(params))

};

export const downloadPdf = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}/downloadPdf`, "params=" + encodeURIComponent(params))
};

export const saveEducationVideo = (video, type) => {
    let formData = new FormData();
    formData.append(type, video);
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/education/saveEducationVideo`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
};

export const getDepartmentParticipants = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL_EDUCATION}/getDepartmentParticipants`, "params=" + JSON.stringify(params));
};

export const participantAutoComplete = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}auto-complete/participantAutoComplete`, "params=" + JSON.stringify(params));
};

export const getEducationSessionByYear = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/report/getEducationSessionByYear`, "params=" + JSON.stringify(params));
};

export const getEducationSessionUsersByYear = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/report/getEducationSessionUsersByYear`, "params=" + JSON.stringify(params));
};

export const getEducationSessionDepartmentByYear = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/report/getEducationSessionDepartmentByYear`, "params=" + JSON.stringify(params));
};

export const getDepartmentQuizResult = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/report/getDepartmentQuizResult`, "params=" + JSON.stringify(params));
};

export const getEducationQuizResult = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/report/getEducationQuizResult`, "params=" + JSON.stringify(params));
};

export const getQuizResultByEducation = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/report/getQuizResultByEducation`, "params=" + JSON.stringify(params));
};
export const getQuizResultByEducationId = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/report/getQuizResultByEducationId`, "params=" + JSON.stringify(params));
};

export const getWeeklyEducationReport = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/report/getWeeklyEducationReport`, "params=" + JSON.stringify(params));
};

export const getElectionDetail = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}election/getElectionDetail`, "params=" + JSON.stringify(params));
};

export const sendSmsToPhone = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}election/sendSmsToPhone`, "params=" + JSON.stringify(params));
};

export const checkCode = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}election/checkCode`, "params=" + JSON.stringify(params));
};

export const sendVote = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}election/sendVote`, "params=" + JSON.stringify(params));
};

export const getElectionResult = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}election/getElectionResult`, "params=" + JSON.stringify(params));
}

export const isSessionExist = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}election/isSessionExist`,"params=" + JSON.stringify(params));
}

export const getResultSession = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}election/getResultSession`,"params=" + JSON.stringify(params));
}

export const saveCandidate = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}election/saveCandidate`,"params=" + JSON.stringify(params));
}

export const getElectionVoters = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}election/getElectionVoters`,"params=" + JSON.stringify(params));
}

export const getElections = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/election/getElections`,"params=" + JSON.stringify(params));
}

export const getElectionPanelDetail = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/election/getElectionDetail`,"params=" + JSON.stringify(params))
}

export const getElectionAutoComplete = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}admin/election/getElectionAutoComplete`,"params=" + JSON.stringify(params))
}

export const endElection = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}election/endElection`,"params=" + JSON.stringify(params))
}

export const startElection = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}election/startElection`,"params=" + JSON.stringify(params))
}

export const sendEmailToUser = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}file/sendEmailToUser`,"params=" + JSON.stringify(params))
}

export const checkEmailCode = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}file/checkCode`,"params=" + JSON.stringify(params))
}

export const getUserFiles = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}file/getUserFiles`,"params=" + JSON.stringify(params))
}

export const addFileView = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}file/addFileView`,"params=" + JSON.stringify(params))
}

export const userAnswerFeedBack = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}file/answerFeedBack`,"params=" + JSON.stringify(params))
}

export const logoutFileUser = () => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}file/logoutFileUser`);
}

export const getUserFeedBacks = (params) => {
    return axios.post(`${URLS.WEB_SERVICE_API_URL}file/getUserFeedBacks`,"params=" + JSON.stringify(params))
}