import React, {Component} from 'react';
import {ASSETS} from "../../constants/Path";
import {getElectionResultAction, getElectionVotersAction} from "../../actions/electionAction";
import {connect} from "react-redux";

class ElectionResultContainer extends Component{
    voters = [];
    printContent = React.createRef();
    filterType;
    constructor(props) {
        super(props);
            props.dispatch(getElectionVotersAction({election_id:this.props.link},(result)=>{
                if(result){
                    this.voters = result;
                    this.forceUpdate()
                }
            }))
    }
    closeModal = () => {
        this.props.changeActiveIndex(0,null,null,null)
    };
    printEducation = (elem) => {
        let myWindow = window.open('', 'PRINT', 'height=400,width=600');

        myWindow.document.write('<html><head><title>' + document.title + '</title>');
        myWindow.document.write('<style>h2,  div.vote-result-block  {display: block;} div.vote-result  {display: table-row;} div.voters-item  {display: table-row;}  div.profile-info-item  {display: table-row} div.info-item{display: table-cell} span.voter-dep{display: table-cell;width: 100px;} span.voter-name{ display: table-cell; width:250px;}  div.vote-result-tab-block{display: none} span.voter-phone{ width:100px;display: table-cell; }  div.img-item{display: none}   </style></head><body >');
        myWindow.document.write('<h1>' + document.title + '</h1>');
        myWindow.document.write(this.printContent.current.innerHTML);
        myWindow.document.write('</body></html>');

        myWindow.document.close(); // necessary for IE >= 10
        myWindow.focus(); // necessary for IE >= 10*/

        myWindow.print();
        myWindow.close();
        return true;
    };
    render() {
        let {detail} = this.props;
        return(
            <div>
                <div className="appointment-root result-vote">
                    <div className="appointment-wrapper">
                        <div className="appointment-container">
                            <div className="banner-img-block ">
                                <div className="info-item">
                                    <div className="btn-item" onClick={this.printEducation}><span className="btn-green">Yazdır</span></div>
                                    {
                                        (detail && detail.detail.is_ended == 1) ?
                                            <div className="btn-item"><span className="btn-green">Oylama Sona Erdi</span></div>
                                            :
                                            <div className="btn-item"><span className="btn-green">Oylama Devam Ediyor</span></div>
                                    }
                                </div>
                                <div className="img-item"><img
                                    src="/assets/img/education/calisan-temsilcisi-secimi-2021.png"
                                    alt=""/>  </div>
                                <div className="text-item">
                                    <p>Çalışan Temsilcisi Seçimi 2020</p>
                                </div>

                            </div>

                            {
                                this.voters &&
                                <div className="vote-result-block" ref={this.printContent}>
                                    <div className="vote-result-tab-block">
                                        <ul>
                                            <li onClick={()=>{
                                                this.filterType = null;
                                                this.forceUpdate()
                                            }} className={!this.filterType?"active":null}>Tümü</li>
                                            <li onClick={()=>{
                                                this.filterType = 1;
                                                this.forceUpdate()
                                            }} className={this.filterType==1?"active":null}>Oy Kullananlar</li>
                                            <li onClick={()=>{
                                                this.filterType = "0";
                                                this.forceUpdate()
                                            }} className={this.filterType==0?"active":null}>Oy Kullanmayanlar</li>
                                        </ul>
                                    </div>
                                    {
                                        this.voters.filter(item=> !this.filterType || item.voted == this.filterType ).map((voterItem,key)=>
                                            <div className="vote-result" key={key}>
                                                <div className="voters-item">
                                                    <span className="voter-name" >{voterItem.participant_name_surname}</span>
                                                    <span className="voter-dep">{voterItem.department_name}</span>
                                                    <span className="voter-phone">{voterItem.participant_phone}</span>
                                                </div>


                                            </div>
                                        )
                                    }
                                </div>
                            }

                                 <div className="sticky-item">
                                     <div className="btn-sticky">
                                         <div className="btn-item" onClick={this.closeModal}>
                                             <span className="btn-back">GERİ DÖN</span>
                                         </div>
                                     </div>
                                 </div>
                                 </div>



                    </div>
                </div>
            </div>
        )
    }
};

export default connect()(ElectionResultContainer);