import React, {Component} from 'react';
import {addFileViewAction} from "../actions/fileAction";
import {FILE_LOGIN, FILE_SHARE} from "../constants/routePaths";
import {connect} from "react-redux";
import SharedFileHeaderComponent from "../components/fileShare/sharedFileHeaderComponent";
import {ASSETS} from "../constants/Path";

class FileShareDetailPage extends Component {
    fileDetail;

    constructor(props) {
        super(props);
        props.dispatch(addFileViewAction({file_id: props.match.params.id}, (res) => {
            if (res) {
                this.fileDetail = res[0];
                this.forceUpdate();
            } else {
                this.props.history.push(`${FILE_LOGIN}?redirectUrl=${this.props.location.pathname}`)
            }
        }))
    }

    render() {
        return (
            this.fileDetail ?
                <div className="file-share-frame">
                    <SharedFileHeaderComponent match={this.props.match} {...this.fileDetail} history={this.props.history}/>
                    {
                        (this.fileDetail.pdf_path && this.fileDetail.pdf_path.indexOf("pdf")>-1) &&
                        <iframe src={ASSETS + this.fileDetail.pdf_path} frameBorder="0"></iframe>

                    }

                    {
                        (this.fileDetail.pdf_path && this.fileDetail.pdf_path.indexOf("pdf")===-1) &&
                        <div className={"file-detail-other"}>
                            <div className="img-item">
                                <img src={`${ASSETS}/img/file-share.png`} alt={this.fileDetail.uploadedFiles.name}/>
                            </div>
                            <div className="download-block">
                                <h3>{this.fileDetail.uploadedFiles.name}</h3>
                                <div className="bottom-item">
                                    <div>
                                        <span>Updated Date</span>
                                        <p>{this.fileDetail.uploadedFiles.updated_date}</p>
                                    </div>
                                    
                                    <a href={ASSETS+this.fileDetail.pdf_path} download className={"btn-green "}>Download</a>
                                </div>

                            </div>
                        </div>
                    }

                </div>
                : null
        );
    }
}

export default connect()(FileShareDetailPage);