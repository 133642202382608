import React, {Component} from "react";
import PanelBreadCrumbComponent from "../../components/Panel/panelBreadCrumbComponent";
import Helper from "../../helper";
import InfiniteScroll from "react-infinite-scroller";
import PanelVersionDocItemComponent from "../../components/Panel/panelVersionDocItemComponent";
import PanelDocItemComponent from "../../components/Panel/panelDocItemComponent";
import {connect} from "react-redux";
import {getElectionsAction} from "../../actions/electionAction";
import {
    EDITEDUCATIONDETAIL_WITHOUTPARAM,
    EDITEDUCATIONVERSIONDETAIL_WITHOUT_PARAM,
    PANEL_ELECTION
} from "../../constants/routePaths";
import moment from "moment";
import {Link} from "react-router-dom";

class PanelElectionListContainer extends Component {
    activePage = 0;
    loadFunc = () => {
        let params = {
            orderName: this.order,
            orderType: this.order_type,
            index: this.activePage
        };
        this.props.dispatch(getElectionsAction(params, (res, isMore) => {
            if (res === true) {
                this.isMore = isMore;
                this.activePage++;
                this.forceUpdate();
            }
        }));
    };
    sortFunc = (sort_name) => {
        this.order_type = this.order_type === "asc" ? "desc" : "asc";
        this.order = sort_name;
        this.activePage = 0;
        this.loadFunc();
    };

    render() {
        let {items, roleId} = this.props;
        return (
            <div className="panel-content-block">
                <PanelBreadCrumbComponent page="Seçimler"/>
                <div className="table-root">
                    <div className="table-container">
                        <div className="table-head">
                            <div className="t-1 td-item" onClick={() => {
                                this.sortFunc("name")
                            }}>
                                <p>Seçim adı</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-2 td-item" onClick={() => {
                                this.sortFunc("is_ended")
                            }}>
                                <p>Durum</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-3 td-item resp-date" onClick={() => {
                                this.sortFunc("started_date")
                            }}>
                                <p>Başlangıç Tarihi</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                            <div className="t-3 td-item resp-date" onClick={() => {
                                this.sortFunc("total_vote_count")
                            }}>
                                <p>Toplam Oy Sayısı</p>
                                <i className="icon-arr-bottom"></i>
                            </div>
                        </div>

                        <div className="table-block">
                            {
                                items &&
                                <InfiniteScroll
                                    pageStart={0}
                                    loadMore={this.loadFunc}
                                    hasMore={this.isMore && items.length > 19}
                                    threshold={250}
                                >
                                    {
                                        items.map((item, key) =>
                                            <Link key={key} to={`${PANEL_ELECTION}/${item.id}`}
                                                  className="table-item">
                                                <div className="td-item t-1">
                                                    <i className="icon-doc"></i>
                                                    <p>{item.name}</p>
                                                </div>
                                                <div className="td-item t-2">
                                                    <p>{item.is_ended===1?"Tamamlandı":"Oylama"}</p>
                                                </div>
                                                <div className="td-item t-3 resp-date">
                                                    <p>{moment(item.started_date).format('LL')}</p>
                                                </div>
                                                <div className="td-item t-3 resp-date">
                                                    <p>{item.total_vote_count}</p>
                                                </div>
                                            </Link>
                                        )
                                    }
                                </InfiniteScroll>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    items: state.PanelReducer.elections,
    roleId: state.sessionReducer.role_id
});

export default connect(mapStateToProps)(PanelElectionListContainer);