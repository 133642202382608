import React, {Component} from 'react';
//import moment from "moment";
import moment from "moment-timezone";
import {getFileUsersAutoCompleteAction, updatePermitUsersAction} from "../../actions/fileAction";
import {connect} from "react-redux";
import SuccessModalComponent from "../common/successModalComponent";
import {FILE_SHARE, PANEL_FILES} from "../../constants/routePaths";
import NewFileUserComponent from "../modals/newFileUserComponent";
import NewFileGroupComponent from "../modals/newFileGroupComponent";
import ChangeWarningModalComponent from "../modals/changeWarningModalComponent";

class PanelFileDetailShareComponent extends Component {
    filePermissions = []
    personInput = React.createRef();
    successMessage = false
    isNewFileUser = false;
    isNewFileGroup = false;
    isCloseEdit=false
    active=false
    filePer=this.props.filePermissions
    firstPermitedUsers = [];
    constructor(props) {
        super(props);
        this.filePermissions = [...this.props.filePermissions];
        this.firstPermitedUsers = [...this.props.filePermissions];
    }

    searchParticipants = (e) => {
        this.selectedElement = null;
        if(e.target.value !== ""){
            this.props.dispatch(getFileUsersAutoCompleteAction({searchTerm:e.target.value},(result)=>{
                this.autoCompleteArr = result;
                this.forceUpdate();
            }))
        }else{
            this.autoCompleteArr=null;
            this.forceUpdate();
        }
    }



    selectedParticipantFunc = (selectedParticipant) => {
        if(selectedParticipant.participants){
            selectedParticipant.participants.filter(item=> !this.filePermissions.find(participantItem=>participantItem.id===item.id) ).map((participantItem)=>{
                this.filePermissions.push(participantItem)
            })
        }else{
            this.filePermissions.push(selectedParticipant)
        }
        this.personInput.current.value="";
        this.forceUpdate();
    }
    focusElement = () => {
        this.autoIsShowing = true;
        this.forceUpdate()
    }

    blurElement = () => {
        setTimeout(()=>{
            this.autoIsShowing = false;
            this.forceUpdate()
        },250)

    }

    toggleModal = () => {
        this.isNewFileUser = false;
        this.isNewFileGroup = false;
        this.forceUpdate()
    }

    openModal = (key) => {
        this[key] = true;
        this.forceUpdate();
    }

    updatePermittedUsers = () => {
        this.props.dispatch(updatePermitUsersAction({permittedUsers:this.filePermissions,file_id:this.props.file_id},(res)=>{
            if(res){
                this.props.getData();
                this.successMessage= true;
                if(this.isCloseEdit === true){
                    this.props.history.push(PANEL_FILES);
                    return;
                }
                this.forceUpdate();
                this.timeOut =  setTimeout(()=>{
                    this.successMessage= false;
                    this.forceUpdate();
                },2000)
            }
        }))
    }


    toggleCloseEdit = (value) => {
        this.isCloseEdit = value;
        this.forceUpdate();
    };




    render() {
        let {filePermissions} = this.props;
        return (
            <div>
                <div className="election-person-container">
                    <div className="subtitle"><span>Kişi ve gruplarla paylaşın</span>
                        <div className="btn-block">
                            <div onClick={()=>{
                                this.openModal("isNewFileUser")
                            }} className="add-btn">YENİ KİŞİ YARAT</div>
                            <div onClick={()=>{
                                this.openModal("isNewFileGroup")
                            }} className="add-btn">YENİ GRUP YARAT</div>
                        </div>
                    </div>

                    <div className="add-person-block">
                        <div className="add-person-item">
                            <input ref={this.personInput} onFocus={this.focusElement} onBlur={this.blurElement} onChange={this.searchParticipants} type="text"
                                   placeholder="Ad Soyad, telefon numarası veya departman adı ile katılımcı ekleyebilirsiniz."/>
                        </div>
                        {
                            (this.autoIsShowing && this.autoCompleteArr) &&
                            <div className="autocomplete-root">
                                <ul>
                                    {
                                        this.autoCompleteArr.fileGroups && this.autoCompleteArr.fileGroups.map((item, key) =>
                                            <li onClick={() => {
                                                this.selectedParticipantFunc(item)
                                            }} key={key}>{item.group_name}</li>
                                        )
                                    }
                                    {
                                        this.autoCompleteArr.fileUsers && this.autoCompleteArr.fileUsers.filter(item=> !this.filePermissions.find(participantItem=>participantItem.id===item.id) ).map((item, key) =>
                                            <li onClick={() => {
                                                this.selectedParticipantFunc(item)
                                            }} key={key}>{item.name_surname} - {item.email}</li>
                                        )
                                    }
                                </ul>
                            </div>

                        }
                    </div>
                    <div className="subtitle sub-2"><span>Paylaştığınız kişi ve gruplar</span>
                    </div>
                    <div>

                    </div>

                    <div className="table-head">
                        <div className="t-1 td-item"><p>Ad Soyad</p><i className="icon-arr-bottom"></i></div>
                        <div className="t-2 td-item"><p>Dahil olduğu grup</p><i className="icon-arr-bottom"></i></div>
                        <div className="t-2 td-item"><p>Son görüntüleme</p><i className="icon-arr-bottom"></i></div>
                        <div className="t-3 td-item "><p>Toplam görüntüleme</p><i className="icon-arr-bottom"></i></div>
                        <div className="t-4 td-item"><p></p></div>

                    </div>
                    {
                        this.filePermissions && this.filePermissions.map((permissionItem, key) =>
                            <div className="table-item" key={key}>
                                <div className="td-item t-1"><i className="icon-user2"></i>
                                    <p>{permissionItem.name_surname}</p></div>
                                <div className="td-item t-2"><p>{permissionItem.group_name}</p></div>
                                <div className="td-item t-2">
                                    <p>{permissionItem.userViews && permissionItem.userViews[0] ? moment(permissionItem.userViews.created_date).format("DD.MM.YYYY") : "-"}</p>
                                </div>
                                <div className="td-item t-3 ">
                                    {
                                        (permissionItem.userViews && permissionItem.userViews.length > 0) &&
                                        <div className="td-info-item">
                                            <p>Son Görüntülemeler</p>
                                            {
                                                permissionItem.userViews.map((item, key) =>
                                                    <span
                                                        key={key}>{moment(item.created_date).tz("Europe/Istanbul").format("DD.MM.YYYY hh:mm")}</span>
                                                )
                                            }
                                        </div>
                                    }

                                    <p>{permissionItem.userViews ? permissionItem.userViews.length:0}</p>
                                </div>
                                <div className="t-4 td-item">
                                    <div className="close-item" onClick={()=>{
                                        this.filePermissions.splice(key,1);
                                        this.forceUpdate();
                                    }}>
                                        <i className="icon-close"></i>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    {
                        this.successMessage === true && <SuccessModalComponent
                            message={"Kayıt Edildi."}/>
                    }
                </div>
                <div className="sticky-item ">
                    <div className="btn-sticky safe-area">
                        <div className="left-item">
                            <div className="btn-item"><span className="btn-back" onClick={() => {
                                if(JSON.stringify(this.filePermissions)!==JSON.stringify(this.firstPermitedUsers)){
                                    this.isCloseEdit = true;
                                    this.forceUpdate();
                                    return;
                                }
                                this.props.history.push(PANEL_FILES);
                            }}>TÜM DOSYALARA DÖN</span>

                            </div>
                        </div>
                        <div className="btn-item">
                            <button onClick={this.updatePermittedUsers} type="button" className={`${(JSON.stringify(this.filePermissions)!==JSON.stringify(this.firstPermitedUsers)) ?"btn-green ":"disable-btn"}`}>KAYDET</button>
                        </div>
                    </div>
                </div>

                {
                    this.isNewFileUser === true &&
                    <NewFileUserComponent isNewFileUser={this.isNewFileUser} toggleModal={this.toggleModal} {...this.props}/>
                }
                {
                    this.isNewFileGroup === true &&
                    <NewFileGroupComponent isNewFileGroup={this.isNewFileGroup} toggleModal={this.toggleModal} {...this.props}/>
                }
                {
                    this.isCloseEdit &&
                    <ChangeWarningModalComponent 
                    saveAndExit={this.updatePermittedUsers} 
                    forceChangeActiveTab={()=>{
                        this.props.history.push(PANEL_FILES);
                    }} 
                    toggleCloseEdit={this.toggleCloseEdit}/>
                }

            </div>
        );
    }
}

export default connect(null,null,null,{withRef:true})(PanelFileDetailShareComponent);