import React, {Component} from "react";
import Helper from "../../helper";
import inputFieldComponent from "../../components/common/formFields/inputFieldComponent";
import {Field} from "redux-form";

class ElectionLiveResultContainer extends Component{
    nameInput = React.createRef();
    departmentInput = React.createRef();
    addLiveResult = () => {
        if(this.nameInput.current.value.trim().length!==0 && this.phoneNumber.length!==0){
            this.props.addLiveResult({
                name: this.nameInput.current.value.trim(),
                election_id:this.props.election_id,
                phone_number: this.phoneNumber
            })
            this.nameInput.current.value = "";
            this.phoneNumber = "";

        }
    }
    render() {
        let {electionResult, deleteLiveResult} = this.props
        return (
            <div className="election-person-container">
                <div className="head-item">
                    <h3>Yetkilendirme</h3>
                </div>
                <div className="subtitle">
                    <span>Canlı Sonuç Görebilecekler</span>
                </div>
                <div className="add-person-block">

                    <div className="add-person-item">
                        <input ref={this.nameInput} type="text" placeholder="İsim Soyisim"/>
                    </div>
                    <div className="add-person-item">
                        <input ref={this.departmentInput} onChange={(e)=>{
                            this.phoneNumber = e.target.value.replace(/[^\d]/g, '');
                            this.forceUpdate();
                        }} type="text" value={this.phoneNumber} placeholder="Telefon Numarası"/>

                    </div>
                    <div className="add-btn" onClick={this.addLiveResult}>
                        <i className="icon-plus-circle"></i>
                        <span>EKLE</span>
                    </div>
                </div>
                <div className="added-person-container">
                    <div className="added-person-block">
                        {
                            electionResult && electionResult.map((electionResultItem,key)=>
                                <div className="added-item" key={key}>
                                    <div className="left-item">
                                        <p>{electionResultItem.name}</p>
                                        <p>0 {electionResultItem.phone_number}</p>
                                    </div>
                                    <div className="close-item" onClick={()=>{
                                        this.props.deleteLiveResult(key)
                                    }}>
                                        <i className="icon-close"></i>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default ElectionLiveResultContainer;