import React, {Component, Fragment} from "react";
import {Field, initialize, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {createFileUserGroupAction} from "../../actions/fileAction";
import inputFieldComponent from "../common/formFields/inputFieldComponent";
import {getFileGroupsAction} from "../../actions/fileUserGroupAction";
import LoaderModalComponent from "../common/loaderModalComponent";
import SuccessModalComponent from "../common/successModalComponent";


const validate = (values) => {
    let hasErrors = false;
    let errors = {};
    if (!values.group_name || values.group_name.trim() === "") {
        hasErrors = true;
        errors.group_name = "Adı Doldurmanız Gerekmektedir.";
    }
    return hasErrors && errors;
};

class NewFileGroupComponent extends Component {
    successMessage = false;
    constructor(props) {
        super(props);
        if (props.editedFileGroup) {
            props.dispatch(initialize('createNewFileGroup', {
                group_name: props.editedFileGroup.group_name,
                id:props.editedFileGroup.id
            }));
        }
    }
    submitForm = (values) => {
        this.loaderIsShowing = true;
        this.errMsg = null;
        this.forceUpdate();
        this.props.dispatch(createFileUserGroupAction({
            ...values,
            file_group_id: this.selectedFileGroup ? this.selectedFileGroup.id : null
        }, (res, message) => {
            this.loaderIsShowing = false;
            if (res === true) {
                this.successMessage = true;
                this.forceUpdate();
                this.props.dispatch(getFileGroupsAction({index: 0, orderName: "id", orderType: "asc"}, (res) => {
                }))
                setTimeout(() => {
                    this.props.toggleModal(false, "isNewFileGroup");
                }, 3000);
            } else {
                this.errMsg = message;
                this.forceUpdate();
            }
        }))

    }

    render() {
        let {handleSubmit, toggleModal} = this.props;
        return (
            <Fragment>
                {
                    this.successMessage === false &&
                    <div className="lb-root">
                        <div className="lb-block">
                            <form onSubmit={handleSubmit(this.submitForm)} className="content-block add-part-block">
                                <div className="content-item">
                                    <div className="head-block">
                                        <h2>{this.props.editedFileGroup ? "Grup Düzenleme":"Yeni Grup Yarat"}</h2>
                                        <div className="info-item">
                                            <p>{this.props.editedFileGroup ? "Grubu düzenleyin.":"Yeni bir grup oluşturun."}</p>
                                        </div>
                                    </div>
                                    <i className="icon-close" onClick={() => {
                                        toggleModal(false, "isNewFileGroup");
                                    }}></i>
                                    <div className="form-block">

                                        <div className="input-container">
                                            <div className="input-block">
                                                <div className="input-container">
                                                    <div className="input-block">
                                                        <Field
                                                            name="group_name"
                                                            label="Grup Adı"
                                                            type="text"
                                                            component={inputFieldComponent}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="user-container">
                                        <h2>Grup Üyeleri</h2>
                                        <div className="user-block">
                                            <div className="user-content-block">
                                            <div className="user-item">
                                                <p> Ahmet Örnekadam</p>
                                                <i></i>
                                            </div>
                                            <div className="user-item">
                                                <p> Ahmet Örnekadam</p>
                                                <i></i>
                                            </div>
                                            <div className="user-item">
                                                <p> Ahmet Örnekadam</p>
                                                <i></i>
                                            </div>
                                            <div className="user-item">
                                                <p> Ahmet Örnekadam</p>
                                                <i></i>
                                            </div>
                                            <div className="user-item">
                                                <p> Ahmet Örnekadam</p>
                                                <i></i>
                                            </div>
                                            <div className="user-item">
                                                <p> Ahmet Örnekadam</p>
                                                <i></i>
                                            </div>
                                            </div>
                                            
                                        </div>

                                    </div>
                                    <div className="bottom-btn-item ">
                                        <p></p>
                                        <button type="submit" className="btn-green">{this.props.editedFileGroup ? "GRUP GÜNCELLEME" : "GRUP YARAT"}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                }
                {
                    this.loaderIsShowing === true && <LoaderModalComponent/>
                }
                {
                    this.successMessage === true && <SuccessModalComponent
                        message={this.props.editedFileGroup ? "Group Güncellendi." : "Group Eklendi."}/>
                }
            </Fragment>
        );
    }
}


NewFileGroupComponent = reduxForm({
    form: "createNewFileGroup",
    validate
})(NewFileGroupComponent);

export default connect()(NewFileGroupComponent);