import React, {Component} from "react";
import {Field, reduxForm} from "redux-form";
import inputFieldComponent from "../../components/common/formFields/inputFieldComponent";
import UploadFileFieldComponent from "../../components/common/formFields/uploadfileFieldComponent";
import LoaderModalComponent from "../../components/common/loaderModalComponent";
import {createUploadedFileAction, getFileUsersAutoCompleteAction} from "../../actions/fileAction";
import {PANEL_FILE_DETAIL_WITHOUT_DETAIL} from "../../constants/routePaths";

const validate = (values) => {
    let hasErrors = false;
    let errors = {};
    if (!values.name || values.name.trim() === "") {
        hasErrors = true;
        errors.name = "Lütfen isim bilgisi girin.";
    }
    if (!values.description || values.description.trim() === "") {
        hasErrors = true;
        errors.description = "Lütfen açıklama girin.";
    }
    if (!values.pdf_path || values.pdf_path.trim() === "") {
        hasErrors = true;
        errors.pdf_path = "Lütfen çıktı dosyası girin.";
    }
    return hasErrors && errors;
};

class PanelNewFileContainer extends Component {
    electionParticipant = [];
    personInput = React.createRef();
    isLoading = false;
    submit = (values) => {
        this.isLoading = true;
        this.forceUpdate();
        this.props.dispatch(createUploadedFileAction({...values, participants: this.electionParticipant}, (res) => {
            console.log(res)
            if (res) {
                this.props.history.push(`${PANEL_FILE_DETAIL_WITHOUT_DETAIL}${res}`)
                console.log(res);
                //TODO:detay sayfasina yonlenecek.
            } else {
                this.isLoading = false;
                this.forceUpdate();
            }
        }))
    }
    searchParticipants = (e) => {
        this.selectedElement = null;
        if (e.target.value !== "") {
            this.props.dispatch(getFileUsersAutoCompleteAction({searchTerm: e.target.value}, (result) => {
                this.autoCompleteArr = result;
                this.forceUpdate();
            }))
        } else {
            this.autoCompleteArr = null;
            this.forceUpdate();
        }
    }
    selectedParticipantFunc = (selectedParticipant) => {
        if (selectedParticipant.participants) {
            selectedParticipant.participants.filter(item => !this.electionParticipant.find(participantItem => participantItem.id === item.id)).map((participantItem) => {
                this.electionParticipant.push(participantItem)
            })
        } else {
            this.electionParticipant.push(selectedParticipant)
        }
        this.personInput.current.value = "";
        this.forceUpdate();
    }
    focusElement = () => {
        this.autoIsShowing = true;
        this.forceUpdate()
    }

    blurElement = () => {
        setTimeout(() => {
            this.autoIsShowing = false;
            this.forceUpdate()
        }, 250)

    }

    render() {
        let {handleSubmit, valid} = this.props;
        return (
            <form onSubmit={handleSubmit(this.submit)} className="panel-content-block panel-election-root new-file">
                <div className="table-root">
                    <div className="table-container">
                        <div className="table-block">
                            <h2>Yeni Dosya Ekle</h2>
                            <Field
                                name="name"
                                label="DOSYA ADI"
                                type="text"
                                component={inputFieldComponent}
                                withDesc={true}
                                example="Dashboardda ve paylaştığınız kişiler tarafından görünecek isim"
                            />

                            <Field
                                name="description"
                                label="AÇIKLAMA"
                                type="text"
                                component={inputFieldComponent}
                                withDesc={true}
                                example="Dosya ile ilgili kısa açıklama ekleyebilirsiniz"
                            />
                            <Field
                                name="pptx_path"
                                label="KAYNAK DOSYASI"
                                component={UploadFileFieldComponent}
                                withDesc={true}
                                //types={["ppt", "pptx"]}
                                //example="PowerPoint veya World dökümanını ekleyin"
                            />
                            <Field
                                name="pdf_path"
                                label="ÇIKTI DOSYASI*"
                                component={UploadFileFieldComponent}
                                withDesc={true}
                                previewData={true}
                                //types={["pdf","ppt", "pptx"]}
                                //example="PDF,PowerPoint veya World dökümanını ekleyin"
                            />

                            <div className="election-person-container">
                                <div className="subtitle">
                                    <span>Kişi ve gruplarla paylaşın</span>
                                    <div className="btn-block">
                                        <div onClick={() => {
                                            this.props.openModal("isNewFileUser")
                                        }} className="add-btn">YENİ KİŞİ YARAT
                                        </div>
                                        <div onClick={() => {
                                            this.props.openModal("isNewFileGroup")
                                        }} className="add-btn">YENİ GRUP YARAT
                                        </div>
                                    </div>
                                </div>
                                <div className="add-person-block">
                                    <div className="add-person-item">
                                        <input ref={this.personInput} onFocus={this.focusElement}
                                               onBlur={this.blurElement} onChange={this.searchParticipants}
                                               type="text"
                                               placeholder="Ad Soyad, telefon numarası veya departman adı ile katılımcı ekleyebilirsiniz."/>
                                    </div>
                                    {
                                        (this.autoIsShowing && this.autoCompleteArr) &&
                                        <div className="autocomplete-root">
                                            <ul>
                                                {
                                                    this.autoCompleteArr.fileGroups && this.autoCompleteArr.fileGroups.map((item, key) =>
                                                        <li onClick={() => {
                                                            this.selectedParticipantFunc(item)
                                                        }} key={key}>{item.group_name}</li>
                                                    )
                                                }
                                                {
                                                    this.autoCompleteArr.fileUsers && this.autoCompleteArr.fileUsers.filter(item => !this.electionParticipant.find(participantItem => participantItem.id === item.id)).map((item, key) =>
                                                        <li onClick={() => {
                                                            this.selectedParticipantFunc(item)
                                                        }} key={key}>{item.name_surname} - {item.email}</li>
                                                    )
                                                }
                                            </ul>
                                        </div>

                                    }
                                </div>
                                {
                                    this.electionParticipant.length> 0 &&
                                    <div className="added-person-container">
                                        <div className="added-person-block">
                                            {
                                                this.electionParticipant.map((participant, key) =>
                                                    <div className="added-item">
                                                        <div className="left-item">
                                                            <p>{participant.name_surname} - {participant.email}</p>
                                                        </div>
                                                        <div onClick={() => {
                                                            this.electionParticipant.splice(key, 1);
                                                            this.forceUpdate();
                                                        }} className="close-item">
                                                            <i className="icon-close"></i>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                    <div className="sticky-item safe-area">
                        <div className="btn-sticky">

                            <div className="left-item">
                                {
                                    (this.props.submitFailed && valid === false) &&
                                    <div className="info-item">Lütfen zorunlu alanları doldurun.</div>
                                }
                            </div>

                            <div className="btn-item"><span className="btn-back">KAPAT</span>
                                <button type="submit" className="btn-green ">OLUŞTUR VE PAYLAŞ</button>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.isLoading &&
                    <LoaderModalComponent/>
                }

            </form>
        )
    }
}

PanelNewFileContainer = reduxForm({
    form: 'newFile',
    validate
})(PanelNewFileContainer);

export default PanelNewFileContainer;