import React,{Component} from "react";
import moment from "moment";
import {Link} from "react-router-dom";
import {FILE_SHARE_DETAIL_WITHOUT_PARAM} from "../../constants/routePaths";
import {ASSETS} from "../../constants/Path";

class SharedFileComponent extends Component{
    render() {
        let {uploadedFiles,pdf_path,preview_src} = this.props;
        return(
            <div className="file-item-block">
                <div className="left-block">
                    <div className="img-item">
                        <img src={ASSETS+preview_src} alt={uploadedFiles.name}/>
                    </div>
                </div>
                <div className="right-block">
                    <div className="info-item">
                        <span>{uploadedFiles.name}</span>
                        <p>{uploadedFiles.description}</p>
                    </div>
                    <div className="bottom-block">
                        <div className="date-item">
                            <p>Last modification date</p>
                            <span>{moment(uploadedFiles.updated_date).format("DD.MM.YYYY")}</span>
                        </div>
                        <div className="btn-item">
                            <Link to={FILE_SHARE_DETAIL_WITHOUT_PARAM+uploadedFiles.id} className="btn btn-back">View</Link>
                            <a href={ASSETS+pdf_path} download className="btn btn-green ">Download</a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default SharedFileComponent;