import React from 'react';
import {Link} from "react-router-dom";
import {EDUCATIONDETAIL_WITHOUTPARAM} from "../../constants/routePaths";
import Helper from '../../helper';
import {ASSETS} from "../../constants/Path";

const FundamentalCourseItemComponent = ({educations,educationCategory,educations_name,educations_image,educations_id}) => (
    <Link to={`${EDUCATIONDETAIL_WITHOUTPARAM}${Helper.turkishToLower((educations ? educations.name:educations_name)).replace(/\s/g, '-').split("?").join("")}-${educations?educations.id:educations_id}`} className="box-item">
        <div className="img-item">
            <img src={ASSETS+(educations ? educations.image:educations_image)} alt=""/>
        </div>
        <div className="info-block">
            <span className={`label-tag green`}>#{educationCategory.name.toLocaleUpperCase('tr')}</span>
            <h3>{Helper.titleCase((educations ? educations.name:educations_name))}</h3>
            <div className="time-block">
                <span><i className="icon-clock"></i>{educations ? educations.duration:""}</span>
            </div>
            <div className="btn-item">
                <span className="link-btn wh">EĞİTİMİ İNCELE</span>
            </div>
        </div>
    </Link>
);

export default FundamentalCourseItemComponent;