import React, {Component} from 'react';
import * as ROUTES from "../../../constants/routePaths";
import {Link} from "react-router-dom";

class InputFieldComponent extends Component {
    render() {
        const {input, meta: {error, touched}, placeholder, type, label, linkIsShowing, example, withDesc} = this.props;
        return (
            <div className="input-item">
                {
                    withDesc ?
                        <label>
                            <div className="head-item">
                                <span>{label}</span>
                                <p>{example}</p>
                            </div>
                            <input {...input} type={type} className={`${error && touched === true ? "error" : ""}`}
                                   autoComplete="off"/>
                        </label>
                        :
                        <label>{label}
                            {
                                example && <span>{example}</span>
                            }
                            <input {...input} className={`${error && touched === true ? "error" : ""}`} type={type}
                                   placeholder={placeholder} autoComplete="off"/>
                        </label>
                }

                {
                    linkIsShowing === true && <Link to={ROUTES.FORGOTPASS} className="text-abs">ŞİFREMİ UNUTTUM?</Link>
                }
            </div>
        );
    }
}

InputFieldComponent.propTypes = {};
InputFieldComponent.defaultProps = {};

export default InputFieldComponent;
