import React, {Component} from 'react';
import {Field, initialize, reduxForm} from "redux-form";
import {connect} from "react-redux";
import inputFieldComponent from "../common/formFields/inputFieldComponent";
import {updateFileInfoAction} from "../../actions/fileAction";
import SuccessModalComponent from "../common/successModalComponent";
import {FILE_SHARE, PANEL_FILES} from "../../constants/routePaths";
import ChangeWarningModalComponent from "../modals/changeWarningModalComponent";
import mapDispatchToProps from "react-redux/lib/connect/mapDispatchToProps";



const validate = (values) => {
    let hasErrors = false;
    let errors = {};
    if (!values.name || values.name.trim() === "") {
        hasErrors = true;
        errors.name = "Lütfen tüm alanları doldurun.";
    }
    if (!values.description || values.description.trim() === "") {
        hasErrors = true;
        errors.description = "Lütfen tüm alanları doldurun.";
    }
    return hasErrors && errors;
};

class PanelFileDetailOptionsComponent extends Component {
    successMessage = false;
    isCloseEdit = false;




    componentDidMount() {
        this.props.dispatch(initialize("fileInfo", {
            name: this.props.uploadedFileInfo.name,
            description: this.props.uploadedFileInfo.description
        }))
    }

    submitForm = (values) => {
        this.props.dispatch(updateFileInfoAction({...values, file_id: this.props.file_id}, (res) => {
            if (res) {
                this.props.getData();
                this.successMessage = true;
                this.forceUpdate();
                this.timeOut = setTimeout(() => {
                    this.successMessage = false;
                    this.forceUpdate();
                }, 2000)
            }
        }))
    }

    toggleCloseEdit = (value) => {
        this.isCloseEdit = value;
        this.forceUpdate();
    };
    saveForm = () => {
        this.submitForm(this.props.formVal)
        if (this.isCloseEdit === true) {
            this.props.history.push(PANEL_FILES);
            return;
        }
    }


    render() {
        let {handleSubmit, formInfo,fileInfo} = this.props;
        return (

            <form onSubmit={handleSubmit(this.submitForm)}>
                <Field
                    name="name"
                    label="DOSYA ADI"
                    type="text"
                    component={inputFieldComponent}
                    withDesc={true}
                    example="Dosya paylaştığınız kişiler tarafından buradaki isim ile görünecek"
                />

                <Field
                    name="description"
                    label="AÇIKLAMA"
                    type="text"
                    component={inputFieldComponent}
                    withDesc={true}
                    example="Dosya ile ilgili kısa açıklama ekleyebilirsiniz"
                />
                {
                    this.successMessage === true && <SuccessModalComponent
                        message={"Kayıt Edildi."}/>
                }
                <div className="sticky-item ">
                    <div className="btn-sticky safe-area">
                        <div className="left-item">
                            <span className="btn-back" onClick={() => {
                                if (JSON.stringify(this.props.fileInfo.initial) !== JSON.stringify(this.props.fileInfo.values)) {
                                    this.isCloseEdit = true;
                                    this.forceUpdate();
                                    return;
                                }
                                this.props.history.push(PANEL_FILES);
                            }}>TÜM DOSYALARA DÖN</span>
                        </div>
                        {
                            this.props.fileInfo&&
                            <div className="btn-item">
                                <button type="submit" className={`${(JSON.stringify(this.props.fileInfo.initial) !== JSON.stringify(this.props.fileInfo.values))?"btn-green":"disable-btn"} `}  >KAYDET</button>
                            </div>
                        }
                    </div>
                </div>
                {
                    this.isCloseEdit &&
                    <ChangeWarningModalComponent
                        saveAndExit={this.saveForm}
                        forceChangeActiveTab={() => {
                            this.props.history.push(PANEL_FILES);
                        }}
                        toggleCloseEdit={this.toggleCloseEdit}/>
                }
            </form>

        );
    }
}

const mapStateToProps = (state) => ({
    formErrors: state.form.createNewParticipant ? state.form.createNewParticipant.syncErrors : {},
    fileInfo: state.form ? state.form.fileInfo : {},
    formVal: state.form.fileInfo ? state.form.fileInfo.values : {},

});

PanelFileDetailOptionsComponent = reduxForm({
    form: "fileInfo",
    validate
})(PanelFileDetailOptionsComponent);

export default connect(mapStateToProps, mapDispatchToProps, null, {withRef: true})(PanelFileDetailOptionsComponent);