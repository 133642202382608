import {
    answerFeedBack, createFileUser, createFileUserGroup,
    createNewVersionOfFile,
    createUploadedFile,
    getAllFileUsers,
    getFiles, getUploadedFile, setCurrentVersion, updateFileInfo, updatePermitUsers,
    uploadFile,
    uploadQuizQuestionImage
} from "../services/adminService";
import {RECEIVE_FILES} from "../constants/actionTypes";
import {
    addFileView,
    checkCode,
    checkEmailCode, getUserFeedBacks,
    getUserFiles, logoutFileUser,
    sendEmailToUser,
    userAnswerFeedBack
} from "../services/webService";

const receiveFiles = (payload, isFirst) => ({
    type: RECEIVE_FILES,
    payload,
    isFirst
})

export const getFilesAction = (params, cb) => dispatch => {
    getFiles(params).then((res) => {
        if (res.data.success) {
            dispatch(receiveFiles(res.data.success, params.index === 0))
            cb(res.data.state)
        } else {
            cb(res.data.state)
        }
    }).catch((err) => {
        console.log("err", err)
    })
}

export const uploadFileAction = (file,cb) => dispatch => {
    uploadFile(file,"file").then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false)
        }
    }).catch((err)=>{
        cb(false)
    })
};

export const getFileUsersAutoCompleteAction = (params,cb) => dispatch => {
    getAllFileUsers(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false)
        }
    }).catch((err)=>{
        cb(false)
    })
}

export const createUploadedFileAction = (params,cb) => dispatch => {
    createUploadedFile(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false)
        }
    }).catch((err)=>{
        cb(false)
    })
}

export const getUploadedFileAction = (params,cb) => dispatch => {
    getUploadedFile(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false)
        }
    }).catch((err)=>{
        cb(false)
    })
}

export const createNewVersionOfFileAction = (params,cb) => dispatch => {
    createNewVersionOfFile(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false)
        }
    }).catch((err)=>{
        cb(false)
    })
}

export const updateFileInfoAction = (params,cb) => dispatch => {
    updateFileInfo(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false)
        }
    }).catch((err)=>{
        cb(false)
    })
}

export const updatePermitUsersAction = (params,cb) => dispatch => {
    updatePermitUsers(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false)
        }
    }).catch((err)=>{
        cb(false)
    })
}

export const answerFeedBackAction = (params,cb) => dispatch => {
    answerFeedBack(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false)
        }
    }).catch((err)=>{
        cb(false)
    })
}

export const sendEmailToUserAction = (params,cb) => dispatch => {
    sendEmailToUser(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false)
        }
    }).catch((err)=>{
        cb(false)
    })
}


export const checkCodeAction = (params,cb) => dispatch => {
    checkEmailCode(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false)
        }
    }).catch((err)=>{
        cb(false)
    })
}

export const getUserFilesAction = (params,cb) =>dispatch => {
    getUserFiles(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false)
        }
    }).catch((err)=>{
        cb(false)
    })
}

export const addFileViewAction = (params,cb) =>dispatch => {
    addFileView(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false)
        }
    }).catch((err)=>{
        cb(false)
    })
}

export const userAnswerFeedBackAction = (params,cb) =>dispatch => {
    userAnswerFeedBack(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false)
        }
    }).catch((err)=>{
        cb(false)
    })
}

export const setCurrentVersionAction = (params,cb) =>dispatch => {
    setCurrentVersion(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false)
        }
    }).catch((err)=>{
        cb(false)
    })
}

export const createFileUserAction = (params,cb) =>dispatch => {
    createFileUser(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success,res.data.state)
        }else{
            cb(false,res.data.state)
        }
    }).catch((err)=>{
        cb(false)
    })
}

export const createFileUserGroupAction = (params,cb) =>dispatch => {
    createFileUserGroup(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false)
        }
    }).catch((err)=>{
        cb(false)
    })
}

export const logoutFileUserAction = (cb) =>dispatch => {
    logoutFileUser().then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false)
        }
    }).catch((err)=>{
        cb(false)
    })
}

export const getUserFeedBacksAction = (params,cb) => dispatch => {
    getUserFeedBacks(params).then((res)=>{
        if(res.data.success){
            cb(res.data.success)
        }else{
            cb(false)
        }
    }).catch((err)=>{
        cb(false)
    })
}