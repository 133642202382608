import {
    CHANGE_MENU_STATUS,
    GET_EDUCATION_CATEGORIES,
    RECEIVE_DOCUMENTS,
    RECEIVE_EDUCATION_CATEGORIES,
    RECEIVE_EDUCATION_SESSION_DETAIL,
    RECEIVE_PANEL_DRAFT,
    RECEIVE_PANEL_EDUCATIONS,
    RECEIVE_PANEL_FEEDBACK,
    RECEIVE_PANEL_LOCATION,
    RECEIVE_PANEL_NOTIFICATIONS,
    RECEIVE_PANEL_QUIZ,
    RECEIVE_PANEL_SELECT,
    RECEIVE_PANEL_SMART_DOCUMENTS,
    RECEIVE_PANEL_TAG,
    RECEIVE_PARTICIPANTS,
    RECEIVE_SURVEY_DETAIL,
    RECEIVE_SURVEYS,
    RECEIVE_USERS, UPDATE_PANEL_TAG
} from '../constants/actionTypes';
import {
    addDocumentPicture,
    createNewEducation, createNewLocation, createSmartDocument, getCategories, getDocuments, getPanelEducations,
    getPanelFeedBack,
    getPanelLocations,
    getPanelQuiz, getSmartDocuments, getSmartDocumentsAutoComplete, getUserDetail,
    getUsers, saveEducationPicture, updateUser
} from "../services/webService";
import {
    changeBannerItem,
    checkQuizExist, createPlanedEducation, educationAutoComplete, endSurvey, getDraftDetail, getDrafts,
    getFeedbackDetail, getParticipantDetail, getParticipants, getSessionEducationDetail,
    getSurveyResults,
    getSurveys, publishDraft, responseFeedback, updateDraftContent, updateParticipant, updateSurvey,
    usersAutoComplete
} from "../services/adminService";

export const receiveDocuments = (payload, isFirst) => ({
    type: RECEIVE_DOCUMENTS,
    payload,
    isFirst
});

export const receiveUsers = (payload, isFirst) => ({
    type: RECEIVE_USERS,
    payload,
    isFirst
});

export const receivePanelEducations = (payload, isFirst) => ({
    type: RECEIVE_PANEL_EDUCATIONS,
    payload,
    isFirst
});

export const receivePanelFeedback = (payload, isFirst) => ({
    type: RECEIVE_PANEL_FEEDBACK,
    payload,
    isFirst
});

export const receivePanelQuiz = (payload, isFirst) => ({
    type: RECEIVE_PANEL_QUIZ,
    payload,
    isFirst
});
export const receivePanelSelect = (payload, isFirst) => ({
    type: RECEIVE_PANEL_SELECT,
    payload,
    isFirst
});
export const receivePanelTag = (payload, isFirst) => ({
    type: RECEIVE_PANEL_TAG,
    payload,
    isFirst
});

export const receivePanelDraft = (payload, isFirst) => ({
    type: RECEIVE_PANEL_DRAFT,
    payload,
    isFirst
});

export const receivePanelLocation = (payload, isFirst) => ({
    type: RECEIVE_PANEL_LOCATION,
    payload,
    isFirst
});

export const receiveSmartDocuments = (payload, isFirst) => ({
    type: RECEIVE_PANEL_SMART_DOCUMENTS,
    payload,
    isFirst
});

export const receivePanelNotifications = (payload) => ({
    type: RECEIVE_PANEL_NOTIFICATIONS,
    payload
});

export const receiveSurveys = (payload, isFirst) => ({
    type: RECEIVE_SURVEYS,
    payload,
    isFirst
});

export const getPanelUserAction = (params, cb) => dispatch => {
    getUsers(params).then((res) => {
        if (res.data.success) {
            dispatch(receiveUsers(res.data.success, params.activePage === 0));
            cb(true, res.data.state);
        } else {
            cb(false, true);
        }
    }).catch((err) => {
        cb(false, true);
    })
};

export const getPanelLocationAction = (params, cb) => dispatch => {
    getPanelLocations(params).then((res) => {
        if (res.data.success) {
            dispatch(receivePanelLocation(res.data.success, params.activePage === 0));
            cb(true, res.data.state);
        } else {
            cb(false, true);
        }
    }).catch((err) => {
        cb(false, true);
    })
};

export const getStartedEducationList = (params, cb) => dispatch => {
    getPanelEducations(params).then((res) => {
        if (res.data.success) {
            dispatch(receivePanelEducations(res.data.success, params.activePage === 0));
            cb(true, res.data.state);
        } else {
            cb(false, true);
        }
    }).catch((err) => {
        cb(false, true);
    })
};

export const getFeedbackEducationAction = (params, cb) => dispatch => {
    getPanelFeedBack(params).then((res) => {
        if (res.data.success) {
            dispatch(receivePanelFeedback(res.data.success, params.activePage === 0));
            cb(true, res.data.state);
        } else {
            cb(false, true);
        }
    }).catch((err) => {
        cb(false, true);
    })
};

export const getQuizEducationAction = (params, cb) => dispatch => {
    getPanelQuiz(params).then((res) => {
        if (res.data.success) {
            dispatch(receivePanelQuiz(res.data.success, params.activePage === 0));
            cb(true, res.data.state);
        } else {
            cb(false, true);
        }
    }).catch((err) => {
        cb(false, true);
    })
};

export const getPanelSelectAction = (params, cb) => dispatch => {
    getPanelQuiz(params).then((res) => {
        if (res.data.success) {
            dispatch(receivePanelSelect(res.data.success, params.activePage === 0));
            cb(true, res.data.state);
        } else {
            cb(false, true);
        }
    }).catch((err) => {
        cb(false, true);
    })
};

export const createNewEducationAction = (params, cb) => dispatch => {
    createNewEducation(params).then((res) => {
        if (res.data.success) {
            cb(true, res.data.success);
        } else {
            cb(false);
        }
    }).catch((err) => {

    })
};

export const saveEducationPictureAction = (image, type, cb) => dispatch => {
    saveEducationPicture(image, type).then((res) => {
        if (res.data.success) {
            cb(res.data.success);
        } else {
            cb(false);
        }
    }).catch((err) => {
        cb(false);
    })
};

export const getPanelDocumentsAction = (params, cb) => dispatch => {
    getDocuments(params).then((res) => {
        if (res.data.success) {
            dispatch(receiveDocuments(res.data.success, params.index === 0));
            cb(true, res.data.state)
        }
    }).catch((err) => {

    })
};

export const addDocumentPictureAction = (files, type, cb) => dispatch => {
    addDocumentPicture(files, type).then((res) => {
        if (res.data.success) {
            cb(res.data.success)
        }else{
            cb(false);
        }
    }).catch(()=>{
        cb(false);
    })
};

export const updateUserAction = (params, cb) => dispatch => {
    updateUser(params).then((res) => {
        if (res.data.success) {
            cb(true, res.data.success);
        } else {
            cb(false, res.data.state)
        }
    }).catch((err) => {
        cb(false)
    })
};

export const getUserDetailAction = (params, cb) => dispatch => {
    getUserDetail(params).then((res) => {
        if (res.data.success) {
            cb(res.data.success)
        } else {
            cb(false)
        }
    }).catch((err) => {
        cb(false)
    })
};

export const getSmartDocumentsAutoCompleteAction = (params, cb) => dispatch => {
    getSmartDocumentsAutoComplete(params).then((res) => {
        if (res.data.success) {
            cb(res.data.success)
        } else {
            cb(false)
        }
    }).catch((err) => {
        cb(false)
    })
};

export const createSmartDocumentAction = (params, cb) => dispatch => {
    createSmartDocument(params).then((res) => {
        if (res.data.success) {
            cb(true, res.data.success)
        } else {
            cb(false, res.data.state)
        }
    }).catch((err) => {
        cb(false)
    })
};
export const openMenu = (payload) =>({
    type: CHANGE_MENU_STATUS,
    payload:payload
});

export const getSmartDocumentsAction = (params, cb) => dispatch => {
    getSmartDocuments(params).then((res) => {
        if (res.data.success) {
            dispatch(receiveSmartDocuments(res.data.success, params.activePage === 0));
            cb(true, res.data.state);
        }else{
            cb(false, true);
        }
    }).catch((err) => {

    })
};

export const createNewLocationAction = (params, cb) => dispatch => {
    createNewLocation(params).then((res) => {
        if (res.data.success) {
            cb(true, res.data.success);
        } else {
            cb(false);
        }
    }).catch((err) => {
        cb(false);
    })
};

export const educationAutoCompleteAction = (params, cb) => dispatch => {
    educationAutoComplete(params).then((res) => {
        if (res.data.success) {
            cb(res.data.success);
        } else {
            cb(false);
        }
    }).catch((err) => {
        cb(false);
    })
};

export const checkQuizExistAction = (params, cb) => dispatch => {
    checkQuizExist(params).then((res) => {
        cb(res.data.success);
    }).catch((err) => {
        cb(false);
    })
};

export const createPlanedEducationAction = (params, cb) => dispatch => {
    createPlanedEducation(params).then((res) => {
        cb(res.data.success);
    }).catch((err) => {
        cb(false);
    })
};

export const usersAutoCompleteAction = (params, cb) => dispatch => {
    usersAutoComplete(params).then((res) => {
        if (res.data.success) {
            cb(res.data.success)
        } else {
            cb([]);
        }
    }).catch((err) => {
        cb([]);
    })
};

export const getSurveysAction = (params, cb) => dispatch => {
    getSurveys(params).then((res) => {
        if (res.data.success) {
            dispatch(receiveSurveys(res.data.success, params.activePage === 0));
            cb(true, res.data.state)
        }
    }).catch((err) => {

    })
};

export const updateSurveyAction = (params, cb) => dispatch => {
    updateSurvey(params).then((res) => {
        if (res.data.success) {
            cb(true, res.data.success)
        } else {
            cb(false, res.data.state)
        }
    }).catch((err) => {
        cb(false, err)
    })
};

const receiveSurveyDetail = (id, payload) => ({
    type: RECEIVE_SURVEY_DETAIL,
    payload,
    id
});

export const getSurveyResultsAction = (params, cb) => dispatch => {
    getSurveyResults(params).then((res) => {
        if (res.data.success) {
            dispatch(receiveSurveyDetail(params.survey_id, res.data.success))
        }
    })
};

export const endSurveyAction = (params, cb) => dispatch => {
    endSurvey(params).then((res) => {
        if (res.data.success) {
            cb(true);
        } else {
            cb(false, res.data.state)
        }
    }).catch((err) => {
        cb(false, err);
    })
};

export const getFeedbackDetailAction = (params, cb) => dispatch => {
    getFeedbackDetail(params).then((res) => {
        if (res.data.success) {
            cb(res.data.success);
        } else {
            cb(null);
        }
    }).catch((err) => {
        cb(null);
    })
};

export const responseFeedbackAction = (params, cb) => dispatch => {
    responseFeedback(params).then((res) => {
        if (res.data.success) {
            cb(res.data.success);
        } else {
            cb(null);
        }
    }).catch((err) => {
        cb(null);
    })
};

export const getDraftDetailAction = (params, cb) => dispatch => {
    getDraftDetail(params).then((res) => {
        if (res.data.success) {

        }
    })
};

export const publishDraftAction = (params, cb) => dispatch => {
    publishDraft(params).then((res) => {
        if (res.data.success) {
            cb(true);
        } else {
            cb(false);
        }
    }).catch((err) => {
        cb(false);
    })
};

export const updateDraftContentAction = (params, cb) => dispatch => {
    updateDraftContent(params).then((res) => {
        if (res.data.success) {
            cb(true);
        } else {
            cb(false);
        }
    }).catch((err) => {
        cb(false);
    })
};

export const getDraftsAction = (params, cb) => dispatch => {
    getDrafts(params).then((res) => {
        if (res.data.success) {
            dispatch(receivePanelDraft(res.data.success, params.index === 0));
            cb(true, res.data.state);
        } else {
            dispatch(receivePanelDraft([]));
            cb(false, true);
        }
    }).catch((err) => {
        // dispatch(receivePanelDraft([]))
    })
};

const receiveSessionEducationDetail = (education_session_id, payload) => ({
    type: RECEIVE_EDUCATION_SESSION_DETAIL,
    payload,
    education_session_id
});

export const getSessionEducationDetailAction = (params, cb) => dispatch => {
    getSessionEducationDetail(params).then((res) => {
        if (res.data.success) {
            dispatch(receiveSessionEducationDetail(params.education_session_id, res.data.success))
        }
    })
};

export const changeBannerItemAction = (params,cb) => dispatch => {
    changeBannerItem(params).then((res)=>{
       if(res.data.success){
           cb(true)
       }else{
           cb(false)
       }
    }).catch((err)=>{
        cb(false)
    })
};

export const receiveParticipants = (payload, isFirst) => ({
    type: RECEIVE_PARTICIPANTS,
    payload,
    isFirst
});

export const getParticipantsAction = (params,cb) => dispatch => {
    getParticipants(params).then((res)=>{
        if (res.data.success) {
            dispatch(receiveParticipants(res.data.success, params.activePage === 0))
            cb(true,res.data.state);
        } else {
            dispatch(receiveParticipants([]));
            cb(true,false);
        }
    }).catch((err) => {
        cb(true,false);
    })
};


export const updateParticipantAction = (params,cb) => dispatch => {
    updateParticipant(params).then((res)=>{
        if (res.data.success) {
            cb(true,res.data.success);
        } else {
            cb(false,res.data.state);
        }
    }).catch((err) => {
        cb(false);
    })
};

export const getParticipantDetailAction = (params,cb) => dispatch => {
    getParticipantDetail(params).then((res)=>{
        if (res.data.success) {
            cb(res.data.success);
        } else {
            cb(false);
        }
    }).catch((err) => {
        cb(false);
    })
};

export const updateTag = (data) => ({
    type: UPDATE_PANEL_TAG,
    payload:data
});