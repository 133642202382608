import {getFileUsers} from "../services/adminService";
import {RECEIVE_FILE_USERS} from "../constants/actionTypes";


const receiveFileUsers = (payload, isFirst) => ({
    type: RECEIVE_FILE_USERS,
    payload,
    isFirst
});
export const getFileUsersAction = (params,cb) => dispatch => {
    getFileUsers(params).then((res) => {
        if (res.data.success) {
            dispatch(receiveFileUsers(res.data.success,params.index===0))
            cb(res.data.state)
        }
        else{
            cb(res.data.state)
        }

    })
        .catch((err) => {
            console.log("err", err)
        })

}