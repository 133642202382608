import React, {Component} from 'react';
import {connect} from "react-redux";
import {answerFeedBackAction, getUserFeedBacksAction, userAnswerFeedBackAction} from "../../actions/fileAction";

class PanelFileFeedbackContainer extends Component {
    feedBackInput = React.createRef();
    feedBacks = [];

    constructor(props) {
        super(props);

        this.feedBacks = props.feedBacks;
        props.dispatch(getUserFeedBacksAction({file_user_id:this.props.file_user_id},(res)=>{
            if(res){
                this.feedBacks = res;
                this.forceUpdate();
            }
        }))
    }

    sendFeedBack = () => {
        if (this.feedBackInput.current && this.feedBackInput.current.value.trim() !== "") {
            let actionFunc = this.props.isUser ? userAnswerFeedBackAction : answerFeedBackAction;
            this.props.dispatch(actionFunc({
                message: this.feedBackInput.current.value,
                file_user_id: this.props.file_user_id
            }, (res) => {
                if (res) {
                    this.props.getData && this.props.getData();
                    this.feedBacks = res;
                    this.feedBackInput.current.value = "";
                    this.forceUpdate();
                }
            }))
        }
    }


    render() {
        let {feedBacks, fileName, fileUsers, toggleFeedBackModal, getData, isUser} = this.props;
        return (
            <div>
                <div className="lb-root file-share">
                    <div className="lb-block">
                        <div className="content-block">

                            <div className="head-block">
                                <div>
                                    <h2>Give Feedback</h2>
                                    <span className="">{fileName}</span>
                                </div>
                                <i onClick={() => {
                                    toggleFeedBackModal(null)
                                }} className="icon-close"></i>

                            </div>
                            <div className="content-item">
                                <div className="chat-root">
                                    <div className="chat-container">
                                        {
                                            this.feedBacks.map((item, key) =>
                                                <div key={key}
                                                     className={"chat-block " + ((isUser ? (item.editor_id === null) : (item.editor_id !== null)) ? "moderator" : "")}>
                                                    <div className="chat-item">
                                                        <span className="chatbg"></span>
                                                        <div className="name">
                                                            <p>{!item.editor_id ? fileUsers.name_surname : "Editor"}</p>
                                                        </div>
                                                        <div className="message-item">
                                                            <p>{item.message}</p>
                                                        </div>
                                                        <div className="date-item"><p>{item.created_date}</p></div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className="chat-text-block">
                                        <textarea ref={this.feedBackInput} name="" id="" cols="30" rows="10"></textarea>
                                        <div onClick={this.sendFeedBack} className="btn-item">
                                            <span className="btn-green">Send</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default connect()(PanelFileFeedbackContainer);