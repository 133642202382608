import React, {Component, Fragment} from 'react';
import NewEducationModalComponent from "../modals/newEducationModalComponent";
import {
    PANEL_DOCUMENTS_WITOUTPARAM,
    PANEL_ELECTION,
    PANEL_NEW_FILE,
    PANEL_TAG_WITHOUTPARAM
} from "../../constants/routePaths";


class NewItemComponent extends Component {
    render() {
        return (
            <Fragment>
                {
                    this.props.roleAccess &&
                    <ul>
                        {
                            this.props.roleAccess.find(roleItem=>roleItem.page.menuItem==="Dökümanlar" && parseInt(roleItem.permission) > 3 ) &&
                            <li onClick={() => {
                                this.props.history.push(`${PANEL_DOCUMENTS_WITOUTPARAM}?education_id=0`)
                                this.props.toggleModal(true,"isNewEducation");
                            }}><i className="icon-doc" ></i> Eğitim Dökümanı Oluştur
                            </li>
                        }
                        {
                            this.props.roleAccess.find(roleItem=>roleItem.page.menuItem==="Akıllı Dökümanlar" && parseInt(roleItem.permission) > 3 ) &&
                            <li onClick={()=>{
                                this.props.toggleModal(true,"isNewSmartDocuments");
                            }}><i className="icon-smartdoc"></i> Akıllı Döküman Oluştur</li>
                        }
                        {
                            this.props.roleAccess.find(roleItem=>roleItem.page.menuItem==="Kullanıcılar" && parseInt(roleItem.permission) > 3 ) &&
                            <li onClick={() => {
                                this.props.toggleModal(true,"isNewUser");
                            }}><i className="icon-user2"></i> Kullanıcı Yarat</li>
                        }
                        {
                            this.props.roleAccess.find(roleItem=>roleItem.page.menuItem==="Eğitimler" && parseInt(roleItem.permission) > 3 ) &&
                            <li onClick={() => {
                                this.props.toggleModal(true,"planedEducation");
                            }}><i className="icon-calendar"></i> Eğitim Planla</li>
                        }
                        {
                            this.props.roleAccess.find(roleItem=>roleItem.page.menuItem==="Quizler" && parseInt(roleItem.permission) > 3 ) &&
                            <li onClick={() => {
                                this.props.toggleModal(true,"isNewQuiz");
                            }}><i className="icon-newquiz"></i> Quiz Oluştur</li>
                        }
                        {
                            this.props.roleAccess.find(roleItem=>roleItem.page.menuItem==="Lokasyonlar" && parseInt(roleItem.permission) > 3 ) &&
                            <li onClick={() => {
                                this.props.toggleModal(true,"isNewLocation");
                            }}><i className="icon-location"></i> Lokasyon Yarat</li>
                        }
                        {
                            this.props.roleAccess.find(roleItem=>roleItem.page.menuItem==="Anketler" && parseInt(roleItem.permission) > 3 ) &&
                            <li onClick={() => {
                                this.props.toggleModal(true,"isNewSurvey");
                            }}><i className="icon-make-survey"></i> Anket Yarat</li>
                        }
                        {
                            this.props.roleAccess.find(roleItem=>roleItem.page.menuItem==="Katılımcılar" && parseInt(roleItem.permission) > 3 ) &&
                            <li onClick={() => {
                                this.props.toggleModal(true,"isNewParticipant");
                            }}><i className="icon-user2"></i> Katılımcı Yarat</li>
                        }
                        {
                            this.props.roleAccess.find(roleItem=>roleItem.page.menuItem==="Etiketler" && parseInt(roleItem.permission) > 3 ) &&
                            <li onClick={() => {
                                this.props.toggleModal(true,"isNewTag");
                                this.props.history.push(`${PANEL_TAG_WITHOUTPARAM}?id=0`)
                            }}><i className="icon-location"></i> Etiket Yarat</li>
                        }{
                        this.props.roleAccess.find(roleItem=>roleItem.page.menuItem==="Seçimler" && parseInt(roleItem.permission) > 3 ) &&
                        <li onClick={() => {
                            this.props.history.push(`${PANEL_ELECTION}/0`)
                        }}><i className="icon-user2"></i> Seçim Yarat</li>
                    }


                    <li className="title" onClick={() => {
                            this.props.history.push(`${PANEL_NEW_FILE}`)
                            this.props.newBtn()
                        }}>
                            <span>Dosya paylaşım sistemi</span>
                            <div className="item-li"><i className="icon-user2"></i> Dosya Ekle</div>
                            </li>
                        <li onClick={() => {
                            this.props.toggleModal(true,"isNewFileGroup");
                            this.props.newBtn()
                            //this.props.history.push(`${PANEL_ELECTION}/0`)
                        }}><i className="icon-user2"></i> Grup Yarat</li>
                        <li onClick={() => {
                            this.props.toggleModal(true,"isNewFileUser");
                            this.props.newBtn()
                            //this.props.history.push(`${PANEL_ELECTION}/0`)
                        }}><div></div>
                            <i className="icon-user2"></i> Kişi Yarat</li>

                        {/*<li><i className="icon-feedback"></i> Geri Bildirim</li>*/}
                    </ul>
                }

            </Fragment>

        )
    }
};

export default NewItemComponent;