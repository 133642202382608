import React,{Component} from 'react';
import {answerSurveyAction, getMainTilesAction} from "../../actions/index";
import {connect} from "react-redux";
import MainBannerComponent from "../../components/home/mainBannerComponent";
import BannerItemComponent from "../../components/home/bannerItemComponent";
import {ELECTION_INFO, ELECTION_INFO_WITHOUT_PARAM} from "../../constants/routePaths";

class BannerContainer extends Component{
    surveyIndex = 0;
    answerToSurvey = (selectedOption, survey) => {
        let params = {survey_id: survey.id, answer: selectedOption.id};
        this.props.dispatch(answerSurveyAction(params, (res) => {
            if (res === true) {
                this.surveyIndex++;
                this.forceUpdate();
            } else {

            }
        }));
    };
    refreshData = () => {
        this.props.dispatch(getMainTilesAction())
    };
    render(){
        let {mainTiles,survey} = this.props;
        return(
            <div className="promo-root">
                <div className="promo-block">

                    {/*Main tile*/}
                    {
                        (mainTiles && mainTiles.main) &&
                        <MainBannerComponent main={mainTiles.main} refreshData={this.refreshData} index={0}/>
                    }

                    <div className="box-block">
                        {/*{*/}
                            {/*(mainTiles && mainTiles.threeTiles && mainTiles.threeTiles.length > 0) &&*/}
                            {/*<BannerItemComponent refreshData={this.refreshData} index={1} obj={mainTiles.threeTiles[0]}/>*/}
                        {/*}*/}

                        {/*SURVEY*/}
                        <div className="box-item survey">
                            <div className="img-item">
                                <img src={survey && survey[this.surveyIndex] ? survey[this.surveyIndex].image : "/assets/img/common/survey-img.png"}
                                    alt=""/>
                            </div>
                            {
                                (mainTiles && mainTiles.election) &&
                                <div className="info-block">
                                    <h3>{mainTiles.election.name}</h3>
                                    <p>{mainTiles.election.description}</p>
                                    <div className="btn-item">
                                        <span className="btn-green" onClick={()=>{
                                            this.props.history.push(`${ELECTION_INFO_WITHOUT_PARAM}${mainTiles.election.id}`)
                                        }} >{mainTiles.election.is_ended==1?"SONUÇLARI GÖR":"HEMEN OY VER"}</span>
                                    </div>
                                </div>
                            }

                        </div>

                        {
                            (mainTiles && mainTiles.threeTiles && mainTiles.threeTiles.length > 0) &&
                                <BannerItemComponent refreshData={this.refreshData} index={2} obj={mainTiles.threeTiles[1]}/>
                        }

                        {
                            (mainTiles && mainTiles.threeTiles && mainTiles.threeTiles.length > 0) &&
                            <BannerItemComponent refreshData={this.refreshData} index={3} obj={mainTiles.threeTiles[2]}/>
                        }

                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    mainTiles: state.homeReducer ? state.homeReducer.mainTiles : {},
    survey: state.homeReducer ? state.homeReducer.survey : {},
});

export default connect(mapStateToProps)(BannerContainer);