import React, {Component} from 'react';
import {getElectionPanelDetailAction, saveElectionDetailAction} from "../../actions/electionAction";
import {connect} from "react-redux";
import {Field, initialize, reduxForm} from "redux-form";
import InputFieldComponent from "../../components/common/formFields/inputFieldComponent";
import electionInputFieldComponent from "../../components/common/formFields/electionInputFieldComponent";
import {saveEducationPictureAction} from "../../actions/panelAction";
import ElectionCandidateContainers from "../electionPanel/electionCandidateContainers";
import ElectionLiveResultContainer from "../electionPanel/electionLiveResultContainer";
import ElectionParticipantsContainer from "../electionPanel/electionParticipantsContainer";
import {EDITEDUCATIONDETAIL_WITHOUTPARAM, PANEL_ELECTION} from "../../constants/routePaths";

const validate = (values) => {
    let hasErrors = false;
    let errors = {};
    if (!values.name || values.name.trim()==="") {
        hasErrors = true;
        errors.name = 'Zorunlu Alan'
    }
    return hasErrors && errors;
};

class PanelSelectContainer extends Component {
    state = {
        detailData: {
            candidates: [],
            electionResult: [],
            electionParticipant: [],
            info: {}
        },
        errMsg:null
    }
    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.props.dispatch(getElectionPanelDetailAction({id:this.props.match.params.id},(res)=>{
            if(res){
                this.props.dispatch(initialize("createElection",{name:res.info.name,img:res.info.img}))
            }
            if(res){
                this.setState({
                    detailData:res
                })
            }
        }))
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.match.params.id!==prevProps.match.params.id){
            this.getData();
        }
    }

    fileOnChangeFunc = (file) => {
        this.props.dispatch(saveEducationPictureAction(file[0], "survey", (res) => {
            if (res !== false) {
                this.image = res;
                this.forceUpdate();
            }
        }));
    };

    addCandidate = (newCandidate) => {
        this.setState({
            detailData:{
                ...this.state.detailData,
                candidates:[
                    ...this.state.detailData.candidates,
                    newCandidate
                ]
            }
        })
    }

    deleteCandidate = (index) => {
        let arr = [...this.state.detailData.candidates];
        arr.splice(index, 1);
        this.setState({
            detailData:{
                ...this.state.detailData,
                candidates:arr
            }
        })
    }

    deleteLiveResult = (index) => {
        let arr = [...this.state.detailData.electionResult];
        arr.splice(index, 1);
        this.setState({
            detailData:{
                ...this.state.detailData,
                electionResult:arr
            }
        })
    }

    addLiveResult = (newElectionResult) => {
        this.setState({
            detailData:{
                ...this.state.detailData,
                electionResult:[
                    ...this.state.detailData.electionResult,
                    newElectionResult
                ]
            }
        })
    }

    deleteParticipant = (index) => {
        let arr = [...this.state.detailData.electionParticipant];
        arr.splice(index, 1);
        this.setState({
            detailData:{
                ...this.state.detailData,
                electionParticipant:arr
            }
        })
    }

    addParticipant = (participants) => {
        this.setState({
            detailData:{
                ...this.state.detailData,
                electionParticipant:[
                    ...this.state.detailData.electionParticipant,
                    ...participants
                ]
            }
        })
    }

    saveElectionDetail = (values) => {
        if(!this.state.detailData.candidates || this.state.detailData.candidates.length===0){
            this.setState({
                errMsg: "En az bir seçmen eklemelisiniz."
            })
        }else if(!this.state.detailData.electionParticipant || this.state.detailData.electionParticipant.length===0){
            this.setState({
                errMsg: "En az bir katılımcı eklemelisiniz."
            })
        }else{
            this.setState({
                errMsg: null
            })
            this.props.dispatch(saveElectionDetailAction({...this.state.detailData,info:{...this.state.detailData.info,...values}},(electionId)=>{
                if(electionId && this.props.match.params.id!= electionId){
                    this.props.history.push(`${PANEL_ELECTION}/${electionId}`)
                }
                this.getData();
            }))
        }
    }

    render() {
        let {match, location, handleSubmit} = this.props;
        return (
            <form onSubmit={handleSubmit(this.saveElectionDetail)} className="panel-content-block panel-election-root">
                <div className="table-root">
                    <div className="table-container">
                        <div className="table-block">
                            <h2>Seçim {match.params.id == 0 ? "Yarat":"Düzenle"}</h2>
                            <Field
                                name="name"
                                type="text"
                                label="SEÇİM ADI"
                                component={InputFieldComponent}
                            />
                            <div className="upload-item">
                                <div className="text-item">
                                    <p>DUYURU METNİ EKLE</p>
                                    <span>Açıklama için yeni bir döküman oluşturabilirsiniz.</span>
                                </div>
                                <div className="box-item">
                                    <span>{match.params.id == 0 ? "Duyuru metni ekleyebilmeniz için önce seçimi oluşturmanız gerekmektedir.": this.state.detailData.info.name}</span>
                                    {match.params.id != 0 && <span onClick={()=>{
                                        this.props.history.push(`${EDITEDUCATIONDETAIL_WITHOUTPARAM}${this.state.detailData.electionDocumentId}`)
                                    }} className="btn-edit">DÜZENLE</span>}
                                </div>
                            </div>
                            <Field
                                name="img"
                                fileOnChangeFunc={this.fileOnChangeFunc}
                                component={electionInputFieldComponent}
                            />
                            {
                                this.state.detailData &&
                                <ElectionCandidateContainers deleteCandidate={this.deleteCandidate} addCandidate={this.addCandidate} election_id={match.params.id} candidates={this.state.detailData.candidates}/>
                            }
                            {
                                this.state.detailData &&
                                <ElectionLiveResultContainer deleteLiveResult={this.deleteLiveResult} addLiveResult={this.addLiveResult} election_id={match.params.id} electionResult={this.state.detailData.electionResult}/>
                            }

                            {
                                this.state.detailData &&
                                <ElectionParticipantsContainer deleteParticipant={this.deleteParticipant}
                                                               addParticipant={this.addParticipant}
                                                               election_id={match.params.id}
                                                               electionParticipant={this.state.detailData.electionParticipant}/>
                            }
                        </div>

                    </div>
                    <div className="sticky-item safe-area">
                        <div className="btn-sticky">
                            <div className="left-item">
                                {
                                    this.state.errMsg &&
                                    <div className="info-item">{this.state.errMsg}</div>
                                }
                            </div>

                            <div className="btn-item">
                                <span className="btn-back" onClick={()=>{
                                    this.props.history.goBack();
                                }} >KAPAT</span>
                                <button type={"submit"} className="btn-green ">SEÇİM {match.params.id == 0 ? "Yarat":"Düzenle"}</button>
                            </div>
                        </div>
                    </div>

                </div>
            </form>
        );
    }
}

PanelSelectContainer = connect()(PanelSelectContainer);

export default reduxForm({
    form: 'createElection',
    validate
})(PanelSelectContainer);