import React, {Component} from 'react';
import {connect} from "react-redux";
import {submit} from "redux-form";

class ChangeWarningModalComponent extends Component {
    contentBlock = React.createRef();
    render() {
        let {updatePermittedUsers,toggleCloseEdit,forceChangeActiveTab,saveAndExit}=this.props
        return (
            <div className="lb-root change-warning-lb"  onClick={(e) => {
                if (this.contentBlock.current.contains(e.target) === false) {
                    toggleCloseEdit(false)
                }
            }}>
                <div className="lb-block">
                    <div className="content-block" ref={this.contentBlock}>
                        <div className="content-item">
                            <i className="icon-close" onClick={()=> toggleCloseEdit(false)} />
                            <i className="icon-item">
                                <svg width={58} height={51} viewBox="0 0 58 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M23.8038 3.00001C26.1132 -0.999993 31.8868 -1 34.1962 3L56.7128 42C59.0222 46 56.1355 51 51.5167 51H6.48335C1.86454 51 -1.02221 46 1.28719 42L23.8038 3.00001Z" fill="#FF4D35" />
                                    <path d="M26.105 15.0999C26.0479 13.9575 26.9587 13 28.1025 13H29.8975C31.0413 13 31.9521 13.9575 31.895 15.0999L31.095 31.0999C31.0418 32.1643 30.1632 33 29.0975 33H28.9025C27.8368 33 26.9582 32.1643 26.905 31.0999L26.105 15.0999Z" fill="white" />
                                    <path d="M26 39C26 37.3431 27.3431 36 29 36C30.6569 36 32 37.3431 32 39C32 40.6569 30.6569 42 29 42C27.3431 42 26 40.6569 26 39Z" fill="white" />
                                </svg>
                            </i>
                            <div className="text-item">
                                <p>Değişiklikleri kaydetmeden çıkmak üzeresiniz</p>
                            </div>
                            <div className="btn-block">
                                <span className="btn-2" onClick={()=>{
                                    forceChangeActiveTab()
                                }}>KAYDETMEDEN ÇIK</span>
                                <div className="btn-item" onClick={()=>{
                                    saveAndExit()
                                }}>
                                    <button  type="button" className="btn-green ">KAYDET VE ÇIK</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default connect() (ChangeWarningModalComponent);