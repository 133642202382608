import React, {Component} from 'react'
import {connect} from "react-redux";
import {getElectionDetailAction, getResultSessionAction, isSessionExistAction} from "../actions/electionAction";
import ElectionInfoPageContainer from "../containers/election/electionInfoPageContainer";
import ElectionPhoneNumberModalContainer from "../containers/election/electionPhoneNumberModalContainer";
import ElectionVoteContainer from "../containers/election/electionVoteContainer";
import ElectionResultContainer from "../containers/election/electionResultContainer";
import ElectionVotersContainer from "../containers/election/electionVotersContainer";

class ElectionInfoPage extends Component{
    state = {
        activeIndex: 0,
        type:null,
        phone_number:null,
        isConditionCheck:false
    }
    constructor(props) {
        super(props);
        props.dispatch(getElectionDetailAction({link:props.match.params.id}))
        props.dispatch(isSessionExistAction({election_id:props.match.params.id},(result)=>{
            if(result){
                this.setState({
                    token:result.token,
                    candidates:result.candidates,
                    activeIndex:2,
                    type:"vote"
                })
            }
        }))
        props.dispatch(getResultSessionAction({election_id:props.match.params.id},(result)=>{
            if(result){
                this.setState({
                    candidates:result,
                    activeIndex:2,
                    type:"result"
                })
            }
        }))
    }
    changeActiveIndex = (activeIndex,type,phone_number,token,candidates) => {
        this.setState({
            activeIndex,
            type: type?type:this.state.type,
            phone_number: phone_number?phone_number:this.state.phone_number,
            token,
            candidates,
            isConditionCheck:false
        })
    }
    checkCondition = () => {
        this.setState({
            isConditionCheck:true,
            activeIndex: 0
        })
    }
    goToVote = () => {
        this.setState({
            activeIndex:2
        })
    }
    goToResult = () => {
        this.setState({
            activeIndex:2,
            type: "result"
        })
    }
    render() {
        let {detail, isLogin, roleId} = this.props;
        return(
                detail ?
                    <div>
                        {
                            this.state.activeIndex === 0 &&
                            <ElectionInfoPageContainer link={this.props.match.params.id} isAdmin={isLogin && roleId==1} detail={detail} goToVote={this.goToVote} isConditionCheck={this.state.isConditionCheck} changeActiveIndex={this.changeActiveIndex} goToResult={this.goToResult}/>
                        }
                        {
                            this.state.activeIndex === 1 &&
                            <ElectionPhoneNumberModalContainer link={this.props.match.params.id} type={this.state.type} changeActiveIndex={this.changeActiveIndex}/>
                        }
                        {
                            (this.state.activeIndex === 2 && this.state.type === "vote") &&
                            <ElectionVoteContainer checkCondition={this.checkCondition} changeActiveIndex={this.changeActiveIndex} token={this.state.token} candidates={this.state.candidates} link={this.props.match.params.id}/>
                        }
                        {
                            (this.state.activeIndex === 2 && this.state.type === "result") &&
                            <ElectionResultContainer detail={detail} changeActiveIndex={this.changeActiveIndex} candidates={this.state.candidates} link={this.props.match.params.id}/>
                        }
                        {
                            (this.state.activeIndex === 2 && this.state.type === "voters") &&
                            <ElectionVotersContainer detail={detail} changeActiveIndex={this.changeActiveIndex} candidates={this.state.candidates} link={this.props.match.params.id}/>
                        }
                    </div>
                    :
                    <div></div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    detail:state.electionReducer[ownProps.match.params.id],
    isLogin: state.sessionReducer.isLogin,
    roleId: state.sessionReducer.role_id
})

export default connect(mapStateToProps)(ElectionInfoPage);