import React, {Component} from 'react';
import {uploadFileAction} from "../../../actions/fileAction";
import {connect} from "react-redux";
import {SITE_URL} from "../../../constants/Path";
import LoaderModalComponent from "../loaderModalComponent";
import {saveEducationPictureAction} from "../../../actions/panelAction";
import {change} from "redux-form";
const pdfjs = require("pdfjs-dist");;
pdfjs.GlobalWorkerOptions.workerSrc = require("pdfjs-dist/build/pdf.worker.entry.js");

class UploadFileFieldComponent extends Component {
    loading = false;
    uploadFile = (file) => {
        this.loading = true;
        this.forceUpdate()
        this.props.dispatch(uploadFileAction(file,(res)=>{
            if(res){
                console.log(res);
                if(res.indexOf(".pdf")!==-1 && this.props.previewData){
                    var pdfPath = process.argv[2] || SITE_URL+"/assets/"+res;
                    var loadingTask = pdfjs.getDocument(pdfPath);
                    loadingTask.promise
                        .then((doc) => {
                            doc.getPage(1).then((page)=>{
                                var viewport = page.getViewport();

                                var canvas = document.getElementById('the-canvas');
                                var context = canvas.getContext('2d');
                                canvas.height = viewport.viewBox[3] || 1000;
                                canvas.width = viewport.viewBox[2] || 1000;
                                var task = page.render({canvasContext: context, viewport: viewport})
                                task.promise.then(()=>{
                                    var image = new Image();
                                    image.src = canvas.toDataURL('image/jpeg', 1.0);
                                    image.onload = () => {
                                        context.setTransform(1,0,0,-1,0,canvas.height);
                                        context.drawImage(image, 0, 0);
                                        canvas.toBlob((blob)=>{
                                            this.props.dispatch(saveEducationPictureAction(blob, "survey", (res) => {
                                                if (res !== false) {
                                                    this.props.dispatch(change('newFile',"preview_src",res))
                                                    this.loading = false;
                                                    this.forceUpdate();
                                                }
                                            }));
                                        }, 'image/jpeg', 0.95)
                                    };
                                });
                            });
                        })
                        .then(
                            function () {
                                console.log("# End of Document");
                            },
                            function (err) {
                                console.error("Error: " + err);
                            }
                        );
                }else{
                    this.loading = false;
                    this.forceUpdate();
                }
                this.props.input.onChange(res);
            }
        }))
    }

    render() {
        const {input, meta: {error, touched}, placeholder, type, label, linkIsShowing, example, fileOnChangeFunc, classTag} = this.props;
        return (
            <div className={"upload-item "+`${error && touched === true ? "error" : ""}`}>
                <div className="text-item">
                    <p>{label}</p>
                    <span>{example}</span>
                </div>
                <div className={"box-item "+(!input.value || input.value === ""?"":"added")}>
                    <div className="add-file-item">
                        <span className="btn-edit">
                            <i className="icon-documents"> </i>
                            DOSYA EKLE</span>
                        <input type="file" onChange={(e)=>{
                            if(e.target.files[0] && (!this.props.types || this.props.types.indexOf(e.target.files[0].name.split(".").pop().toLowerCase())>-1)){
                                let files = e.target.files;
                                this.uploadFile(files[0])
                            }else{
                                this.pdfFileError = true;
                                this.forceUpdate();
                            }
                        }}/>
                    </div>
                    <div className="added-item">
                        <i className="icon-doc"></i>
                        <span>{input.value?input.value.replace("/files/",""):input.value}</span>
                    </div>
                    <div className="close-item" onClick={()=>{
                        input.onChange("")
                    }}>
                        <i className="icon-close"></i>
                    </div>

                </div>
                <canvas style={{display:"none"}} id="the-canvas"></canvas>
                {
                    this.loading &&
                        <LoaderModalComponent/>
                }
                {
                    this.pdfFileError &&
                    <div className="error-message">
                        <p>Lütfen {this.props.types.join(",")} formatında dosya yükleyiniz.</p>
                    </div>
                }
            </div>
        )
    }
}

export default connect()(UploadFileFieldComponent);
