import React, {Component} from 'react';
import PanelFileDetailOptionsComponent from "../components/Panel/panelFileDetailOptionsComponent";
import PanelFileDetailVersion from "../components/Panel/panelFileDetailVersion";
import PanelFileDetailShareComponent from "../components/Panel/panelFileDetailShareComponent";
import PanelFileDetailFeedback from "../components/Panel/panelFileDetailFeedback";
import {getUploadedFileAction, updateFileInfoAction} from "../actions/fileAction";
import {connect} from "react-redux";
import {FILE_SHARE_DETAIL_WITHOUT_PARAM} from "../constants/routePaths";
import {openMenu} from "../actions/panelAction";
import {BASE_URL} from "../constants/urls";
import {ASSETS, SITE_URL} from "../constants/Path";
import SuccessModalComponent from "../components/common/successModalComponent";
import moment from 'moment-timezone'
import PanelFileActiveVersionComponent from "../components/Panel/panelFileActiveVersionComponent";
import ChangeWarningModalComponent from '../components/modals/changeWarningModalComponent';
import {submit} from "redux-form";
moment.locale("tr");

class PanelFileDetailPage extends Component {
    activeTab = "users";
    fileDetail;
    sharedUserComponent = React.createRef();
    fileVersions = React.createRef();
    fileOptions = React.createRef();
    active=false
    isCloseEdit= false;
    constructor(props) {
        super(props);
        this.getData();
    }
    getData = () => {
        this.props.dispatch(getUploadedFileAction({id:this.props.match.params.id},(res)=>{
            this.fileDetail = res;
            this.forceUpdate()
        }))
    }
    changeActiveTab = (activeTab) => {
        this.tempActiveTab = activeTab;
        if(this.sharedUserComponent.current && JSON.stringify(this.sharedUserComponent.current.getWrappedInstance().firstPermitedUsers)!==JSON.stringify(this.sharedUserComponent.current.getWrappedInstance().filePermissions)){
            this.isCloseEdit = true;
            this.forceUpdate();
            return;
        }
        if(this.fileVersions.current && (this.fileVersions.current.getWrappedInstance().newPdf || this.fileVersions.current.getWrappedInstance().newPptx)){
            this.isCloseEdit = true;
            this.forceUpdate();
            return;
        }
        if(this.fileOptions.current && (JSON.stringify(this.props.fileInfo.initial)!==JSON.stringify(this.props.fileInfo.values)) && this.props.success!==true){

            this.isCloseEdit = true;
            this.forceUpdate();
            return;
        }
        this.activeTab = activeTab;
        this.forceUpdate();
    }



    saveAndExit = () => {
        if(this.sharedUserComponent.current){
            this.sharedUserComponent.current.getWrappedInstance().updatePermittedUsers();
            this.forceChangeActiveTab();
            return;
        }
        if(this.fileVersions.current){
            this.fileVersions.current.getWrappedInstance().saveVersion();
            this.forceChangeActiveTab();
            return;
        }
        if(this.fileOptions.current){
          this.props.dispatch(updateFileInfoAction({...this.props.formVal, file_id: this.props.match.params.id}, (res) => {
                if (res) {
                    this.getData();
                }
              this.forceChangeActiveTab()
            }))
            return;
        }

    }
    forceChangeActiveTab = () => {
        this.activeTab = this.tempActiveTab;
        this.isCloseEdit = false;
        this.forceUpdate();
        this.tempActiveTab = null;
    }
    copyToClipBoard = () => {
        var dummy = document.createElement('input'),
            text = SITE_URL + FILE_SHARE_DETAIL_WITHOUT_PARAM+this.props.match.params.id;
        this.successMessage = true
        this.forceUpdate();
        setTimeout(()=>{
            this.successMessage = false
            this.forceUpdate();
        },500)
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
    }
    toggleCloseEdit = (value) => {
        this.isCloseEdit = value;
        this.forceUpdate();
    };
    render() {
        let{fileInfo}=this.props
        return (
            <div className="mainRoot">
                <div className="panel-root">
                    <div className="panel-wrapper safe-area">
                        <div className="panel-container">
                            <div className="file-detail-root">
                                <div className="panel-content-block panel-election-root new-file">
                                    <div className="table-root">
                                        <div className="table-container">
                                            <div className="table-block files-table-block">
                                                <div className="tab-block">
                                                    {
                                                        (this.fileDetail && this.fileDetail.uploadedFileInfo) &&
                                                        <h2>{this.fileDetail.uploadedFileInfo.name}</h2>
                                                    }
                                                    <div className="btn-item" onClick={this.copyToClipBoard}>
                                                        <i className="icon-doc"></i>
                                                       <span>BAĞLANTIYI KOPYALA</span>
                                                    </div>
                                                </div>

                                                {
                                                    this.fileDetail&&
                                                    <PanelFileActiveVersionComponent  versions={this.fileDetail.fileVersions}  />
                                                }

                                                <div className="vote-result-block">
                                                    <div className="vote-result-tab-block">
                                                        <ul>
                                                            <li onClick={()=>{
                                                                this.changeActiveTab("users")
                                                            }} className={this.activeTab==="users"?"active":null}>Paylaşım</li>
                                                            <li onClick={()=>{
                                                                this.changeActiveTab("versions")
                                                            }} className={this.activeTab==="versions"?"active":null}>Dosya Sürümleri</li>
                                                            <li onClick={()=>{
                                                                this.changeActiveTab("info")
                                                            }} className={this.activeTab==="info"?"active":null}>Dosya Ayarları</li>
                                                            <li onClick={()=>{
                                                                this.changeActiveTab("feedBack")
                                                            }} className={this.activeTab==="feedBack"?"active":null}>Geri Bildirimler</li>

                                                        </ul>
                                                    </div>
                                                </div>
                                                {
                                                    (this.activeTab === "versions" && this.fileDetail) &&
                                                    <PanelFileDetailVersion ref={this.fileVersions} history={this.props.history} getData={this.getData} file_id={this.props.match.params.id} versions={this.fileDetail.fileVersions}/>
                                                }
                                                {
                                                    (this.activeTab === "info" && this.fileDetail) &&
                                                    <PanelFileDetailOptionsComponent ref={this.fileOptions}  getData={this.getData} uploadedFileInfo={this.fileDetail.uploadedFileInfo} history={this.props.history} file_id={this.props.match.params.id}/>
                                                }
                                                {
                                                    (this.activeTab === "users" && this.fileDetail) &&
                                                    <PanelFileDetailShareComponent ref={this.sharedUserComponent} filePermissions={this.fileDetail.filePermissions} getData={this.getData} history={this.props.history} file_id={this.props.match.params.id}/>
                                                }
                                                {
                                                    (this.activeTab === "feedBack" && this.fileDetail) &&
                                                    <PanelFileDetailFeedback history={this.props.history} fileFeedBacks={this.fileDetail.fileFeedBacks} getData={this.getData} uploadedFileInfo={this.fileDetail.uploadedFileInfo}/>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.isCloseEdit &&
                    <ChangeWarningModalComponent 
                    saveAndExit={this.saveAndExit} 
                    forceChangeActiveTab={this.forceChangeActiveTab} 
                    toggleCloseEdit={this.toggleCloseEdit}/>
                }
                {
                    this.successMessage === true && <SuccessModalComponent
                        message={"Panoya Kopyalandı"}/>
                }
            </div>


        );
    }
}
const mapStateToProps = (state)=>({
    isMenuOpen:state.PanelReducer.isMenuOpen,
    fileInfo:state.form ? state.form.fileInfo : {},
    success:state.form && state.form.fileInfo ? state.form.fileInfo.submitSucceeded : {},
    formVal: state.form.fileInfo ? state.form.fileInfo.values : {},
});

export default connect(mapStateToProps)(PanelFileDetailPage);